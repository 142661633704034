import { SaveExternalCourseParticipationRequest, SetCourseParticipationCanceledRequest } from "@/modules/course/requests";
import { ApiPagedResult, BaseService } from "@k-digitale/kappa";
import { CourseInstance, CourseInstanceFinder, CourseParticipation, CourseParticipationFinder, CourseParticipationOutcome, CourseParticipationOutcomeFinder, CourseParticipationRequest, CourseParticipationRequestFinder, LessonInstance, LessonInstanceFinder, LessonParticipation, LessonParticipationFinder} from '../instances/Models'
import { SaveCourseParticipationRequest, SaveCourseParticipationRequestListItemRequest, SaveCourseParticipationRequestRequest} from '../instances/Requests'
import { Teacher } from "../registries/Models";

class InstancesService extends BaseService {

    //COURSE INSTANCES
    async getCourseInstancesByFinder(finder: CourseInstanceFinder): Promise<ApiPagedResult<CourseInstance>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/course-instances/", { params: finder })
        );

        return response!.data;
    }
    async getCourseInstance(id: string): Promise<CourseInstance> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/course-instances/${id}`)
        );

        return response!.data;
    }

    async getCourseTeachers(courseId: string): Promise<Teacher[]> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/course-instance/${courseId}/teachers`)
        );

        return response!.data;
    }

    //LESSON INSTANCE
    async getLessonInstancesByFinder(finder: LessonInstanceFinder): Promise<ApiPagedResult<LessonInstance>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/instances/lessons/", { params: finder })
        );

        return response!.data;
    }
    async getLessonInstance(id: string): Promise<LessonInstance> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/instances/lesson/${id}`)
        );

        return response!.data;
    }


    //COURSE PARTICIPATION REQUESTS
    async getCourseParticipationRequestsByFinder(finder: CourseParticipationRequestFinder): Promise<ApiPagedResult<CourseParticipationRequest>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/instances/course-participation-requests/", { params: finder })
        );

        return response!.data;
    }
    async saveCourseParticipationRequest(request: SaveCourseParticipationRequestRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/instances/save-course-participation-request", request)
        );

        return response!.data;
        // return this.handleApiResult(response?.data);
    }

    async saveCourseParticipationRequestList(request: SaveCourseParticipationRequestListItemRequest[]): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/instances/save-course-participation-request-list", request)
        );

        //return response!.data;
        return response?.status == 200;
    }

    //COURSE PARTICIPATIONS
    async getCourseParticipationsByFinder(finder: CourseParticipationFinder): Promise<ApiPagedResult<CourseParticipation>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/instances/course-participations/", { params: finder })
        );

        return response!.data;
    }

    async saveCourseParticipationList(request: SaveExternalCourseParticipationRequest[]): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/instances/save-external-course-participation-list", request)
        );

        //return response!.data;
        return response?.status == 200;
    }
    async setCourseParticipationListCancelled(request: SetCourseParticipationCanceledRequest[]): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("learning/course-participations/set-external-list-canceled", request)
        );

        //return response!.data;
        return response?.status == 200;
    }

    //COURSE PARTICIPATION OUTCOMES
    async getCourseParticipationOutcomesByFinder(finder: CourseParticipationOutcomeFinder): Promise<ApiPagedResult<CourseParticipationOutcome>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/course-participation-outcomes/", { params: finder })
        );

        return response!.data;
    }

    //LESSON PARTICIPATIONS
    async getLessonParticipationsByFinder(finder: LessonParticipationFinder): Promise<ApiPagedResult<LessonParticipation>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/lesson-participations/", { params: finder })
        );

        return response!.data;
    }

}

export const instancesService = new InstancesService();
