import { ApiPagedResult, ApiResponse, BaseService, DownloadedDocument } from "@k-digitale/kappa";
import { Cantiere, CantiereFinder, CantiereVisibility, CantiereVisibilityFinder, CesfNotification, RLSWorkerData } from "@/services/cesf/Models";
import { SaveWorkerRequest } from "@/learning/registries/Requests";
import { Worker } from "@/learning/registries/Models";
import { DownloadDocumentRequest, DownloadDocumentResponse, RequestRLSNomina, SaveDeleteCantiereVisibilityListRequest, SendEmailToCesfRequest, SendMessageToCesfRequest } from "./Requests";

class CesfService extends BaseService {

    //CANTIERI
    async getCantieriByEduCompanyId(finder: CantiereFinder, companyId : string): Promise<ApiPagedResult<Cantiere>> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantieri/${companyId}`, { params: finder })
        );

        return response?.data;
    }

    async getCantiere(id: string): Promise<Cantiere> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantiere/${id}`)
        );

        return response?.data;
    }

    //DIPENDENTI
    async saveNewWorker(request: SaveWorkerRequest){
        const response = await this.tryExecute(
            this.api.post<any>(`learning/workers/save-ext`, request)
        );

        return response?.data.success;
        //return await this.handleApiResponse(response?.data);
    }

    // DOCUMENTI CANTIERE
    async uploadDocumentoCantiere(id: string, formData: FormData): Promise<boolean> {

        const response = await this.tryExecute(
            this.api.post<any>(`cesf/cantiere/${id}/upload`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
        );

        return response?.data.success;
        //return await this.handleApiResponse(response?.data);
    }

    async getDocumentiCantiere(id : string, companyId?: string): Promise<any> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantiere/${id}/lista-documenti/${companyId}`)
        );

        return response?.data;
    }

    async downloadCantieriDocument(id: string, idDocumento : number) {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantiere/${id}/documenti/${idDocumento}`, {
                responseType: 'blob'
            })
        );

        let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '');
        let link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = `${filename}`;
        link.target = "_new";
        link.click();
    }

    async deleteDocumentoCantiere(id: string, idDocumento : number): Promise<boolean> {

        const response = await this.tryExecute(
            this.api.post<any>(`cesf/cantiere/${id}/documenti/${idDocumento}/delete`)
        );

        return response?.data.success;
    }

    //NOTIFICATIONS
    async sendEmailToCesf(request: SendEmailToCesfRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("cesf/notifications/send-email-to-cesf", request)
        );

        //return response?.data;
        return response?.data.success;
    }

    async sendMessageToCesf(request: SendMessageToCesfRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("cesf/notifications/send-message-to-cesf", request)
        );

        //return response?.data;
        return response?.data.success;
    }

    //ATTESTATI
    async downloadCertificate(request: DownloadDocumentRequest) : Promise<DownloadDocumentResponse> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/documents/download`, {
                params: request,
                responseType: 'blob'
            })
        );

        if(response){
            let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '');
            return {
                contentType: response.headers['content-type'],
                fileName: `${filename}`,
                fileStream: response.data,
            } as DownloadDocumentResponse;
        } else{
            return undefined;
        }
    }

    //Preposti
    async getCompaniesPreposti(id: string, pageIndex : number, pagesSize : number) : Promise<ApiPagedResult<Worker>> {

        const response = await this.tryExecute(
            this.api.get<ApiPagedResult<Worker>>(`cesf/companies/${id}/get-preposti`, {params: {pageIndex:pageIndex, pagesSize: pagesSize}})
        );

        return response?.data;
    }

    //CANTIERI VISIBILITY
    async getCantieriVisibilityByFinder(finder: CantiereVisibilityFinder): Promise<ApiPagedResult<CantiereVisibility>> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/cantieri-visibility/`, { params: finder })
        );

        return response?.data;
    }

    async saveDeleteCantieriVisibilities(request: SaveDeleteCantiereVisibilityListRequest): Promise<any> {
        const response = await this.tryExecute(
            this.api.post<any>("cesf/cantieri-visibility/list/insert-delete", request)
        );

        //return response?.data;
        return response?.data;
    }

    //RLS
    async getRlsWorkerDataByIdWorker(idWorker : string): Promise<ApiResponse<RLSWorkerData>> {
        const response = await this.tryExecute(
            this.api.get<ApiResponse<RLSWorkerData>>(`cesf/rls/worker/${idWorker}/data`)
        );

        return response?.data;
    }

    async requestNominaRls(request: RequestRLSNomina): Promise<ApiResponse<boolean>> {
        var formData = new FormData();
        formData.append("idWorker", request.idWorker);
        formData.append("idCompany", request.idCompany);
        formData.append("dataNomina", new Date(request.dataNomina).toISOString());
        if(request.dataScadenzaCorso){
            formData.append("dataScadenzaCorso", new Date(request.dataScadenzaCorso).toISOString());
        }
        if((request.fileNomina?.length ?? 0) > 0){
            formData.append("fileNomina", request.fileNomina[0]);
        }
        if((request.fileAttestato?.length ?? 0) > 0){
            formData.append("fileAttestato", request.fileAttestato[0]);
        }
        if((request.fileAggiornamento?.length ?? 0) > 0){
            formData.append("fileAggiornamento", request.fileAggiornamento[0]);
        }

        const response = await this.tryExecute(
            this.api.post<ApiResponse<any>>("cesf/rls/request-nomina", formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
        );

        return response?.data;
    }

    async generaLetteraIncaricoRlst(idCompany: string, filename: string) : Promise<DownloadedDocument> {
        const response = await this.tryExecute(
            this.api.get<Blob>(`cesf/company/${idCompany}/generate-lettera-incarico-RLST`, { responseType: 'blob' })
        );

        return this.parseDownloadedFileResponse(response, filename);
    }
}

export const cesfService = new CesfService();
