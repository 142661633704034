<template>
    <Page :loading="loading">
        <PageHeader title="Imprese">
        </PageHeader>

        <div class="col-12 md:col-6">
            <KInputSearch placeholder="Cerca" style="margin-bottom: 0 !important;" v-model="searchTerm" @search="searchCompaniesByTerm" />
        </div>

        <DataTable
            @row-click="sel($event.data.id)"
            rowHover
            :value="items"
            dataKey="id"
            responsiveLayout="scroll">

            <Column :sortable="true" field="businessName" header="Ragione Sociale" style="font-weight: bold;"></Column>
            <Column :sortable="true" field="fiscalCode" header="Codice Fiscale"></Column>
            <Column :sortable="true" field="partitaIVA" header="Partita IVA"></Column>
            <Column :sortable="true" field="city" header="Comune"></Column>
            <!-- <Column>
                <template #body="slotProps">
                    <div style="text-align: right;">
                        <Button icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" @click="toggleActionsPanel(slotProps.data, $event)" />
                    </div>
                </template>
            </Column> -->

            <template #empty>
                Nessuna impresa trovata.
            </template>

        </DataTable>
        <Paginator :rows="pageInfo.pageSize" :totalRecords="pageInfo.totalCount" @page="onPage($event)">
            <template #start>
                {{pageStatus}}
            </template>
        </Paginator>

    </Page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { KInputSearch, PageInfo } from '@k-digitale/kappa';
import { Company, CompanyFinder } from '@/learning/registries/Models';
import { learningExtensionService } from '@/services/learningExtensions/learningExtensionsServices';
import { useCesfUser } from '@/composables';

const { switchCompany } = useCesfUser();

const pageInfo = ref<PageInfo>({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
});

const companiesFinder = ref<CompanyFinder>({
        pageIndex: pageInfo.value.pageIndex,
        pageSize: pageInfo.value.pageSize,
        //sortField: nameof<Company>("businessName"),
    } as CompanyFinder);

const items = ref<Company[]>([]);
const loading = ref(false);
const searchTerm = ref('');

//COMPUTED
const pageStatus = computed(() => {
    const firstOfPage = pageInfo.value.pageSize * (pageInfo.value.pageIndex - 1) + 1;
    let lastOfPage = pageInfo.value.pageIndex * pageInfo.value.pageSize;
    if(lastOfPage > pageInfo.value.totalCount)
        lastOfPage = pageInfo.value.totalCount;

    return `${firstOfPage}-${lastOfPage} di ${pageInfo.value.totalCount}`
})

//VUE
const router = useRouter();

onMounted(async () => {
    await searchCompanies();
});

const searchCompaniesByTerm = async () => {
    companiesFinder.value.globalSearchTerm = searchTerm.value;
    companiesFinder.value.pageIndex = 1;
    await searchCompanies();
}

const searchCompanies = async () => {
    loading.value = true;
    const results = await learningExtensionService.getCompaniesByFinder(companiesFinder.value);
    if(results){
        pageInfo.value = results;
        items.value = results.items;
    }
    loading.value = false;
}

const onPage = async (event: any) => {
    if(pageInfo.value.pageIndex != event.page + 1) {
        pageInfo.value.pageIndex = event.page + 1;
        companiesFinder.value.pageIndex = pageInfo.value.pageIndex;
        companiesFinder.value.pageSize = pageInfo.value.pageSize;
        await searchCompanies();
    }
}

const sel = async (idCompany: string) => {
    await switchCompany(idCompany);
    router.push("/");
}

</script>

<style lang="scss">

</style>