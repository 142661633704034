<template>
    <Page>
        <KBackButton />
        <PageHeader title="Tutti i corsi"></PageHeader>

        <div class="row">
            <div class="col-6">
                <KInputSearch
                    placeholder="Cerca corso"
                    style="margin-bottom: 0 !important;"
                    v-model="courseFullTextSearch"
                    @search="onCorsoSearch" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <DataTable :value="courseTemplates" dataKey="id" responsiveLayout="scroll" :loading="loading">
                    <Column field="title" header="TITOLO"></Column>
                    <Column bodyClass="text-align-center" field="totalHours" header="ORE"></Column>
                    <Column header="IN PARTENZA">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.templateStartingCourses > 0">
                                <div class="bg-info state-code-label inline-block mr-3" />
                                <div v-if="slotProps.data.templateStartingCourses == 1" class="accordion-body p-0 inline-block">
                                    E' presente (1) corso in partenza.
                                </div>
                                <div v-else class="accordion-body p-0 inline-block">
                                    Sono presenti ({{slotProps.data.templateStartingCourses}}) corsi in partenza.
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column >
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <button type="button" @click="goToDetail(slotProps.data.id)" class="btn btn-primary"> DETTAGLI </button>
                            </div>
                        </template>
                    </Column>

                    <template #empty> Nessun corso trovato </template>
                    <template #footer>
                        <KPaginator
                            :currentPage="currentCoursePage"
                            :total-count="totalCourseCount"
                            show-total-count
                            @page-selected="onPageChange"
                            :totalPages="totalCoursePages"/>
                    </template>
                </DataTable>
            </div>
        </div>
        <div class="row">
            <h3>Altri corsi</h3>
            <div class="row">
                <div class="col-6">
                    <KInputSearch
                        placeholder="Cerca altri corsi"
                        style="margin-bottom: 0 !important;"
                        v-model="courseOtherFullTextSearch"
                        @search="onOtherCorsoSearch" />
                </div>
            </div>
            <div class="col-12">
                <DataTable :value="otherCourses" dataKey="id" responsiveLayout="scroll" :loading="loading">
                    <Column field="title" header="TITOLO"></Column>
                    <Column bodyClass="text-align-center" field="totalHours" header="ORE"></Column>
                    <Column header="IN PARTENZA">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.templateStartingCourses > 0">
                                <div class="bg-info state-code-label inline-block mr-3" />
                                <div v-if="slotProps.data.templateStartingCourses == 1" class="accordion-body p-0 inline-block">
                                    E' presente (1) corso in partenza.
                                </div>
                                <div v-else class="accordion-body p-0 inline-block">
                                    Sono presenti ({{slotProps.data.templateStartingCourses}}) corsi in partenza.
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column >
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <button type="button" @click="goToDetail(slotProps.data.id)" class="btn btn-primary"> DETTAGLI </button>
                            </div>
                        </template>
                    </Column>

                    <template #empty> Nessun corso trovato </template>
                    <template #footer>
                        <KPaginator
                            :currentPage="currentOtherCoursePage"
                            :total-count="totalOtherCourseCount"
                            show-total-count
                            @page-selected="onOtherPageChange"
                            :totalPages="totalOtherCoursePages"/>
                    </template>
                </DataTable>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useCourseStore } from "@/modules/course/store"
import { CourseInstance, CourseInstanceFinder } from "@/learning/instances/Models";
import { useRouter } from "vue-router";

//IMPORT COMPONENTS
import { CourseTemplateFinder } from "@/learning/templates/Models";
import { KPaginator } from "@k-digitale/kappa";
import { CourseTemplateListItemView } from "@/services/learningWrappers/Models";


const router = useRouter();
const courseStore = useCourseStore();

const loading = ref(false);

//VUE
onMounted(async () => {
    loading.value = true;
    await Promise.all([loadCourses(), loadOtherCourses()]);
    loading.value = false;
});

const courseFullTextSearch = ref<string>("");
const courseTemplatesFinder = ref<CourseTemplateFinder>({
    queryRelations:true,
    pageIndex: 1,
    pageSize: 10,
    sortField: "title",
    hideInvisibleToExtranet: true,
} as CourseTemplateFinder);

const totalCourseCount = ref<number>();
const currentCoursePage = ref<number>(1);

const courseTemplates = ref<CourseTemplateListItemView[]>();

const totalCoursePages = computed(() => {
    const result = Math.ceil(totalCourseCount.value / courseTemplatesFinder.value.pageSize);
    return  result == 0 ? 1 : result;
})

const loadCourses = async () => {
    const response = await courseStore.getAllCourses(courseTemplatesFinder.value);
    if(response){
        totalCourseCount.value = response.totalCount;
        currentCoursePage.value = courseTemplatesFinder.value.pageIndex;
        courseTemplates.value = response.items;
    }
}

const onPageChange = (newPage: number) => {
    courseTemplatesFinder.value.pageIndex = newPage;
    loadCourses();
};

const onCorsoSearch = () => {
    courseTemplatesFinder.value.globalSearchTerm = courseFullTextSearch.value;
    loadCourses();
};


const otherCoursesFInder = ref<CourseTemplateFinder>({
    queryRelations:true,
    pageIndex: 1,
    pageSize: 10,
    sortField: "title",
    hideInvisibleToExtranet: true,
} as CourseTemplateFinder);

const otherCourses = ref<CourseTemplateListItemView[]>();

const courseOtherFullTextSearch = ref<string>("");

const totalOtherCourseCount = ref<number>();
const currentOtherCoursePage = ref<number>(1);

const totalOtherCoursePages = computed(() => {
    const result = Math.ceil(totalOtherCourseCount.value / otherCoursesFInder.value.pageSize);
    return  result == 0 ? 1 : result;
})

const loadOtherCourses = async () => {
    const response = await courseStore.getOtherCourses(otherCoursesFInder.value);
    if(response){
        totalOtherCourseCount.value = response.totalCount;
        currentOtherCoursePage.value = otherCoursesFInder.value.pageIndex;
        otherCourses.value = response.items;
    }
}

const onOtherPageChange = (newPage: number) => {
    otherCoursesFInder.value.pageIndex = newPage;
    loadOtherCourses();
};

const onOtherCorsoSearch = () => {
    otherCoursesFInder.value.globalSearchTerm = courseOtherFullTextSearch.value;
    loadOtherCourses();
};

const goToDetail = (id: string) => {
    router.push({
        path: `/course-detail/${id}`
    });
}

</script>

<style lang="scss">
.tb-lista-cantieri .p-datatable-wrapper {
    table {
        thead {
            tr {
                th {
                    border: none;
                }
            }
        }
    }
}

.code-employee-state {
    top: 5px;
    left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.course-expired-employee-state {
    top: 5px;
    left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.employees-paging-info {
    opacity: 1;
    color: rgba(92,111,130,1);
    font-family: "TitilliumWeb-Regular";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
</style>
