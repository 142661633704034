import { RouteRecordRaw } from "vue-router";

import ProfileHome from './pages/Profile.vue';

const cnaRoutes: RouteRecordRaw[] = [
    {
        path: '/profile',
        component: ProfileHome,
        meta: {
            requiresAuth: true,
            menu: {
                order: 1,
                label: 'Anagrafica e profilo',
                iconAsset: 'icons/ico-anagrafica.svg'
            }
        }
    }
];

export default cnaRoutes;