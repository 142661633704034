import { Company, Coordinator, Student, Teacher, Tutor, Worker } from "./Models";
import { SaveCompanyRequest, SaveCoordinatorRequest, SaveStudentRequest, SaveTeacherRequest, SaveTutorRequest, SaveWorkerRequest } from "./Requests";
import dayjs from "dayjs";

export function mapCompanyToSaveRequest(model: Company) : SaveCompanyRequest {
    return {
        address: model.address,
        businessName: model.businessName,
        extCode1: model.extCode1,
        cellPhone: model.cellPhone,
        extCode2: model.extCode2,
        city: model.city,
        consultantEmail: model.consultantEmail,
        consultantFax: model.consultantFax,
        consultantLastName: model.consultantLastName,
        consultantName: model.consultantName,
        consultantPhone: model.consultantPhone,
        country: model.country,
        email: model.email,
        fax: model.fax,
        fiscalCode: model.fiscalCode,
        id: model.id,
        partitaIVA: model.partitaIVA,
        pec: model.pec,
        phone: model.phone,
        provincia: model.provincia,
        zipCode: model.zipCode,
        userId: model.userId,
        ccnl: model.ccnl,
        description: model.description,
    } as Company;
};

export function mapStudentToSaveRequest(model: Student) : SaveStudentRequest {
    return {
        id: model.id,
        italianCourseDone: model.italianCourseDone,
        newWorker: mapWorkerToSaveRequest(model.worker),
        workerId: model.workerId,
        hasReceived16Hours: model.hasReceived16Hours,
        toBeVerified: model.toBeVerified,
    } as SaveStudentRequest;
};

export function mapTeacherToSaveRequest(model: Teacher) : SaveTeacherRequest {
    return {
        id: model.id,
        contractType: model.contractType,
        hourlyCost: model.hourlyCost,
        publicAdministration: model.publicAdministration,
        supplierCode: model.supplierCode,
        newWorker: mapWorkerToSaveRequest(model.worker),
        workerId: model.workerId,
    } as SaveTeacherRequest;
};

export function mapTutorToSaveRequest(model: Tutor) : SaveTutorRequest {
    return {
        id: model.id,
        newWorker: mapWorkerToSaveRequest(model.worker),
        workerId: model.workerId,
    } as SaveTutorRequest;
};
export function mapCoordinatorToSaveRequest(model: Coordinator) : SaveCoordinatorRequest {
    return {
        id: model.id,
        newWorker: mapWorkerToSaveRequest(model.worker),
        workerId: model.workerId,
    } as SaveCoordinatorRequest;
};

export function mapWorkerToSaveRequest(model: Worker) : SaveWorkerRequest {
    return {
        address: model.address,
        birthdate: model.birthdate,
        birthplace: model.birthplace,
        cellPhone: model.cellPhone,
        citizenship: model.citizenship,
        city: model.city,
        companyId: model.companyId,
        country: model.country,
        email: model.email,
        fiscalCode: model.fiscalCode,
        hiringDate: model.hiringDate,
        lastName: model.lastName,
        id: model.id,
        name: model.name,
        privacyInfo: model.privacyInfo,
        privacyPhoto: model.privacyPhoto,
        provincia: model.provincia,
        sex: model.sex,
        telephone: model.telephone,
        toBeHired: model.toBeHired,
        zipCode: model.zipCode,
        isUnemployed: model.isUnemployed,
        userId: model.userId,

    } as SaveWorkerRequest;
};