import { BaseFinder } from "@k-digitale/kappa"
import { Coordinator, Teacher, Tutor } from "../registries/Models";
import { CourseInstance } from "../instances/Models";

export enum CourseTemplateStatus {
    InDesign = "InDesign",
    Completed = "Completed",
    Old = "Old"
}
export enum CourseTemplateFinancingMode {
    SelfFinanced = "SelfFinanced",
    Financed = "Financed",
    Payed = "Payed"
}

export enum CourseTemplateRelationshipType {
    Prerequisite = "Prerequisite",
    Inclusion = "Inclusion",
    Update = "Update"
}

export interface CourseTemplate {
    id: string;
    udId: string | null;
    ucId: string | null;
    tutorId: string | null;
    coordinatorId: string | null;
    scopeId: string | null;
    title: string;
    shortTitle?: string;
    extProductId?: number;
    hoursForDesign: string;
    hoursForCoordination: string;
    hoursForDirection: string;
    hoursForAdministration: string;
    hoursForTutoring: string;
    hoursForTutoringAtClass: string;
    areFullfillmentsNotRequested: boolean;
    totalHours: number;
    is16HoursNotRequested: boolean;
    isToBeUpdated: boolean;
    status: CourseTemplateStatus;
    financingMode: CourseTemplateFinancingMode;
    lender: string;
    validityMonths: number;
    lessonTemplates: LessonTemplate[];
    fullfillments: Fullfillment[];
    ud: Ud;
    uc: Uc;
    coordinator: Coordinator;
    tutor: Tutor;
    scope: Scope;
    courseTemplateRelationships: CourseTemplateRelationship[],
}

export interface LessonTemplate {
    id: string;
    courseTemplateId: string;
    subject: string;
    hours: number;
    description: string;
    requestedParticipants: number;
    startHour: Date | null;
    endHour: Date | null;
    place: string;
    teachers: Teacher[];
    subjectArea: string;
}

export interface Fullfillment {
    id: string;

    code: string;
    legislation: string;
}

export interface Ud {
    id: string;
    code: string;
    title: string;
}

export interface Uc {
    id: string;
    code: string;
    title: string;
}

export interface Scope {
    id: string;
    description: string;
}

export interface CourseTemplateRelationship {
    id: string;
    baseCourseTemplateId: string;
    extensionCourseTemplateId: string;
    relationshipType: CourseTemplateRelationshipType;
    extensionCourseTemplate: CourseTemplate;
}


export interface CourseTemplateWithInstancesResponse {
    courseTemplate?: CourseTemplate,
    courseInstances?: CourseInstance[]
}

//FINDERS
export interface CourseTemplateFinder extends BaseFinder{
    queryRelations: boolean,
    status: CourseTemplateStatus
    code: string,
    title: string,
    showOldCourses: boolean,
    hideOldCourses: boolean,
    globalSearchTerm: string,
    hideInvisibleToExtranet: boolean,
    showRelatedToPimOnly: boolean,
}
export interface LessonTemplateFinder extends BaseFinder{
    courseTemplateId: string,
}

export interface FullfillmentFinder extends BaseFinder {

}

export interface UdFinder extends BaseFinder {

}
export interface UcFinder extends BaseFinder {

}

export interface ScopeFinder extends BaseFinder {

}
