<template>
    <KSpinner :loading="loading">
        <div v-if="rls">
            <span v-if="rls.isTerritorial">RLST:&nbsp;</span>
            <span v-else>RLS:&nbsp;</span>
            <span class="label-box">
                <a href="#" role="button" @click="goToDetail()">{{ rls.nome }} {{ rls.cognome }}</a>
            </span>
        </div>
        <div v-else>
            <span class="label-box">RLS/RLST NON PRESENTE!</span>
        </div>

        <KModalSlide v-model="rlsDetailVisible" title="Dettaglio RLS">
            <dl class="row" v-if="rls">
                <dt class="col-sm-3">Nome</dt>
                <dd class="col-sm-9">{{ rls.nome }}</dd>

                <dt class="col-sm-3">Cognome</dt>
                <dd class="col-sm-9">{{ rls.cognome }}</dd>

                <template v-if="rls.isTerritorial">
                    <dt class="col-sm-3">Assegnato da</dt>
                    <dd class="col-sm-9">{{ rls.territorialName || '-' }}</dd>
                </template>

                <dt class="col-sm-3">Telefono</dt>
                <dd class="col-sm-9">{{ rls.telefono || '-' }}</dd>

                <dt class="col-sm-3">Indirizzo email</dt>
                <dd class="col-sm-9">{{ rls.eMail || '-' }}</dd>

                <dt class="col-sm-3">Ente Formazione</dt>
                <dd class="col-sm-9">{{ rls.enteFormazione || '-' }}</dd>

                <dt class="col-sm-3">Luogo Formazione</dt>
                <dd class="col-sm-9">{{ rls.luogoFormazione || '-' }}</dd>

                <dt class="col-sm-3">Data Nomina</dt>
                <dd class="col-sm-9">{{ rls.dataNomina ? toDateString(rls.dataNomina) : '-' }}</dd>

                <dt class="col-sm-3">Valido fino a</dt>
                <dd class="col-sm-9">{{ rls.validoFinoA ? toDateString(rls.validoFinoA) : '-' }}</dd>

                <dt v-if="rls.isTerritorial" class="col-sm-3">Lettera d'incarico</dt>
                <button v-if="rls.isTerritorial" class="btn btn-primary btn-xs text-uppercase col-3"
                    type="button" @click="generaLetteraIncarico()" :disabled="isDownloading">
                        <span v-if="!isDownloading">Scarica</span>
                        <ProgressSpinner v-else style="width: 20px; height: 20px; margin: -5px 0;" strokeWidth="4" />
                </button>
            </dl>

        </KModalSlide>
    </KSpinner>
</template>

<script setup lang="ts">
import { learningExtensionService } from "@/services/learningExtensions/LearningExtensionsServices";
import { ResponsabileSicurezzaLavoratori } from "@/services/cesf/Models";
import { cesfService } from "@/services/cesf/CesfServices";
import { KSpinner, useFilters, usePlatformApi } from '@k-digitale/kappa';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const { kToast } = usePlatformApi();

const { toDateString } = useFilters();
const loading = ref(false);
const rls = ref<ResponsabileSicurezzaLavoratori>(null)

const props = withDefaults(
    defineProps<{
        idCompany: string
    }>(),
	{
        idCompany: null
    }
);

watch(
    () => props.idCompany,
    async (value) => {
        loading.value = true;
        try {
            if(value != null) {
                rls.value = await learningExtensionService.getCompanyRls(value);
            }
            else {
                rls.value = null;
            }
        }
        finally {
            loading.value = false;
        }
    },
    { immediate: true }
);

const rlsDetailVisible = ref(false);
const goToDetail = () => {
    if(rls.value.idWorker){
        router.push({
            path: `/employee/detail/${rls.value.idWorker}`
        });
    }
    else{
        rlsDetailVisible.value=true
    }
}

const isDownloading = ref<boolean>(false);

const generaLetteraIncarico = async () => {
    isDownloading.value = true;
    const downloadResponse = await cesfService.generaLetteraIncaricoRlst(props.idCompany, `lettera_incarico_RLST_${rls.value.cognome}_${rls.value.nome}`);
        if(downloadResponse){
            let blob = new Blob([downloadResponse.fileStream], { type: downloadResponse.contentType });
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = downloadResponse.fileName;
            link.click()
            URL.revokeObjectURL(link.href)
        } else{
            kToast.showError("Errore", "E' avvenuto un errore durante il download del documento");
        }
    isDownloading.value = false;
}

</script>