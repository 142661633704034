<template>
    <div v-if="isRlsInEditMode" class="row px-3 py-3 border-1 mt-3">
        <div class="col-12 d-flex justify-content-between mb-5 position-relative">
            <h3 class="m-0">Lista dipendenti</h3>

            <a @click="undoRlsEditMode" class="btn btn-xs btn-icon p-0" role="button">
                <KIcon class="icon-primary" icon="it-close-big" />
                <span class="ml-4 text-primary">ANNULLA</span>
            </a>

            <!-- <KIcon class="icon-primary icon-xl position-absolute icon-chat-anagrafica" icon="it-comment" /> -->
        </div>
        <div class="col-6">
            <KInputSearch
                placeholder="Cerca dipendente"
                form-group-class="mb-1"
                v-model="workerSearchTerm"
                @search="onWorkerFullSearch" />
        </div>
        <DataTable :value="visibleWorkers" dataKey="id" responsiveLayout="scroll" :loading="loading">
            <Column field="lastName" header="COGNOME"></Column>
            <Column field="name" header="NOME"></Column>
            <Column header="CORSO RLS">
                <template #body="slotProps">
                    <span :class="{'text-danger':!slotProps.data.idRLSCourseOutcome, 'text-success':slotProps.data.idRLSCourseOutcome }">
                        {{ slotProps.data.idRLSCourseOutcome ? "Fatto presso il CESF" : "Non fatto o fatto esternamente" }}
                    </span>
                </template>
            </Column>
            <Column header="AGGIORNAMENTO RLS">
                <template #body="slotProps">
                    <span :class="{'text-danger':!slotProps.data.idRLSAggiornamentoOutcome, 'text-success':slotProps.data.idRLSAggiornamentoOutcome }">
                        {{ slotProps.data.idRLSAggiornamentoOutcome ? "Fatto presso il CESF" : "Non fatto o fatto esternamente" }}
                    </span>
                </template>
            </Column>
            <Column header="SCADENZA CORSO RLS">
                <template #body="slotProps">
                    {{ slotProps.data.dataScadenzaCorso ? toDateString(slotProps.data.dataScadenzaCorso) : "-" }}
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <div style="text-align: right">
                        <button type="button" class="btn btn-primary"
                            @click="openSubstituteRLSPanel(slotProps.data)"> NOMINA </button>
                    </div>
                </template>
            </Column>

            <template #empty> Nessun dipendente trovato </template>
            <template #footer>
                <KPaginator
                    :currentPage="finder.pageIndex"
                    @page-selected="onPageChange"
                    show-total-count
                    :total-count="totalCount"
                    :totalPages="totalPages"/>
                </template>
            </DataTable>
            <KModalSlide :dimension="ModalDimension.lg" title="Nomina RLS" v-model="isSetRLSPanelOpen">
                <KSpinner :loading="loadingSetRLSPanel">
                <div class="d-flex">
                    <div class="col-6 mb-3">
                        <h6>Lavoratore</h6>
                        {{ currentWorker.name }} {{ currentWorker.lastName }}
                    </div>
                    <div v-if="currentWorker.dataScadenzaCorso" class="col-6 mb-3">
                        <h6>Scadenza ultimo corso fatto</h6>
                        {{ toDateString(currentWorker.dataScadenzaCorso) }}
                    </div>
                </div>

                <div class="d-flex">
                    <div class="col-6">
                        <h6>Data nomina</h6>
                        <KDatePicker form-group-class="mb-1" v-model="requestRlsNomina.dataNomina" />
                    </div>
                    <div class="col-6">
                        <h6>Verbale nomina</h6>
                        <KInputFile v-model="requestRlsNomina.fileNomina" />
                    </div>
                </div>
                <div class="col-12">
                    <h6>Data scadenza corso RLS</h6>
                    <KDatePicker form-group-class="mb-2" v-model="requestRlsNomina.dataScadenzaCorso" />
                </div>
                <div class=" col-12" v-if="isUploadAttestatoVisibile">
                    <h6>Attestato corso RLS</h6>
                    <KInputFile v-model="requestRlsNomina.fileAttestato" />
                    <small class="text-danger">Il corso RLS non è stato fatto presso il CESF, <br/> caricare il file dell'attestato se il corso è stato effettuato presso un altro ente</small>
                </div>
                <div class="col-12" v-if="isUploadAggiornamentoVisibile">
                    <h6>Attestato corso aggiornamento RLS</h6>
                    <KInputFile v-model="requestRlsNomina.fileAggiornamento" />
                    <small class="text-danger">Il corso RLS risulta scaduto, <br/> aggiornare il corso o, se necessario, caricare il file dell'attestato del corso di aggiornamento se il corso è stato effettuato presso un altro ente</small>
                </div>
            </KSpinner>
            <template #actions>
                <button class="btn btn-outline-primary btn-xs text-uppercase" type="button"
                    @click="isSetRLSPanelOpen = false">Annulla</button>
                <button :disabled="!canSendRequest" class="btn btn-primary btn-xs text-uppercase"
                    type="button" @click="completaNomina">Completa nomina</button>
            </template>
        </KModalSlide>
    </div>
    <div v-else class="row px-3 py-3 border-1 mt-3">
        <div class="col-12 d-flex justify-content-between mb-5 position-relative">
            <h3 class="m-0">RLS attuale</h3>

            <a v-if="rlsAttivo.status != 'InAcceptance'" @click="startRlsEditMode" class="btn btn-xs btn-icon p-0" role="button">
                <KIcon class="icon-primary" icon="it-pencil" />
                <span class="ml-4 text-primary">{{rlsAttivo.isTerritorial ? "NOMINA INTERNO" : "SOSTITUISCI"}}</span>
            </a>

            <!-- <KIcon class="icon-primary icon-xl position-absolute icon-chat-anagrafica" icon="it-comment" /> -->
        </div>
        <p v-if="rlsAttivo.status == 'InAcceptance'">Dati in verifica</p>
        <div class="col-12 col-md-5">
            <h6 class="">Nome</h6>
            <h5>{{rlsAttivo.nome}} {{ rlsAttivo.cognome }}</h5>
        </div>
        <div class="col-12 col-md-5">
            <h6 class="">Tipo</h6>
            <h5>{{rlsAttivo.isTerritorial ? `RLST (${rlsAttivo.territorialName})` : "RLS"}}</h5>
        </div>
        <div class="col-12 col-md-5">
            <h6 class="">Data Nomina</h6>
            <h5>{{rlsAttivo.dataNomina ? toDateString(rlsAttivo.dataNomina) : "-"}}</h5>
        </div>
        <div v-if="!rlsAttivo.isTerritorial" class="col-12 col-md-5">
            <h6 class="">Valido fino a</h6>
            <h5>{{rlsAttivo.validoFinoA ? toDateString(rlsAttivo.validoFinoA) : "-"}}</h5>
        </div>
        <div v-else class="col-md-5" />
        <div v-if="rlsAttivo.isTerritorial" class="col-12 col-md-5">
            <h6 class="">Telefono</h6>
            <h5>{{rlsAttivo.telefono || '-' }}</h5>
        </div>
        <div v-if="rlsAttivo.isTerritorial" class="col-12 col-md-5">
            <h6 class="">Indirizzo email</h6>
            <h5>{{rlsAttivo.eMail || '-' }} </h5>
        </div>
        <!-- <div v-if="!rlsAttivo.isTerritorial" class="col-12 col-md-5">
            <h6 class="">Ente formazione</h6>
            <h5>{{rlsAttivo.enteFormazione || '-'}}</h5>
        </div>
        <div v-if="!rlsAttivo.isTerritorial" class="col-12 col-md-5">
            <h6 class="">Luogo formazione</h6>
            <h5>{{rlsAttivo.luogoFormazione || '-' }} </h5>
        </div> -->
    </div>
</template>

<script setup lang="ts">

//IMPORTS GENERAL
import { nextTick, onMounted, ref, watch, computed } from "vue";
import { KSpinner, KTab, KTabs, useFilters, usePlatformApi, KInputSearch, KModalSlide, KInputFile, KSelect, KDatePicker, KInputDate, ModalDimension } from "@k-digitale/kappa";
import { useCesfUser } from "../../../composables";
import { learningExtensionService } from "../../../services/learningExtensions/LearningExtensionsServices";
import { learningWrappersService} from "../../../services/learningWrappers/LearningWrappersServices";
import { cesfService } from "../../../services/cesf/CesfServices";
import { WorkersRLSWrapper } from "../../../services/learningWrappers/Models";
import { ResponsabileSicurezzaLavoratori, RLSWorkerData } from "../../../services/cesf/Models";
import { RequestRLSNomina } from "../../../services/cesf/Requests";
import { WorkerFinder } from "../../../learning/registries/Models";

const { toDateString } = useFilters();
const { cesfUser } = useCesfUser();
const { kToast, kConfirm } = usePlatformApi();

const isRlsInEditMode = ref<boolean>();

onMounted(async () => {
    await loadData();
});

const rlsAttivo = ref<ResponsabileSicurezzaLavoratori>({} as ResponsabileSicurezzaLavoratori);
const loading = ref<boolean>();
const workers = ref<WorkersRLSWrapper[]>([]);
const visibleWorkers = computed(() => {
    if(rlsAttivo.value){
        if(rlsAttivo.value.isTerritorial){
            return workers.value;
        } else{
            return workers.value.filter(w => w.id != rlsAttivo.value.idWorker);
        }
    } else{
        return workers.value;
    }
})
const loadData = async () => {
    loading.value = true;

    const rlsAttivoResponse = await learningExtensionService.getCompanyRls(cesfUser.value.companyId);
    if(rlsAttivoResponse){
        rlsAttivo.value = rlsAttivoResponse;
    }
    await loadCompanyWorkers();

    loading.value = false;
}

const loadCompanyWorkers = async () => {
    loading.value = true;
    finder.value.companyId = cesfUser.value.companyId;
    const workersResponse = await learningWrappersService.getWorkersRlsWrappersByFinder(finder.value);
    if(!workersResponse){
        kToast.showError("Errore", "E' avvenuto un errore durante il caricamento dei dipendenti")
    }
    workers.value = workersResponse.items;
    totalCount.value = workersResponse.totalCount;

    loading.value = false;
}

const startRlsEditMode = () => {
    isRlsInEditMode.value = true;
}
const undoRlsEditMode = () => {
    isRlsInEditMode.value = false;
}

const totalPages = computed(() => {
    const result = Math.ceil(totalCount.value / finder.value.pageSize);
    return  result == 0 ? 1 : result;
})

const finder = ref<WorkerFinder>({
    pageIndex: 1,
    pageSize: 10,
} as WorkerFinder);
const totalCount = ref<number>();

const onPageChange = (newPage: number) => {
    finder.value.pageIndex = newPage;
    loadCompanyWorkers();
};

const workerSearchTerm = ref<string>();
const onWorkerFullSearch = () => {
    finder.value.globalSearchTearm = workerSearchTerm.value;
    loadCompanyWorkers();
}

//Gestione Pannello Inserimento
const isSetRLSPanelOpen = ref<boolean>(false);
const loadingSetRLSPanel = ref<boolean>(false);
const requestRlsNomina = ref<RequestRLSNomina>({} as RequestRLSNomina);
const currentWorker = ref<WorkersRLSWrapper>({} as WorkersRLSWrapper);
const openSubstituteRLSPanel = async (worker : WorkersRLSWrapper) => {
    currentWorker.value = worker;

    requestRlsNomina.value = {
        idWorker: worker.id,
        idCompany: cesfUser.value.companyId,
        dataScadenzaCorso: worker.dataScadenzaCorso,
    } as RequestRLSNomina;
    isSetRLSPanelOpen.value = true;
}

const isBaseCourseDone = computed(() => {
    return currentWorker.value.idRLSCourseOutcome;
})

const isUploadAttestatoVisibile = computed(() => {
    return !isBaseCourseDone.value;
})

const isUploadAggiornamentoVisibile = computed(() => {
    if(new Date(currentWorker.value.dataScadenzaCorso) < new Date()){
        return true;
    }

    return false;
})

const completaNomina = async () => {
    kConfirm.requireConfirm(
        `Una volta inviata la nomina un operatore CESF controllerà i dati inviati, al termine della verifica il lavoratore sarà confermato come RLS. \r\nFino al termine della verifica non sarà possibile cambiare i dati inviati`,
        async () => {
            loadingSetRLSPanel.value = true;
            const result = await cesfService.requestNominaRls(requestRlsNomina.value);
            //TODO baco del KinputFile svuoto i file altrimenti dà errore
            requestRlsNomina.value.fileAggiornamento = [];
            requestRlsNomina.value.fileAttestato = [];
            requestRlsNomina.value.fileNomina = [];
            if(result && result.success){
                kToast.showSuccess("Completato", "La richiesta di nomina è stata inviata con successo");
                isSetRLSPanelOpen.value = false;
                //Morte alle KMODALSLIDE!!
                setInterval(async () =>{
                    await loadData();
                    isRlsInEditMode.value = false;
                }, 500);
            } else{
                kToast.showError("Errore", "E' avvenuto un errore durante la nomina dell'RLS");
            }
            loadingSetRLSPanel.value = false;
        },
        () => {},
        "Completare la nomina dell'RLS?"
    );
}

const canSendRequest = computed(() => {
    let result = !!requestRlsNomina.value.dataNomina &&
        (requestRlsNomina.value.fileNomina?.length ?? 0) > 0;

    result = result && !!requestRlsNomina.value.dataScadenzaCorso;

    if(isUploadAttestatoVisibile.value){
        result = result && ((requestRlsNomina.value.fileAttestato?.length ?? 0) > 0);
    }

    // if(isUploadAggiornamentoVisibile.value){
    //     result = result && ((requestRlsNomina.value.fileAggiornamento?.length ?? 0) > 0);
    // }

    return result;

});

</script>

<style lang="scss">
</style>