<template>
    <Page>
        <KBackButton />
        <div v-if="course?.id" class="row mt-5 mb-3">
            <div class="col-12">
                <div class="c-line kd-header-full">
                    <div class="header-top-title">Corso</div>
                    <div class="row">
                        <div class="col-12 col-md-8 col-lg-12">
                            <h3 class="title-text">
                                {{ course.title }}
                            </h3>
                        </div>
                    </div>
                    <K3ColContainer rowClass="m-0" col1Class="p-1" col2Class="p-1" col3Class="p-1">
                        <template #col1>
                            <p class="header-sub-detail m-0">Durata</p>
                            <p>{{ course.totalHours }} ore</p>
                        </template>
                    </K3ColContainer>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-between mb-3 position-relative">
            <h3 class="m-0">In partenza</h3>

            <a @click="goToPreRegistrationPage()" class="btn btn-xs btn-icon p-0 k-btn-flat" role="button">
                <KIcon class="icon-primary" icon="it-calendar" />
                <span class="ml-4 text-primary">RICHIEDI PRE-ISCRIZIONE</span>
            </a>
        </div>
        <div class="col-12">
            <DataTable :value="startingCourses" dataKey="id" responsiveLayout="scroll" :loading="loading">
                <Column header="DATA INIZIO">
                    <template #body="slotProps">
                        {{ toDateString((slotProps.data as CourseInstance).startDate) }}
                    </template>
                </Column>
                <Column header="ISCRITTI">
                    <template #body="slotProps">
                        {{ slotProps.data.subscribedNumber }}
                    </template>
                </Column>
                <Column header="DISPONIBILITA'" >
                    <template #body="slotProps">
                        {{ slotProps.data.studentsNumber - slotProps.data.subscribedNumber }}
                    </template>
                </Column>
                <Column >
                    <template #body="slotProps">
                        <div style="text-align: right">
                            <button type="button" @click="goToSubscriptionPage(slotProps.data.id)" class="btn btn-primary"> ISCRIVITI </button>
                        </div>
                    </template>
                </Column>

                <template #empty> Nessun corso in partenza trovato, puoi richiedere la pre-iscrizione </template>
            </DataTable>
        </div>
        <div>
            <h3>Corsi collegati</h3>
            <DataTable :value="relatedCourses" dataKey="id" responsiveLayout="scroll" :loading="loading">
                <Column header="CORSO">
                    <template #body="slotProps">
                        {{ slotProps.data.title }}
                    </template>
                </Column>
                <Column header="EDIZIONE IN PARTENZA">
                    <template #body="slotProps">
                        <div v-if="slotProps.data.firstInstanceId">
                            data inizio: {{ toDateString(slotProps.data.firstInstanceStartDate) }}
                            <button type="button" @click="goToSubscriptionPage(slotProps.data.firstInstanceId)" class="btn btn-primary"> ISCRIVITI </button>
                        </div>
                        <div v-else>
                            Nessuna edizione in partenza trovata
                        </div>
                        {{ slotProps.data.subscribedNumber }}
                    </template>
                </Column>
                <Column >
                    <template #body="slotProps">
                        <div style="text-align: right">
                            <button type="button" @click="goToCourseDetail(slotProps.data.templateId)" class="btn btn-primary"> DETTAGLIO </button>
                        </div>
                    </template>
                </Column>

                <template #empty> Nessun corso collegato trovato </template>
            </DataTable>
        </div>
        <h2>Le mie iscrizioni</h2>
        <h5>Effettuate</h5>
        <DataTable table-class="k-data-table k-row-clickable"
            @sort=" onCourseParticipationSort($event)"
            @row-click="goToSubscriptionPage($event.data.courseInstanceId)"
            :value="courseParticipations"
            :loading="loading"
            dataKey="id"
            responsiveLayout="scroll">

            <Column sortable field="name" header="Lavoratore">
                <template #body="slotProps">
                    {{slotProps.data.student.worker.lastName}} {{slotProps.data.student.worker.name}}
                </template>
            </Column>
            <Column sortable field="courseInstance.startDate" header="Data corso" >
                <template #body="slotProps">
                    {{slotProps.data.timestamp ? toDateString(slotProps.data.courseInstance.startDate) : '-' }}
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <a @click="goToSubscriptionPage(slotProps.data.courseInstanceId)" class="read-more mt-3 float-end" role="button" title="DETTAGLIO ISCRIZIONE">
                        <span class="text">
                            DETTAGLIO ISCRIZIONE
                            <span class="visually-hidden">DETTAGLIO ISCRIZIONE</span>
                        </span>
                        <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                    </a>
                </template>
            </Column>

            <template #empty>
                Nessuna iscrizione trovata.
            </template>
        </DataTable>

        <KPaginator :current-page="pageInfo.pageIndex" :total-count="pageInfo.totalCount" :total-pages="pageInfo.totalPages"
            :show-total-count="true" @page-selected="goToPage">
        </KPaginator>

        <h5>Richieste</h5>
        <DataTable table-class="k-data-table k-row-clickable"
            @sort=" onCourseParticipationRequestSort($event)"
            :loading="loading"
            :value="courseParticipationRequests"
            dataKey="id"
            responsiveLayout="scroll">

            <Column sortable field="name" header="Lavoratore">
                <template #body="slotProps">
                    {{slotProps.data.student.worker.lastName}} {{slotProps.data.student.worker.name}}
                </template>
            </Column>

            <template #empty>
                Nessuna pre-iscrizione trovata.
            </template>
        </DataTable>
        <KPaginator :current-page="preIscrizioniPageInfo.pageIndex" :total-count="preIscrizioniPageInfo.totalCount" :total-pages="preIscrizioniPageInfo.totalPages"
            :show-total-count="true" @page-selected="goToPartRequestPage">
        </KPaginator>
    </Page>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import { useCourseStore } from "../store";
import { useRouter, useRoute } from "vue-router";
import { useCesfUser} from "@/composables";
import { CourseTemplate } from "@/learning/Templates/Models";
import { CourseInstance, CourseInstanceFinder, CourseInstanceStatus, CourseParticipation, CourseParticipationFinder, CourseParticipationRequest, CourseParticipationRequestFinder, CourseParticipationRequestStatus, CourseParticipationStatus } from "@/learning/instances/Models";
import { CourseInstanceWrapper } from "../../../services/learningWrappers/Models";
import { PageInfo, SortingOrder, useFilters } from "@k-digitale/kappa"

const router = useRouter();
const { toDateString } = useFilters();
const route = useRoute();
const { cesfUser } = useCesfUser();
const courseStore = useCourseStore();

const course = ref<CourseTemplate>({} as CourseTemplate)
const startingCourses = ref<CourseInstanceWrapper[]>([])

const loading = ref<boolean>(false);

const idCourse = computed( () => {
    let id = route.params.id as unknown as string;
    if(id){
        return id;
    }
    return "";
})

watch(idCourse, async () => {
    await loadData();
})

const courseInstanceFinder = ref<CourseInstanceFinder>({
 pageIndex: -1,
 pageSize: -1,
} as CourseInstanceFinder)

const relatedCourses = ref<any[]>([]);

const loadData = async () => {
    window.scrollTo(0,0);
    loading.value = true;
    const courseResponse = await courseStore.getCourse(idCourse.value);
    if(courseResponse){
        course.value = courseResponse;
        courseInstanceFinder.value.courseTemplateId = course.value.id;
        startingCourses.value = await courseStore.getStartingCoursesWrapper(courseInstanceFinder.value);
        courseParticipationsFinder.value.courseTemplateId = course.value.id;
        courseParticipationRequestsFinder.value.courseTemplateId = course.value.id;
        await Promise.all([loadParticipations(), loadParticipationRequest()]);

        const requiredCourses = await courseStore.getRequiredCourseTemplates(course.value.id);
        for(var requiredCourse of requiredCourses){
            const relatedCourseInstances = await courseStore.getStartingCourses({
                pageIndex: 1,
                courseTemplateId: requiredCourse.id,
                status: CourseInstanceStatus.StudentsSigninIn,
                startAfterDate: new Date(),
                excludeFullCourses: true,
                pageSize: 1,
                sortField: "startDate",
                sortOrder: SortingOrder.Ascending
            } as CourseInstanceFinder);

            const relatedCourseFirstInstance = relatedCourseInstances.length > 0 ?
                relatedCourseInstances[0] :
                null;

            relatedCourses.value.push({
                templateId: requiredCourse.id,
                title: requiredCourse.title,
                firstInstanceId: relatedCourseFirstInstance?.id,
                firstInstanceStartDate: relatedCourseFirstInstance?.startDate,
            });
        }
    }
    loading.value = false;
}

onMounted(async () => {
    loadData();
});

const goToSubscriptionPage = (id: string) => {
    router.push({ name: 'course-registration', params: { id: id } });
}
const goToPreRegistrationPage = () => {
    router.push({ name: 'course-pre-registration', params: { id: course.value.id } });
}

const goToCourseDetail = (id: string) => {
    router.push({ name: 'course-detail', params: { id: id } });
};

//tabella partecipazioni
const pageInfo = ref<PageInfo>({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
});

const courseParticipationsFinder = ref<CourseParticipationFinder>({
    queryInstancesRelations: true,
    queryRegistriesRelations: true,
    pageIndex: pageInfo.value.pageIndex,
    pageSize: pageInfo.value.pageSize,
    companyId: cesfUser.value.companyId,
    status: CourseParticipationStatus.SignedUp,
    sortField: "courseInstance.startDate",
    sortOrder: SortingOrder.Descending,
} as CourseParticipationFinder);

const courseParticipations = ref<CourseParticipation[]>([]);
const loadParticipations = async () => {
    loading.value = true;
    const results = await courseStore.getParticipations(courseParticipationsFinder.value);
    if(results){
        pageInfo.value = results;
        courseParticipations.value = results.items;
    }
    loading.value = false;
}

const goToPage = async (selectedPage: number) => {
    if(pageInfo.value.pageIndex != selectedPage) {
        pageInfo.value.pageIndex = selectedPage;
        courseParticipationsFinder.value.pageIndex = pageInfo.value.pageIndex;
        courseParticipationsFinder.value.pageSize = pageInfo.value.pageSize;
        await loadParticipations();
    }
}

const onCourseParticipationSort = async (event : any) => {
    courseParticipationsFinder.value.sortField = event.sortField;
    courseParticipationsFinder.value.sortOrder = event.sortOrder;
    await loadParticipations();
}

//tabella pre-iscrizioni
const preIscrizioniPageInfo = ref<PageInfo>({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
});

const courseParticipationRequestsFinder = ref<CourseParticipationRequestFinder>({
    queryRelations: true,
    status: CourseParticipationRequestStatus.NotSignedUp,
    companyId: cesfUser.value.companyId,
    pageIndex: pageInfo.value.pageIndex,
    pageSize: pageInfo.value.pageSize,
    sortField: "timestamp",
} as CourseParticipationRequestFinder);

const courseParticipationRequests = ref<CourseParticipationRequest[]>([]);
const loadParticipationRequest = async () => {
    loading.value = true;
    const results = await courseStore.getParticipationRequests(courseParticipationRequestsFinder.value);
    if(results){
        preIscrizioniPageInfo.value = results;
        courseParticipationRequests.value = results.items;
    }
    loading.value = false;
}

const goToPartRequestPage = async (selectedPage: number) => {
    if(preIscrizioniPageInfo.value.pageIndex != selectedPage) {
        preIscrizioniPageInfo.value.pageIndex = selectedPage;
        courseParticipationRequestsFinder.value.pageIndex = pageInfo.value.pageIndex;
        courseParticipationRequestsFinder.value.pageSize = pageInfo.value.pageSize;
        await loadParticipationRequest();
    }
}

const onCourseParticipationRequestSort = async (event : any) => {
    courseParticipationRequestsFinder.value.sortField = event.sortField;
    courseParticipationRequestsFinder.value.sortOrder = event.sortOrder;
    await loadParticipationRequest();
}

</script>

<style lang="scss">
.go-to-course-accordion {
    top: 19px;
    right: 70px;
}

.kd-header-full {
    margin: -20px -30px -20px;
    padding: 20px;
    color: #333333;
    background-color: #f4f4f4;
}

.course-state-code {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}
</style>
