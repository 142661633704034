<template>
    <Page :loading="loading" v-if="corso != null">
        <PageHeader title="Pre-Iscrizione"></PageHeader>
        <KBackButton />
        <div class="row mt-5">
            <div class="col-12">
                <div class="c-line kd-header-full">
                    <div class="header-top-title">Corso</div>
                    <div class="row">
                        <div class="col-12 col-md-8 col-lg-12">
                            <h3 class="title-text">
                                {{ corso.title }}
                            </h3>
                        </div>
                    </div>
                    <K3ColContainer rowClass="m-0" col1Class="p-1" col2Class="p-1" col3Class="p-1">
                        <template #col1>
                            <p class="header-sub-detail m-0">Durata</p>
                            <p>{{ corso.totalHours }} ore</p>
                        </template>
                    </K3ColContainer>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 p-0">
                <div class="" id="collapseExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="dati-utente">
                            <button
                                    class="accordion-button h4 fw-normal text-decoration-none border-none border-bottom"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-user-data"
                                    aria-expanded="true"
                                    aria-controls="collapse1c">
                                Verifica i tuoi dati
                            </button>
                        </h2>
                        <div id="collapse-user-data" class="accordion-collapse collapse show" role="region" aria-labelledby="dati-utente">
                            <div class="accordion-body mt-5">
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.extCode2" :labelText="'Codice cassa edile '" :class="'form-control-plaintext'" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.businessName" :labelText="'Ragione Sociale'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.partitaIVA" :labelText="'Partita IVA'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.fiscalCode" :labelText="'Codice Fiscale'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.address" :labelText="'Indirizzo'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.zipCode" :labelText="'CAP'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.city" :labelText="'Comune'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.provincia" :labelText="'Provincia'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.phone" :labelText="'Telefono'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.fax" :labelText="'Fax'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.cellPhone" :labelText="'Cellulare'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.email" :labelText="'Email'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="row">
            <div class="col-12">
                <h4 class="fw-normal">Chi desideri iscrivere?</h4>
            </div>
        <CourseRegistrationWorkerList
            :courseTemplateId="corso.id"
            v-model="dipendentiAggiuntiItems"
            :workersList="dipendentiCercatiItems"
            :subscribedWorkersId="subscribedWorkersId"
            @on-new-worker-saved="loadData"
            @on-search-employees="onSearchEmployee"
            />
        </div>

        <div class="row text-center">
            <div class="col-12">
                <button type="button" @click="sendSubscriptionRequest" class="btn btn-primary col-xs-8 col-md-4 col-lg-4 col-xl-3">INVIA RICHIESTA</button>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { courseService } from "../service";
import { CourseParticipationRequest, CourseParticipationRequestFinder, CourseParticipationRequestStatus} from "@/learning/instances/Models";
import { useRoute } from "vue-router";
import { profileService } from "@/modules/profile/service";
import { Company } from "@/learning/registries/Models";
import { employeeService } from "@/modules/employee/service";
import { instancesService } from "@/learning/instances/InstancesService";
import { useCesfUser} from "@/composables";
import { useCourseStore } from "../store";
import { useToast } from "primevue/usetoast";
import CourseRegistrationWorkerList from "./components/CourseRegistrationWorkerList.vue";

import { Student, StudentFinder, Worker, WorkerFinder } from "@/learning/registries/Models";
import { SaveCourseParticipationRequestListItemRequest } from "../requests";
import { SaveStudentRequest } from "@/learning/registries/Requests";

//IMPORT COMPONENTS
import { CourseTemplate } from "@/learning/Templates/Models";

const toast = useToast();
const route = useRoute();
const courseStore = useCourseStore();

const idCorso = ref(route.params.id);
const corso = ref<CourseTemplate>(null);
const company = ref<Company>({} as Company);
const { cesfUser, isUserCompany } = useCesfUser();

const loading = ref<boolean>(false);
const companyStudents = ref<Student[]>([]);
const companyParticipationRequests = ref<CourseParticipationRequest[]>([]);
const dipendentiCercatiItems = ref<any[]>([]);

const subscribedWorkersId = computed(() => {
    return companyParticipationRequests.value.map(cpr => cpr.student.workerId );
})

onMounted(async () => {
    loading.value = true;
    corso.value = await courseService.getCourseTemplate(idCorso.value as string);
    if(isUserCompany.value){
        company.value = await profileService.getCompany(cesfUser.value.companyId);

        await loadStudents();
        await loadCourseParticipationRequest();
    }
    loading.value = false;

});

const loadData = async () =>{
    loading.value = true;
    // await loadWorkers();
    await loadStudents();
    await loadCourseParticipationRequest();
    loading.value = false;
}

const loadStudents = async (fullTextSearch?: string) => {
    const studentFinder = {
        companyId: cesfUser.value.companyId,
        globalSearchTearm: fullTextSearch,
    } as StudentFinder;

    var workerResponse = await employeeService.getStudents(studentFinder);
    if (workerResponse && workerResponse.items) {
        companyStudents.value = workerResponse.items;
        dipendentiCercatiItems.value = workerResponse.items.map(s => {return {notSubscribable: s.notSubscribable, toBeVerified: s.toBeVerified, ...s.worker}})
    }
}

const loadCourseParticipationRequest = async () => {
    const courseParticipationRequestFinder = {
        companyId: cesfUser.value.companyId,
        courseTemplateId: corso.value.id,
        status: CourseParticipationRequestStatus.NotSignedUp,
        queryRelations: true,
    } as CourseParticipationRequestFinder;

    var workerResponse = await instancesService.getCourseParticipationRequestsByFinder(courseParticipationRequestFinder);
    if (workerResponse && workerResponse.items) {
        companyParticipationRequests.value = workerResponse.items;
    }
}


const searchTimeout = ref<any>(null);
const onSearchEmployee = function (e: any) {
    clearTimeout(searchTimeout.value);

    searchTimeout.value = setTimeout(async () => {
        await loadStudents(e.target.value);
    }, 300);
};


const dipendentiAggiuntiItems = ref<any[]>([]);
const sendSubscriptionRequest = async () => {
    const request = dipendentiAggiuntiItems.value.map(da => {
        const relatedStudent = companyStudents.value.find(s => s.workerId == da.id);
        if(relatedStudent){
            return{
                companyId: cesfUser.value.companyId,
                courseTemplateId: corso.value.id,
                studentId: relatedStudent.id,
            } as SaveCourseParticipationRequestListItemRequest;
        } else{
            return{
                companyId: cesfUser.value.companyId,
                courseTemplateId: corso.value.id,
                saveStudentRequest: {
                    workerId: da.id,
                    toBeVerified: true,
                } as SaveStudentRequest,
            } as SaveCourseParticipationRequestListItemRequest;
        }
    } ) as SaveCourseParticipationRequestListItemRequest[];

    loading.value = true;
    const response = await courseStore.saveCourseParticipationRequestsSave(request);
    if(response){
        toast.add({severity: "success",summary: "Pre-iscrizioni richieste con successo",life: 3000});
        await loadCourseParticipationRequest();
        await loadStudents();
        // await loadWorkers();
        dipendentiAggiuntiItems.value = [];
    } else{
        toast.add({severity: "error",summary: "Errore nella richiesta delle pre-iscrizioni",life: 3000});
    }
    loading.value = false;
}
</script>

<style scoped lang="scss">
.kd-header-full {
    margin: -20px -30px -20px;
    padding: 20px;
    color: #333333;
    background-color: #f4f4f4;
}
.kd-already-sub {
    background-color: #f4f4f4;
}
.title-text {
    font-size: 40px;
}
.title-text-underline {
    text-decoration: underline;
}
.top-text {
    font-size: 14px;
    color: #757575;
}
.focus-text {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 400;
}
.exit-menu {
    color: #0066cc;
    font-size: 16px;
    //font-weight: 600;
    justify-content: flex-end;

    .exit-menu-icon {
        fill: #0066cc;
    }
}
.header-top-title,
.header-sub-detail {
    color: #757575;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
}
</style>
