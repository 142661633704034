import { RouteRecordRaw } from "vue-router";

import Course from './pages/Course.vue';
import CourseRegistration from './pages/CourseRegistration.vue';
import CoursePreRegistration from './pages/CoursePreRegistration.vue';
import CoursesList from './pages/CoursesList.vue';
import CourseDetail from './pages/CourseDetail.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/course',
        component: Course,
        meta: {
            requiresAuth: true,
            menu: {
                order: 4,
                label: 'Corsi',
                iconAsset: 'icons/ico-corsi.svg'
            }
        }
    },
    {
        path: '/course/registration/:id',
        name: 'course-registration',
        component: CourseRegistration,
        meta: {
            requiresAuth: true,
            menu: {
                label: 'Iscrizione corso',
                iconAsset: 'icons/ico-corsi.svg',
                hidden: true
            }
        }
    },
    {
        path: '/course/pre-registration/:id',
        component: CoursePreRegistration,
        name: 'course-pre-registration',
        meta: {
            requiresAuth: true,
            menu: {
                label: 'Iscrizione corso',
                iconAsset: 'icons/ico-corsi.svg',
                hidden: true
            }
        }
    },
    {
        path: "/courses",
        component: CoursesList,
        meta: {
            requiresAuth: true,
            menu: {
                label: "Corsi",
                iconAsset: "icons/ico_corsi.svg",
                hidden: true
            }
        }
    },
    {
        path: "/course-detail/:id",
        name: 'course-detail',
        component: CourseDetail,
        meta: {
            requiresAuth: true,
        }
    }
];

export default routes;