import { RouteRecordRaw } from "vue-router";

import Employee from "./pages/Employee.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/employee",
        component: Employee,
        meta: {
            requiresAuth: true,
            menu: {
                order: 2,
                label: "profilo",
                iconAsset: "icons/ico-dipendenti.svg"
            }
        }
    }
];

export default routes;
