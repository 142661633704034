<template>
    <Page :loading="loading">
        <PageHeader :title="employeeFullName"></PageHeader>

        <div class="row mx-0" v-if="employee">
            <div class="col-12">
                <div class="row d-flex align-items-center mt-3 bg-light py-3">
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Data Assunzione</h6>
                        <h5>{{ toDateString(employee.hiringDate) }}</h5>
                    </div>
                    <div class="col-12 col-md-5 col-lg-6">
                        <h6 class="">Azienda</h6>
                        <h5>{{ employee.company ? employee.company.businessName : "" }}</h5>
                    </div>
                    <div class="col-12 col-md-4 col-lg-2">
                        <h6 class="">Attestati</h6>
                        <h5>{{ participationsNumber }}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-4" v-if="employee">
            <div class="col-12 ">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a :class="activeTab == 'anagrafica' || !activeTab ? 'active' : ''" class="nav-link px-5" id="Anagrafica-tab" data-bs-toggle="tab" href="#Anagrafica" role="tab" aria-controls="Anagrafica" aria-selected="false">Anagrafica</a>
                    </li>
                    <li class="nav-item">
                        <a :class="activeTab == 'attestati' ? 'active' : ''" class="nav-link px-5" id="Attestati-tab" data-bs-toggle="tab" href="#Attestati" role="tab" aria-controls="Attestati" aria-selected="true">Attestati</a>
                    </li>

                </ul>
                <div class="tab-content" id="myTabContent" >
                    <div :class="activeTab == 'anagrafica' || !activeTab ? 'active show' : ''"  class="tab-pane py-4 fade" id="Anagrafica" role="tabpanel" aria-labelledby="Anagrafica-tab">
                        <div v-if="isRegistryInEditMode" class="row px-3 py-3 border-1 mt-3">
                            <form
                                id="formEditRegistryData"
                                class="needs-validation"
                                autocomplete="off"
                            >
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                                        <h3 class="m-0">Modifica dati anagrafica</h3>
                                        <a @click="stopEditRegistryData" class="btn btn-xs btn-icon p-0" role="button">
                                            <KIcon class="icon-primary" icon="it-close-big" />
                                            <span class="ml-4 text-primary">ANNULLA</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-5">
                                        <KInputText v-kvalidate="kValidation.getField('name')" v-model="workerToSave.name" labelText="Nome *"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-5">
                                        <KInputText v-kvalidate="kValidation.getField('lastName')" v-model="workerToSave.lastName" labelText="Cognome *"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-2">
                                        <KSelect v-model="workerToSave.sex" :options="workerSexDropdownOptions" labelText="Sesso"></KSelect>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KInputText v-kvalidate="kValidation.getField('fiscalCode')" v-model="workerToSave.fiscalCode" labelText="Codice Fiscale *"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KDatePicker v-kvalidate="kValidation.getField('birthdate')" :max-date="new Date()" class="w-full" v-model="workerToSave.birthdate" labelText="Data di nascita *" />
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KInputText v-kvalidate="kValidation.getField('birthplace')" v-model="workerToSave.birthplace" labelText="Luogo di nascita *"></KInputText>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KDatePicker class="w-full" v-model="workerToSave.hiringDate" labelText="Data assunzione" />
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KSelect v-model="workerToSave.qualification" :options="workerQualificationDropDownOptions" labelText="Qualifica"></KSelect>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-4">
                                        <KSelect v-model="workerToSave.status" :options="workerStatusDropDownOptions" labelText="Status"></KSelect>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button @click.prevent="saveWorkerRegistryData" class="btn btn-primary mt-2" type="submit">SALVA</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-else class="row px-3 py-3 border-1 mt-3">
                            <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                                <h3 class="m-0">Anagrafica</h3>

                                <a @click="startEditRegistryData" class="btn btn-xs btn-icon p-0" role="button">
                                    <KIcon class="icon-primary" icon="it-pencil" />
                                    <span class="ml-4 text-primary">MODIFICA</span>
                                </a>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Cognome</h6>
                                <h5>{{ employee.lastName }}</h5>
                            </div>
                            <!-- <div class="col-12 col-md-5">
                                <KInputText :id="'lastName'" :labelText="'Cognome'" v-model:value="employee.worker.lastName"></KInputText>
                            </div> -->
                            <div class="col-12 col-md-5">
                                <h6 class="">Nome</h6>
                                <h5>{{ employee.name }}</h5>
                            </div>
                            <div class="col-12 col-md-12">
                                <h6 class="">Tipologia Documento</h6>
                                <h5>Codice Fiscale - {{ employee.fiscalCode }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Data di nascita</h6>
                                <h5>{{ toDateString(employee.birthdate) }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Luogo di nascita</h6>
                                <h5>{{ employee.birthplace }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Cittadinanza</h6>
                                <h5>{{ employee.citizenship }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Qualifica</h6>
                                <h5>{{ viewStatusHelper.formatWorkerQualification(employee.qualification) }}</h5>
                            </div>
                            <div class="col-12 col-md-4">
                                <h6 class="">Status</h6>
                                <h5>{{ viewStatusHelper.formatWorkerStatus(employee.status) }}</h5>
                            </div>
                        </div>

                        <div v-if="isRegistryContactsInEditMode" class="row px-3 py-3 border-1 mt-3">
                            <div class="row">
                                <div class="col-12 d-flex justify-content-between mb-3 position-relative">
                                    <h3 class="m-0">Modifica contatti</h3>
                                    <a @click="stopEditRegistryContacts" class="btn btn-xs btn-icon p-0" role="button">
                                        <KIcon class="icon-primary" icon="it-close-big" />
                                        <span class="ml-4 text-primary">ANNULLA</span>
                                    </a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.address" labelText="Indirizzo"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.city" labelText="Comune"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-2">
                                    <KInputText v-model="workerToSave.zipCode" labelText="CAP"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-2">
                                    <KInputText v-model="workerToSave.provincia" labelText="Provincia"></KInputText>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.email" labelText="E-mail"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.telephone" labelText="Telefono"></KInputText>
                                </div>
                                <div class="cols-12 col-md-12 col-lg-4">
                                    <KInputText v-model="workerToSave.cellPhone" labelText="Cellulare"></KInputText>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button @click.prevent="saveWorkerContacts" class="btn btn-primary mt-2">SALVA</button>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row px-3 py-3 border-1 mt-3">
                            <div class="col-12 d-flex justify-content-between mb-3">
                                <h3 class="m-0">Contatti</h3>

                                <a @click="startEditRegistryContacts" class="btn btn-xs btn-icon p-0" role="button">
                                    <KIcon class="icon-primary" icon="it-pencil" />
                                    <span class="ml-4 text-primary">MODIFICA</span>
                                </a>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Indirizzo</h6>
                                <h5>{{ employee.address }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Cap</h6>
                                <h5>{{ employee.zipCode }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Comune</h6>
                                <h5>{{ employee.city }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Provincia</h6>
                                <h5>{{ employee.provincia }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Telefono</h6>
                                <h5>{{ employee.telephone }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Cellulare</h6>
                                <h5>{{ employee.cellPhone }}</h5>
                            </div>
                            <div class="col-12 col-md-5">
                                <h6 class="">Email</h6>
                                <h5>{{ employee.email }}</h5>
                            </div>
                        </div>
                    </div>
                    <div :class="activeTab == 'attestati' ? 'active show' : ''" class="tab-pane py-4 fade" id="Attestati" role="tabpanel" aria-labelledby="Attestati-tab">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-borderless">
                                    <thead class="table-dark">
                                        <tr>
                                            <th class="table-padding" scope="col">CORSO</th>
                                            <th class="table-padding" scope="col">DATA</th>
                                            <th class="table-padding" scope="col">SCADENZA</th>
                                            <th class="table-padding" scope="col">STATO</th>
                                            <th class="table-padding" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="loading">
                                            <tr>
                                                <td colspan="4">
                                                    <KSpinner :loading="loading"/>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else-if="courseSummary.length > 0" v-for="(summary, index) in courseSummary" :key="index">
                                            <tr>
                                                <td>
                                                    <div class="d-flex">
                                                        <div class="d-flex align-items-center" v-if="summary.isOrphan" tabindex="1" v-tooltip.focus="'Il corso base non è stato fatto presso CESF'">
                                                            <KIcon icon="it-error" class="icon-danger"/>
                                                        </div>
                                                        <div class="accordion accordion-left-icon border-0" id="accordionCourseSummary">
                                                            <div class="accordion-item">
                                                                <h2  v-if="summary.details.length > 0" class="accordion-header" :id="`courseSummaryHeading${index}`">
                                                                    <button
                                                                        class="accordion-button border-0 text-black" type="button"
                                                                        data-bs-toggle="collapse"
                                                                        :data-bs-target="`#courseSummaryCollapse${index}`" aria-expanded="false"
                                                                        :aria-controls="`courseSummaryCollapse${index}`">
                                                                        {{ summary.name }}
                                                                    </button>
                                                                </h2>
                                                                <div style="padding: 14px 24px" v-else>
                                                                    {{ summary.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="table-padding">
                                                        {{toDateString(summary.dataCorso)}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="table-padding">
                                                        {{toDateString(summary.dataScadenza)}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="table-padding">
                                                        <div class="state-code-label inline-block mr-3" :class="`bg-${summary.statusCode ?? 'primary'}`" />
                                                        <div class="accordion-body p-0 inline-block">
                                                            {{ summary.statusDescription }}
                                                        </div>
                                                        <div v-if="summary.partialOutcomes">
                                                            Effettuate {{ summary.partialOutcomes.hoursDone }} ore su {{ summary.partialOutcomes.hoursForUpdate }} per l'aggiornamento
                                                        </div>
                                                    </div>
                                                </td>
                                                <td v-if="summary.certificateId">
                                                    <button type="button" class="btn btn-primary"
                                                        @click="() => downloadCertificate(summary.certificateId)"> ATTESTATO </button>
                                                </td>
                                            </tr>
                                            <tr v-if="summary.details.length > 0" :id="`courseSummaryCollapse${index}`"
                                                class="accordion-collapse collapse"
                                                data-bs-parent="#accordionCourseSummary" role="region"
                                                :aria-labelledby="`courseSummaryHeading${index}`">
                                                <td/>
                                                <td colspan="3">

                                                    <table class="table table borderless">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th scope="col" style="max-width: 200px">CORSO</th>
                                                                <th scope="col">DATA</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(dd, indexRow) in summary.details" :key="indexRow" :class="indexRow > 0 ? 'border-top' : ''">
                                                                <td>
                                                                    <div class="accordion-body p-0">{{ dd.title }}</div>
                                                                </td>
                                                                <td>
                                                                    <div class="accordion-body p-0">{{ dd.courseDate }}</div>
                                                                </td>
                                                                <td v-if="dd.certificateId">
                                                                    <button type="button" class="btn btn-primary"
                                                                        @click="() => downloadCertificate(dd.certificateId)"> ATTESTATO </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <td>Non sono presenti al momento corsi effettuati.</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h3>Altri corsi</h3>
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-borderless">
                                    <thead class="table-dark">
                                        <tr>
                                            <th class="table-padding" scope="col">CORSO</th>
                                            <th class="table-padding" scope="col">DATA</th>
                                            <th class="table-padding" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="loading">
                                            <tr>
                                                <td colspan="4">
                                                    <KSpinner :loading="loading"/>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else-if="otherCourseSummary.length > 0" v-for="(summary, index) in otherCourseSummary" :key="index">
                                            <tr>
                                                <td>
                                                    <div class="accordion accordion-left-icon border-0" id="accordionOtherCourseSummary">
                                                        <div class="accordion-item">
                                                            <h2 v-if="summary.details.length > 0" class="accordion-header" :id="`otherCourseSummaryHeading${index}`">
                                                                <button class="accordion-button border-0 text-black" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    :data-bs-target="`#otherCourseSummaryCollapse${index}`" aria-expanded="false"
                                                                    :aria-controls="`otherCourseSummaryCollapse${index}`">
                                                                    {{ summary.name }}
                                                                </button>
                                                            </h2>
                                                            <div style="padding: 14px 24px" v-else>
                                                                {{ summary.name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="table-padding">
                                                        {{toDateString(summary.dataCorso)}}
                                                    </div>
                                                </td>
                                                <td v-if="summary.certificateId">
                                                    <button type="button" class="btn btn-primary"
                                                        @click="() => downloadCertificate(summary.certificateId)"> ATTESTATO </button>
                                                </td>
                                            </tr>
                                            <tr v-if="summary.details.length > 0" :id="`otherCourseSummaryCollapse${index}`"
                                                class="accordion-collapse collapse"
                                                data-bs-parent="#accordionOtherCourseSummary" role="region"
                                                :aria-labelledby="`otherCourseSummaryHeading${index}`">
                                                <td/>
                                                <td colspan="3">

                                                    <table class="table table borderless">
                                                        <thead class="table-light">
                                                            <tr>
                                                                <th scope="col" style="max-width: 200px">CORSO</th>
                                                                <th scope="col">DATA</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(dd, indexRow) in summary.details" :key="indexRow" :class="indexRow > 0 ? 'border-top' : ''">
                                                                <td>
                                                                    <div class="accordion-body p-0">{{ dd.title }}</div>
                                                                </td>
                                                                <td>
                                                                    <div class="accordion-body p-0">{{ dd.courseDate }}</div>
                                                                </td>
                                                                <td v-if="dd.certificateId">
                                                                    <button type="button" class="btn btn-primary"
                                                                        @click="() => downloadCertificate(dd.certificateId)"> ATTESTATO </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr>
                                                <td>Non sono presenti al momento corsi effettuati.</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">

//IMPORTS GENERAL
import { onMounted } from "@vue/runtime-core";
import { computed, ref, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
//import { Tooltip } from "bootstrap-italia";
//IMPORTS MODELS
import { Worker, Student, StudentFinder } from "@/learning/registries/models";
import { learningWrappersService } from "@/services/learningWrappers/LearningWrappersServices";
import { registriesService } from "@/learning/registries/RegistriesService";
import { employeeService } from "../service";
import { cesfService } from "../../../services/cesf/CesfServices";
import { DownloadDocumentRequest } from "../../../services/cesf/Requests";
import { CourseParticipationOutcome, CourseParticipationOutcomeFinder, CourseParticipationOutcomeValidityStatus,
    CourseParticipationOutcomeStatus} from "@/learning/instances/Models";
import { SaveWorkerRequest } from "@/learning/registries/Requests";
import { mapWorkerToSaveRequest } from "@/learning/registries/Mappers";

//IMPORTS KAPPA
import { KIcon, useFilters, usePlatformApi, useKValidation, KValidationRules } from "@k-digitale/kappa";
import { FormValidate } from "bootstrap-italia";

//IMPORTS COMPOSABLES
import { useViewStatus, useValidationHelper } from "@/composables";
import { CourseParticipationOutcomeWrapper, CourseTemplateTagType } from "@/services/learningWrappers/Models";

//COMPOSABLES
const { toDateTimeString, toDateString } = useFilters();
const viewStatusHelper = useViewStatus();
const router = useRouter();
const route = useRoute();
const { isValidFiscalCode } = useValidationHelper();

const { kToast } = usePlatformApi();

// var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
// var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//   return new Tooltip(tooltipTriggerEl)
// });

//REFS
const idWorker = ref<string>(null);
const loading = ref(false);
const employee = ref<Worker>(null);
const workerToSave = ref<SaveWorkerRequest>({} as SaveWorkerRequest);
const courseSummary = ref<any[]>([]);
const otherCourseSummary = ref<any[]>([]);
const workerSexDropdownOptions = ref<any[]>(viewStatusHelper.getWorkerSexDropdownOptions());
const workerQualificationDropDownOptions = ref<any[]>([{
        name: "Nessuna",
        value: null,
}].concat(viewStatusHelper.getWorkerQualificationDropdownOptions()));
const workerStatusDropDownOptions = ref<any[]>([{
    name: "Nessuno",
    value: null,
}].concat(viewStatusHelper.getWorkerStatusDropdownOptions()));

const isRegistryInEditMode = ref(false);
const isRegistryContactsInEditMode = ref(false);

const props = withDefaults(
    defineProps<{
        idEmployee?: string
    }>(),
	{
        idEmployee: null
    }
);

//COMPUTED
const employeeFullName = computed(() => {
    if (employee.value) {
        return employee.value!.name + " " + employee.value!.lastName;
    }
    return "";
});

const participationsNumber = computed(() => {
    return courseSummary.value.length + otherCourseSummary.value.length;
});

//VUE
onMounted(async () => {
    await router.isReady();
    if(route.params.id) {
        idWorker.value = Array.isArray(route.params.id) ? route.params.id[0] : route.params.id;
    }
    else {
        idWorker.value = props.idEmployee;
    }
    await loadData();
});

watch(
    () => props.idEmployee,
    async () => {
        if(props.idEmployee) {
            idWorker.value = props.idEmployee;
            await loadData();
        }
    }
)

watch(isRegistryInEditMode, async (newValue) => {
    if(newValue){
        await nextTick();
        defineValidations();
    }
});

const activeTab = ref(route.params.activeTab);

//METHODS
const loadData = async () => {
    if(!idWorker.value) {
        console.error("worker id missing...");
        return;
    }

    loading.value = true;

    employee.value = await employeeService.getWorker(idWorker.value as string);
    const student = (await registriesService.getStudentsByFinder({workerId: employee.value.id} as StudentFinder )).items[0];
    const outcomeResponse = await learningWrappersService.getCourseParticipationOutcomesWrappersByFinder({
        studentId: student.id,
        pageIndex: -1,
        pageSize: -1,
        sortField: "expiryDate",
        sortOrder: -1,
        status: CourseParticipationOutcomeStatus.Success,
    } as CourseParticipationOutcomeFinder);
    let fathers = outcomeResponse.items.filter(o  => o.fathersCourseTemplates.length == 0 && o.validityStatus == CourseParticipationOutcomeValidityStatus.Active);

    let groupedFathers = fathers.reduce((acc : any, obj : CourseParticipationOutcomeWrapper) => {
        const key = obj.relatedCourseTemplateId;
        if (!acc[key]) {
            acc[key] = [];
        }

        acc[key].push(obj);
        return acc;
    }, {});
    for (var fatherKey of Object.keys(groupedFathers)) {
        const group = groupedFathers[fatherKey];
        const sortedGroup = (group as CourseParticipationOutcomeWrapper[]).sort((a,b) => {
                    return new Date(b.courseDate).getTime() - new Date(a.courseDate).getTime();
                });
        const headFather = sortedGroup[0];
        const remainingFathers = sortedGroup.slice(1, sortedGroup.length);
        let sons = outcomeResponse.items
            .filter(o =>
                o.fathersCourseTemplates.includes(headFather.relatedCourseTemplateId) ||
                (o.relatedCourseTemplateId == headFather.relatedCourseTemplateId
                    && o.validityStatus == CourseParticipationOutcomeValidityStatus.Old
                    && !o.isUpdate))
            .concat(remainingFathers)
            .sort((a,b) => {
                return new Date(b.courseDate).getTime() - new Date(a.courseDate).getTime();
            });
        const details = sons.map(s => {
            return {
                title: s.relatedCourseTemplate.shortTitle? s.relatedCourseTemplate.shortTitle : s.relatedCourseTemplate.title,
                courseDate: toDateString(s.courseDate),
                certificateId: s.certificateCopyId,
            };
        })
        const courseView = {
            name: headFather.relatedCourseTemplate.shortTitle? headFather.relatedCourseTemplate.shortTitle : headFather.relatedCourseTemplate.title,
            details: details,
            dataScadenza: headFather.expiryDate,
            dataCorso: headFather.courseDate,
            certificateId: headFather.certificateCopyId,
            statusCode: viewStatusHelper.getStatusCodeFromDate(headFather.expiryDate),
            statusDescription: viewStatusHelper.getStatusLabelFromDate(headFather.expiryDate),
            partialOutcomes: headFather.partialOutcomes
        };
        if(headFather.relatedTemplateTags && headFather.relatedTemplateTags.some(t => t == CourseTemplateTagType.Main)){
            courseSummary.value.push(courseView);
        } else{
            otherCourseSummary.value.push(courseView);
        }
    }

    let fathersRelatedCourseTemplatesId = fathers.map(f => f.relatedCourseTemplateId);
    let orphans = outcomeResponse.items.filter(o => o.fathersCourseTemplates.length > 0 && !o.fathersCourseTemplates.some(ct => fathersRelatedCourseTemplatesId.includes(ct)));
    let groupedOrphans = orphans.reduce((acc : any, obj : CourseParticipationOutcomeWrapper) => {
                const key = obj.fathersCourseTemplates[0];
                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push(obj);
                return acc;
            }, {});
    for (var key of Object.keys(groupedOrphans)) {
        const group = groupedOrphans[key];
        const sortedGroup = (group as CourseParticipationOutcomeWrapper[]).sort((a,b) => {
                    return new Date(b.courseDate).getTime() - new Date(a.courseDate).getTime();
                });
        const details = sortedGroup.slice(1, sortedGroup.length-1).map(s => {
            return {
                title: s.relatedCourseTemplate.shortTitle? s.relatedCourseTemplate.shortTitle : s.relatedCourseTemplate.title,
                courseDate: toDateString(s.courseDate),
            };
        })
        let main = group[0];
        const courseDate = new Date(main.courseDate);
        const validityMonths = main.relatedCourseTemplate.validityMonths;
        //Pezza, in casi estremi per gli orfani la data di scadenza potrebbe essere diversa dal corso attuale
        main.expiryDate = main.expiryDate ? main.expiryDate : new Date((courseDate.setMonth(courseDate.getMonth()+validityMonths)));
        const courseView = {
            name: main.relatedCourseTemplate.shortTitle? main.relatedCourseTemplate.shortTitle : main.relatedCourseTemplate.title,
            details: details,
            certificateId: main.certificateId,
            dataScadenza: main.expiryDate,
            dataCorso: main.courseDate,
            statusCode: viewStatusHelper.getStatusCodeFromDate(main.expiryDate),
            statusDescription: viewStatusHelper.getStatusLabelFromDate(main.expiryDate),
            isOrphan: true
        };
        courseSummary.value.push(courseView);
    }
    loading.value = false;
};

const kValidation = useKValidation();
const defineValidations = () => {
    kValidation.bindInstanceToValidate(workerToSave);
    kValidation.addField('name', [ KValidationRules.required ]);
    kValidation.addField('lastName', [ KValidationRules.required ]);
    kValidation.addField('birthdate', [ KValidationRules.required ]);
    kValidation.addField('birthplace', [ KValidationRules.required ]);
    kValidation.addField('fiscalCode', [ KValidationRules.required, KValidationRules.codiceFiscale ]);
}

const startEditRegistryData = () => {
    workerToSave.value = mapWorkerToSaveRequest(employee.value);
    isRegistryInEditMode.value = true;
}

const stopEditRegistryData = () => {
    workerToSave.value = {} as SaveWorkerRequest;
    isRegistryInEditMode.value = false;
}

const startEditRegistryContacts = () => {
    workerToSave.value = mapWorkerToSaveRequest(employee.value);
    isRegistryContactsInEditMode.value = true;
}

const stopEditRegistryContacts = () => {
    workerToSave.value = {} as SaveWorkerRequest;
    isRegistryContactsInEditMode.value = false;
}

const saveWorkerRegistryData = async () => {
    if(kValidation.validate()){
        loading.value = true;
        const response = await registriesService.saveWorker(workerToSave.value);
        if(response){
            stopEditRegistryData();
            stopEditRegistryContacts();
            await loadData();
        }
        loading.value = false;
    }
}
const saveWorkerContacts = async () => {
    loading.value = true;
    const response = await registriesService.saveWorker(workerToSave.value);
    if(response){
        stopEditRegistryData();
        stopEditRegistryContacts();
        await loadData();
    }
    loading.value = false;
}

const downloadCertificate = async (certificateId : number) => {
    try {
        loading.value = true;
        const downloadResponse = await cesfService.downloadCertificate({ documentId: certificateId} as DownloadDocumentRequest);
        loading.value = false;
        if(downloadResponse){
            let blob = new Blob([downloadResponse.fileStream], { type: downloadResponse.contentType });
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = downloadResponse.fileName;
            link.click()
            URL.revokeObjectURL(link.href)
        } else{
            kToast.showError("Errore", "E' avvenuto un errore durante il download dell'attestato");
        }
    } catch(err) {
        kToast.showError("Errore", "E' avvenuto un errore durante il download dell'attestato");
    }
}

</script>

<style lang="scss">
.tabview-custom {
    i,
    span {
        vertical-align: middle;
    }

    span {
        margin: 0 0.5rem;
    }
}
.table-padding{
    padding: 14px 24px !important
}
.p-tabview p {
    line-height: 1.5;
    margin: 0;
}
.state-code-label {
    width: 12px;
    height: 12px;
    border-radius: 6px;
}
</style>
