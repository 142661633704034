import { BaseFinder } from "@k-digitale/kappa"

export enum CompanyCcnl {
    Industria = "Industria",
    Artigianato = "Artigianato",
    Cooperative = "Cooperative",
    Altro = "Altro",
}

export interface Company {
    id: string;
    extCode2: string;
    businessName: string;
    extCode1: string;
    partitaIVA: string;
    fiscalCode: string;
    address: string;
    zipCode: string;
    city: string;
    provincia: string;
    country: string;
    phone: string;
    cellPhone: string;
    fax: string;
    email: string;
    pec: string;
    consultantName: string;
    consultantLastName: string;
    consultantEmail: string;
    consultantFax: string;
    consultantPhone: string;
    isDataToBeVerified: boolean | null;
    ceVerificationRequestDate: Date | null;
    inCEVerification: boolean;
    ceVerificationProtocol: string;
    ceLastVerificationResultDate: Date | null;
    ceIsLastVerificationSucceded: boolean | null;
    ceLastVerificationError: string;
    userId: number | null;
    ccnl:CompanyCcnl | null;
    description: string;
}

export interface CompanyFinder extends BaseFinder {
    businessName: string;
    globalSearchTerm: string;
    onlyToBeVerified: boolean;
}

//WORKERS
export enum WorkerQualification {
    Apprendista = "Apprendista",
    Operaio = "Operaio",
    OperaioConQualifica = "OperaioConQualifica",
    ImpiegatoAmministrativo = "ImpiegatoAmministrativo",
    ImpiegatoTecnico = "ImpiegatoTecnico",
}

export enum WorkerStatus {
    Occupato = "Occupato",
    Autonomo = "Autonomo",
    Professionista = "Professionista",
    Disoccupato = "Disoccupato",
    Inoccupato = "Inoccupato",
    Studente = "Studente",
    Imprenditore = "Imprenditore",
    SocioLavoratore = "SocioLavoratore",
}

export interface Worker {
    id: string;
    companyId: string | null;
    name: string;
    lastName: string;
    birthdate: Date | null;
    birthplace: string;
    fiscalCode: string;
    citizenship: string;
    country: string;
    address: string;
    zipCode: string;
    city: string;
    provincia: string;
    telephone: string;
    cellPhone: string;
    email: string;
    sex: string;
    toBeHired: boolean;
    hiringDate: Date | null;
    privacyInfo: boolean;
    privacyPhoto: boolean;
    isUnemployed: boolean;
    company:Company;
    userId: number | null;
    qualification: WorkerQualification | null;
    status: WorkerStatus | null;
}

export interface WorkerFinder extends BaseFinder {
    name: string | undefined;
    lastName: string | undefined;
    fiscalCode: string | undefined;
    companyId: string | undefined;
    globalSearchTearm: string | undefined;
}

//STUDENTS

export interface Student {
    id: string;
    workerId: string;
    worker: Worker;
    italianCourseDone: boolean;
    hasReceived16Hours: boolean;
    notSubscribable: boolean;
    toBeVerified: boolean;
}

export interface StudentFinder extends WorkerFinder {
    workerId: string | null;
    onlyToBeVerified: boolean;
}

//TEACHERS
export enum EngagementLetterTypes {
    Societa = "Societa",
    Occasionale = "Occasionale",
    Professionale = "Professionale",
}

export interface Teacher {
    id: string;
    workerId: string;
    worker: Worker;
    hourlyCost: number;
    contractType: string;
    supplierCode: string;
    publicAdministration: boolean;
}

export interface TeacherFinder extends WorkerFinder {
    contractType: string;
    workerId: string | null;
    isTeacherFilterEmpty: boolean;
}

//TUTORS

export interface Tutor {
    id: string;
    workerId: string;
    idSignature: number | null;
    worker: Worker;
}

export interface TutorFinder extends WorkerFinder {
    workerId: string | null;
}

//COORDINATORS
export interface Coordinator {
    id: string;
    idSignature: number | null;
    workerId: string;
    worker: Worker;
}

export interface CoordinatorFinder extends WorkerFinder {
    workerId: string | null;
}