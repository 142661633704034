<template>
    <div class="row my-4">
        <KModalSlide @closed="emit('update:modelValue', false)" v-model="isModalOpen">
            <div style="width: 680px">
                <h3 style="text-align: center">Contatta un consulente CESF</h3>

                <KTabs>
                    <KTab name="Prenota appuntamento">
                        <div class="card shadow h-100 pb-4"><div id="kd-widget"></div></div>
                    </KTab>
                    <KTab name="Scrivi email">
                        <form
                            id="formSendEmail"
                            class="needs-validation"
                            autocomplete="off"
                        >
                            <div class="row">
                                <div class="cols-12 col-md-1 col-lg-12">
                                    <KInputText id="email-subject" v-model="sendEmailRequest.subject" labelText="Oggetto *"></KInputText>
                                </div>
                            </div>
                            <div class="row">
                                <div class="cols-12 col-md-12 col-lg-12">
                                    <KInputTextArea textClass="sendmail-body-textarea" id="email-body" v-model="sendEmailRequest.body" labelText="Il tuo messaggio *"></KInputTextArea>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="d-flex justify-content-center col-12">
                                    <button type="submit" @click="sendEmailToCesf" class="btn btn-primary mt-2">INVIA</button>
                                </div>
                            </div>
                        </form>
                    </KTab>
                    <KTab name="Invio messaggio">
                        <form
                            id="formSendMessage"
                            class="needs-validation"
                            autocomplete="off"
                        >
                            <div class="row">
                                <div class="cols-12 col-md-12 col-lg-12">
                                    <KInputTextArea textClass="sendmessage-body-textarea" id="message-body" v-model="sendMessageRequest.message" labelText="Il tuo messaggio *"></KInputTextArea>
                                </div>
                            </div>
                            <div class="row align-items-center">
                                <div class="d-flex justify-content-center col-12">
                                    <button type="submit" @click="sendMessageToCesf" class="btn btn-primary mt-2">INVIA</button>
                                </div>
                            </div>
                        </form>
                    </KTab>
                </KTabs>
            </div>
        </KModalSlide>
    </div>
</template>

<script lang="ts" setup>
import { SendEmailToCesfRequest, SendMessageToCesfRequest } from '@/services/cesf/Requests';
import { cesfService } from '@/services/cesf/CesfServices';
import { KInputTextArea, KModalSlide } from '@k-digitale/kappa';
import { FormValidate } from "bootstrap-italia";
import { onMounted, ref, watch, nextTick } from 'vue';
import { useCesfUser } from '@/composables';


//VARS
let validateEmailForm : any;
let validateMessageForm : any;

//REFS
const sendEmailRequest = ref<SendEmailToCesfRequest>({} as SendEmailToCesfRequest);
const sendMessageRequest = ref<SendMessageToCesfRequest>({} as SendMessageToCesfRequest);

//VUE
const props = defineProps<{
    modelValue: boolean,
  }>();

const isModalOpen = ref(false);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

watch(() => props.modelValue, (newValue) => {
    isModalOpen.value = newValue;
    // if(isModalOpen.value){
    //     mountWidget();
    // }
})

onMounted(() => {
    nextTick(() => mountWidget());
})

const { cesfUser, company } = useCesfUser();
const mountWidget = () => {
    const cfg = {
            language: "it",
            companyAndRoleVisible: true,
            notesVisible: false,
            chkPrivacyVisible: true,
            chkDataConsentVisible: false,
            chkMarketingVisible: false,
            twoStepsVersion: true,
            privacyPolicyLink: 'https://cesf.dp365.it/informativa-prenota-un-appuntamento',
            marketingPolicyLink: '',
            defaultCustomerDataCallback: function() { return { firstName: cesfUser.value.user.firstName, lastName: cesfUser.value.user.lastName, eMail: cesfUser.value.user.emailAddress, phoneNumber: company.value.phone, company: company.value.businessName}}
            //chkPrivacyCallback: function (linkClcked) { return true; }
        };
        // Inizializzazione widget con configurazione per lingua, check visibili, link...
    (window as any).initWEOnDemand("#kd-widget", cfg);
    createEmailFormValidation();
    createMessageFormValidation();
}

//METHODS
const createEmailFormValidation = () => {
    validateEmailForm = new FormValidate("#formSendEmail" as any, {
        errorFieldCssClass: "is-invalid",
        errorLabelCssClass: "form-feedback",
        errorLabelStyle: "",
        focusInvalidField: true,
    });

    validateEmailForm.addField("#email-subject", [
        {
            rule: "required",
            errorMessage: "L'oggetto della mail deve essere specificato",
        },
    ]);
    validateEmailForm.addField("#email-body",
        [
            {
                rule: "required",
                errorMessage: "Il messaggio della mail deve essere specificato",
            },
        ],
        {
            successFieldCssClass: 'textarea-validation-success',
        } );
}

const createMessageFormValidation = () => {
    validateMessageForm = new FormValidate("#formSendMessage" as any, {
        errorFieldCssClass: "is-invalid",
        errorLabelCssClass: "form-feedback",
        errorLabelStyle: "",
        focusInvalidField: true,
    });

    validateMessageForm.addField("#message-body",
        [
            {
                rule: "required",
                errorMessage: "Il contenuto del messaggio deve essere specificato",
            },
        ],
        {
            successFieldCssClass: 'textarea-validation-success',
        } );
}

const sendEmailToCesf = async () => {
    await validateEmailForm.revalidate();
    if(validateEmailForm.isValid){
        const result = await cesfService.sendEmailToCesf(sendEmailRequest.value);
        if(result){
            isModalOpen.value = false;
        }
    }
}
const sendMessageToCesf = async () => {
    await validateMessageForm.revalidate();
    if(validateMessageForm.isValid){
        const result = await cesfService.sendMessageToCesf(sendMessageRequest.value);
        if(result){
            isModalOpen.value = false;
        }
    }
}

</script>

<style>
.sendmail-body-textarea{
    height: 253px;
}
.sendmessage-body-textarea{
    height: 323px;
}

.textarea-validation-success{
    border-color: #008758 !important;
}
</style>