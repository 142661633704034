import { ApiPagedResult, ApiResponse, BaseService } from "@k-digitale/kappa";
import { ServiziAziendaDto } from "./models";

class ModuleServiceService extends BaseService {

    async getCompanyServices(companyId: string): Promise<ServiziAziendaDto> {
        const response = await this.tryExecute(
            this.api.get<ServiziAziendaDto>(`cesf/ext/companies/${companyId}/services`)
        );

        return response?.data;
    }

    async requestService(companyId: string, idServizio: number): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<ApiResponse<boolean>>(`cesf/ext/companies/${companyId}/request-service/${idServizio}`)
        );

        return response?.data?.success ? response.data.data == true : false;
    }

    async downloadDocumentoServizioAzienda(idAzienda: string, idDocumento : number) {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/ext/companies/${idAzienda}/doc-services/${idDocumento}`, {
                responseType: 'blob'
            })
        );

        let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '');
        let link = document.createElement('a');
        link.href = URL.createObjectURL(response.data);
        link.download = `${filename}`;
        link.target = "_new";
        link.click();
    }
}

export const serviceService = new ModuleServiceService();