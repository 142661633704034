import { RouteRecordRaw } from 'vue-router';

const optionalModules : { [index: string]: RouteRecordRaw[] } = {
};

import HomePage from './pages/Home.vue';

optionalModules.mod_home = [
    {
        path: '/',
        component: HomePage,
        meta: {
            requiresAuth: true,
            menu: {
                order: 0,
                label: 'Home',
                //icon: 'Home'
                iconAsset: "icons/ico-bacheca.svg"
            }
        }
    }
];


import profileRoutes from './modules/profile/routes';
// optionalModules.mod_profile = profileRoutes;

import employeesRoutes from './modules/employee/routes-azi';
import employeeRoutes from './modules/employee/routes-usr';
//optionalModules.mod_employee = employeeRoutes;


//TODO Riaggiungere
// import certificationRoutes from './modules/certification/routes';
// optionalModules.mod_certification = certificationRoutes;

import buildingSiteRoutes from './modules/buildingsite/routes';
//optionalModules.mod_buildingsite = buildingSiteRoutes;

import courseRoutes from './modules/course/routes';
//optionalModules.mod_course = courseRoutes;

//TODO Aggiungere
import serviceRoutes from './modules/service/routes';
optionalModules.mod_service = serviceRoutes;

import communicationRoutes from './modules/communication/routes';
optionalModules.mod_communication = communicationRoutes;

import SelComp from './pages/SelComp.vue';
import { useCesfUser } from './composables';

const resolveCustomModule = (moduleKey: string) : RouteRecordRaw[]  =>
{
    switch(moduleKey?.toLowerCase()) {
        case "scheda-azi": return profileRoutes;
        case "dipendenti": return employeesRoutes;
        case "cantieri": return buildingSiteRoutes;
        case "corsi": return courseRoutes;
        case "servizi": return serviceRoutes;
        case "scheda-usr": return employeeRoutes;
        case "selcomp": {
            return [{
                name: 'sel-comp',
                path: '/selcomp',
                component: SelComp,
                meta: {
                    requiresAuth: true,
                    menu: {
                        label: 'CAMBIO AZIENDA',
                        icon: 'pi pi-cog'
                    }
                }
            }];
        }
    }

    return null;
}

export { optionalModules, resolveCustomModule };