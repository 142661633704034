import { ApiPagedResult, BaseService } from "@k-digitale/kappa";
import { Company, CompanyFinder } from "@/learning/registries/Models";
import { CourseTemplate } from "@/learning/Templates/Models";
import { RLSWorkerData, ResponsabileSicurezzaLavoratori } from "../cesf/Models";

class LearningExtensionsServices extends BaseService {

    //COMPANIES
    async getCompanyRls(companyId : string): Promise<ResponsabileSicurezzaLavoratori> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/ext/companies/${companyId}/rls`)
        );

        return response?.data;
    }

    async getCompaniesByFinder(finder: CompanyFinder): Promise<ApiPagedResult<Company>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/companies", { params: finder })
        );

        return response!.data;
    }

    async getRelatedUpdateCourse(baseId: string): Promise<CourseTemplate> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/related-course-update/${baseId}`)
        );

        return response?.data;
    }
    async getRelatedBaseCourse(baseId: string): Promise<CourseTemplate> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/related-course-base/${baseId}`)
        );

        return response?.data;
    }

}

export const learningExtensionService = new LearningExtensionsServices();
