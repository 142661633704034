import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import addUiFramework from './ui-framework';
import { optionalModules, resolveCustomModule } from './router';
import { createKappaApp, KappaAppOptions } from '@k-digitale/kappa';

import { useCesfUser } from './composables';

import  VueGoogleMaps from '@fawmi/vue-google-maps'

import 'primevue/resources/themes/fluent-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import './assets/fonts/fabric-icons.css';
import './assets/layout.scss';

// TODO: estensione aggiunta a livello di framework... verificare il corretto funzionamento --> Togliendolo non funziona :(
dayjs.extend(utc);

const options : KappaAppOptions = {
    appName: import.meta.env.VITE_APP_NAME,
    appType: "extranet",
    appApiBaseUrl: import.meta.env.VITE_API_BASE_URL,
    optionalModules: optionalModules,
    routeBasePath: import.meta.env.VITE_ROUTE_BASE_PATH,
    globalSearchPlugins: [ ],
    customNotificationPlugins: [],
    customModuleResolver: resolveCustomModule,
    routerBeforeNavigateGuard: (to: string, from: string) => {
        const { cesfUser } = useCesfUser();
        if(cesfUser.value) {
            const compIdPresent = cesfUser.value && cesfUser.value.companyId && cesfUser.value.companyId.length > 0;
            let nextPath = '/selcomp';
            if(!compIdPresent && cesfUser.value.isExtranetSuperuser == true && to != nextPath) {
                return nextPath;
            }
        }
        // se path diverso non restituito, framework continua con destinazione corrente...
        return null;
    }
};

createKappaApp(options)
    .then((app: any) => {
        addUiFramework(app);
        app.use(VueGoogleMaps, { load: { key: import.meta.env.VITE_GMAPS_API_KEY } });
        app.mount('#app');
    })
    .catch(() => {
        console.error("AIUTO!!!")
    }
);