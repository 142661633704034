import { WorkerQualification, WorkerStatus } from "@/learning/registries/Models";
import dayjs from "dayjs";
import { usePrimeVue } from 'primevue/config';

export function useViewStatus() {
    const getStatusCodeFromDate = (date : Date, monthsThreshold = 2) : string => {
        if(dayjs() > dayjs(date)){
            return "danger";
        }
        if(dayjs(date).diff(dayjs(), 'month') >= 0 && dayjs(date).diff(dayjs(), 'month') < monthsThreshold){
            return "warning";
        }
        return "success";
    }
    const getStatusLabelFromDate = (date : Date, monthsThreshold = 2) : string => {
        if(dayjs() > dayjs(date)){
            return "Scaduto";
        }
        if(dayjs(date).diff(dayjs(), 'month') >= 0 && dayjs(date).diff(dayjs(), 'month') < monthsThreshold){
            return "In Scadenza";
        }
        return "Valido";
    }

    const isExpiredOrInExpiring = (date : Date, monthsThreshold = 2) : boolean => {
        if(dayjs() > dayjs(date)){
            return true;
        }
        if(dayjs(date).diff(dayjs(), 'month') >= 0 && dayjs(date).diff(dayjs(), 'month') < monthsThreshold){
            return true;
        }
        return false;
    }

    const formatWorkerQualification = (qualification: WorkerQualification) => {
        switch(qualification){
            case WorkerQualification.Apprendista:
                return "Apprendista";
            case WorkerQualification.ImpiegatoAmministrativo:
                return "Impiegato amministrativo";
            case WorkerQualification.ImpiegatoTecnico:
                return "Impiegato tecnico";
            case WorkerQualification.Operaio:
                return "Operaio";
            case WorkerQualification.OperaioConQualifica:
                return "Operaio con qualifica";
            default:
                return "Nessuna";
        }
    }

    const getWorkerQualificationDropdownOptions = ()=> {
        return [
            {
                name: "Operaio",
                value: WorkerQualification.Operaio
            },
            {
                name: "Impiegato Tecnico",
                value: WorkerQualification.ImpiegatoTecnico
            },
            {
                name: "Apprendista",
                value: WorkerQualification.Apprendista
            },
            {
                name: "Impiegato amministrativo",
                value: WorkerQualification.ImpiegatoAmministrativo
            },
            {
                name: "Operaio con qualifica",
                value: WorkerQualification.OperaioConQualifica
            },
        ]
    };

    const formatWorkerStatus = (status: WorkerStatus) => {
        switch(status){
            case WorkerStatus.Autonomo:
                return "Autonomo";
            case WorkerStatus.Disoccupato:
                return "Disoccupato";
            case WorkerStatus.Imprenditore:
                return "Imprenditore";
            case WorkerStatus.Inoccupato:
                return "Inoccupato";
            case WorkerStatus.Occupato:
                return "Occupato";
            case WorkerStatus.Professionista:
                return "Professionista";
            case WorkerStatus.SocioLavoratore:
                return "Socio lavoratore";
            case WorkerStatus.Studente:
                return "Studente";
            default:
                return "Nessuno";
        }
    }

    const getWorkerStatusDropdownOptions = ()=> {
        return [
            {
                name: "Autonomo",
                value: WorkerStatus.Autonomo
            },
            {
                name: "Disoccupato",
                value: WorkerStatus.Disoccupato
            },
            {
                name: "Imprenditore",
                value: WorkerStatus.Imprenditore
            },
            {
                name: "Inoccupato",
                value: WorkerStatus.Inoccupato
            },
            {
                name: "Occupato",
                value: WorkerStatus.Occupato
            },
            {
                name: "Professionista",
                value: WorkerStatus.Professionista
            },
            {
                name: "Socio lavoratore",
                value: WorkerStatus.SocioLavoratore
            },
            {
                name: "Studente",
                value: WorkerStatus.Studente
            },
        ]
    };
    const getWorkerSexDropdownOptions = ()=> {
        return [
            {
                name: "M",
                value: "M"
            },
            {
                name: "F",
                value: "F"
            },
        ]
    };

    return {
        getStatusCodeFromDate,
        getStatusLabelFromDate,
        isExpiredOrInExpiring,
        formatWorkerQualification,
        getWorkerQualificationDropdownOptions,
        formatWorkerStatus,
        getWorkerStatusDropdownOptions,
        getWorkerSexDropdownOptions
    }
}