<template>
    <div class="row my-4">
        <ul class="list-group">
            <li class="list-group-item p-2">
                <div class="form-group m-0">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text border-none">
                                <KIcon icon="it-search" />
                            </div>
                        </div>

                        <input type="text" class="form-control border-none" placeholder="Cerca dipendente" @input="emit('on-search-employees', $event)" />
                    </div>
                </div>
            </li>
            <li class="list-group-item px-3 py-0" v-for="(dip, index) in dipendentiList" :key="index">
                <div :class="{'row': true,  'align-items-center':true,  'kd-already-sub':isAlreadySubscribed(dip)}">
                    <div class="col-12 col-xs-12 col-md-4 col-lg-3 list-row-dipendente-name">{{ dip.lastName }} {{ dip.name }}</div>
                    <div class="col-12 col-xs-12 col-md-4 col-lg-3 list-row-dipendente-cf">{{ dip.fiscalCode }}</div>
                    <div class="col-12 col-xs-12 col-md-4 col-lg-2 list-row-dipendente-cf">
                        <div class="course-state-code inline-block mr-4" :class="`bg-${dip.stateCode}`"></div>
                        <div class="accordion-body p-0 inline-block">{{ dip.stateDescription }}</div>
                    </div>
                    <div class="col-12 col-xs-12 col-md-4 col-lg-2 list-row-dipendente-cf">
                        {{ dip.expireDate }}
                    </div>
                    <div class="col-12 col-xs-12 col-md-4 col-lg-2 text-md-end">
                        <div class="d-flex align-items-center justify-content-end" v-if="dip.notSubscribable">
                            <KIcon icon="it-error" class="icon-danger"/>
                            <span disabled class="text-danger btn-add-dipendente ml-1">NON ISCRIVIBILE</span>
                        </div>
                        <button v-else-if="dip.courseBaseDone && dip.stateCode != 'danger'" disabled type="button" class="btn btn-add-dipendente k-btn-flat">CORSO BASE GIA' EFFETTUATO</button>
                        <button v-else-if="dip.toBeVerified" disabled type="button" class="btn btn-add-dipendente k-btn-flat">IN ATTESA DI VERIFICA</button>
                        <button v-else-if="dip.courseBaseDone && dip.stateCode == 'danger'" @click="goToCourseDetail(relatedCourseUpdate.id)" type="button" class="btn btn-add-dipendente k-btn-flat">AGGIORNA</button>
                        <button v-else-if="!isSubscription && isAlreadySubscribed(dip)" disabled type="button" class="btn btn-add-dipendente k-btn-flat" @click="onAddEmployeeTosubscribe(dip, index)">PRE-ISCRIZIONE GIA' RICHIESTA</button>
                        <button v-else-if="isSubscription && isAlreadySubscribed(dip)" @click="onAddEmployeeToCancel(dip, index)" type="button" class="btn btn-add-dipendente k-btn-flat">ANNULLA ISCRIZIONE</button>
                        <button v-else-if="isSubscription && availableSpots <= 0" disabled type="button" class="btn btn-add-dipendente k-btn-flat">DISPONIBILITA' ESAURITA</button>
                        <button v-else type="button" class="btn btn-add-dipendente k-btn-flat" @click="onAddEmployeeTosubscribe(dip, index)">
                            <span class="text-uppercase fw-bold ml-2 text-primary">AGGIUNGI</span>
                        </button>
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <button @click="isNewEmployeePanelVisible = true" class="btn btn-icon btn-me k-btn-flat">
                    <KIcon icon="it-plus" class="icon-primary"></KIcon>
                    <span class="text-uppercase fw-bold ml-2 text-primary">NUOVO DIPENDENTE</span>
                </button>
            </li>
        </ul>
        <NuovoDipendente @new-worker-saved="loadData" v-model="isNewEmployeePanelVisible"/>
        <div v-if="dipendentiAggiuntiItems.length > 0" class="row">
            <div class="col-12">
                <h5>Lavoratori da iscrivere</h5>
                <DataTable :value="dipendentiAggiuntiItems" dataKey="id" responsiveLayout="scroll">
                    <Column field="lastName" header="COGNOME"></Column>
                    <Column field="name" header="NOME"></Column>
                    <Column field="fiscalCode" header="CODICE FISCALE"></Column>
                    <Column field="expireDate" header="SCADENZA" />
                    <Column>
                        <template #body="slotProps">
                            <button v-if="slotProps.data.courseBaseNotDone" disabled type="button" class="btn btn-add-dipendente">CORSO BASE NON EFFETTUATO</button>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <button type="button" class="btn btn-add-dipendente" @click="onRemoveEmployeeToSubscribe(slotProps.data)">
                                <span class="text-uppercase fw-bold ml-2 text-primary k-btn-flat">RIMUOVI</span>
                            </button>
                        </template>
                    </Column>
                    <template #empty> Nessun dipendente aggiunto </template>
                    <template #footer>
                        <div class="text-end">Totale: {{ dipendentiAggiuntiItems.length }} iscritti</div>
                    </template>
                </DataTable>
            </div>
        </div>
        <div v-if="dipendentiRimossiItems.length > 0" class="row">
            <div class="col-12">
                <h5>Iscrizioni da annullare</h5>
                <DataTable :value="dipendentiRimossiItems" dataKey="id" responsiveLayout="scroll">
                    <Column field="lastName" header="COGNOME"></Column>
                    <Column field="name" header="NOME"></Column>
                    <Column field="fiscalCode" header="CODICE FISCALE"></Column>
                    <Column field="expireDate" header="SCADENZA" />
                    <Column>
                        <template #body="slotProps">
                            <button v-if="slotProps.data.courseBaseNotDone" disabled type="button" class="btn btn-add-dipendente">CORSO BASE NON EFFETTUATO</button>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <button type="button" class="btn btn-add-dipendente" @click="onRemoveEmployeeToCancel(slotProps.data)">
                                <span class="text-uppercase fw-bold ml-2 text-primary">RIMUOVI</span>
                            </button>
                        </template>
                    </Column>
                    <template #empty> Nessun dipendente rimosso </template>
                    <template #footer>
                        <div class="text-end">Totale: {{ dipendentiRimossiItems.length }} in rimozione</div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

import { Worker } from "@/learning/registries/Models";
import {ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import {learningExtensionService} from "../../../../services/learningExtensions/LearningExtensionsServices";
import {learningWrappersService} from "../../../../services/learningWrappers/LearningWrappersServices";
import { useFilters } from "@k-digitale/kappa";
import { CourseTemplate } from '@/learning/Templates/Models';
import { CourseParticipationOutcomeWrapper } from '@/services/learningWrappers/Models';
import { useCesfUser, useViewStatus } from "@/composables";
import { CourseParticipationOutcomeFinder, CourseParticipationOutcomeStatus, CourseParticipationOutcomeValidityStatus } from '@/learning/instances/Models';
import NuovoDipendente from "@/components/NuovoDipendente.vue";

const router = useRouter();
const {cesfUser} = useCesfUser();
const viewStatusHelper = useViewStatus();
const outcomes = ref<CourseParticipationOutcomeWrapper[]>([]);

const dipendentiList = ref<any[]>();
const dipendentiAggiuntiItems = ref<any[]>([]);

const props = withDefaults(
  defineProps<{
    isSubscription?: boolean
    courseTemplateId: string,
    workersList: any[],
    subscribedWorkersId: string[],
    modelValue: any[],
    canceledSubscriptions?: any[],
    availableSpots?: number
  }>(), {
    isSubscription: false,
    availableSpots: 0,
  }
);

const loadCourseOutcomes = async () => {
    let finder = {
        queryRegistriesRelations: true,
        pageIndex: -1,
        pageSize: -1,
        status: CourseParticipationOutcomeStatus.Success,
        validityStatus: CourseParticipationOutcomeValidityStatus.Active,
        sortOrder: -1
    } as CourseParticipationOutcomeFinder;

    if(relatedCourseBase.value){
        finder.relatedCourseTemplateId = relatedCourseBase.value.id;
    } else {
        finder.relatedCourseTemplateId = props.courseTemplateId;
    }

    const outcomesResponse = await learningWrappersService.getCourseParticipationOutcomesWrappersForActualCompanyStudents(
        cesfUser.value.companyId,
        finder);

    outcomes.value = outcomesResponse.items;
}

const { toDateString } = useFilters();

const relatedCourseUpdate = ref<CourseTemplate>();
const relatedCourseBase = ref<CourseTemplate>();
watch(() => props.courseTemplateId, async (newValue) => {
    if(newValue){

        relatedCourseUpdate.value = await learningExtensionService.getRelatedUpdateCourse(newValue);
        relatedCourseBase.value = await learningExtensionService.getRelatedBaseCourse(newValue);
        await loadCourseOutcomes();
    }
}, {immediate: true});

watch(outcomes, (newValue) => {
    if(newValue){
        dipendentiList.value = props.workersList.map(d => {
            const relatedOutcome = outcomes.value.find(o => o.courseParticipation.student.workerId == d.id)

            return{
                courseBaseDone: relatedOutcome && !relatedCourseBase.value,
                courseBaseNotDone: !relatedOutcome && relatedCourseBase.value,
                expireDate: relatedOutcome ?  toDateString(relatedOutcome.expiryDate) : '-',
                stateCode: relatedOutcome ? viewStatusHelper.getStatusCodeFromDate(relatedOutcome.expiryDate) : '-',
                stateDescription: relatedOutcome ? viewStatusHelper.getStatusLabelFromDate(relatedOutcome.expiryDate) : '-',
                ...d
            }
        });
    }
},{immediate: true})

watch(() => props.workersList, (newValue) => {
    if(newValue){
        dipendentiList.value = props.workersList.map(d => {
            const relatedOutcome = outcomes.value.find(o => o.courseParticipation.student.workerId == d.id)

            return{
                courseBaseDone: relatedOutcome && !relatedCourseBase.value,
                courseBaseNotDone: !relatedOutcome && relatedCourseBase.value,
                expireDate: relatedOutcome ?  toDateString(relatedOutcome.expiryDate) : '-',
                stateCode: relatedOutcome ? viewStatusHelper.getStatusCodeFromDate(relatedOutcome.expiryDate) : '-',
                stateDescription: relatedOutcome ? viewStatusHelper.getStatusLabelFromDate(relatedOutcome.expiryDate) : '-',
                ...d
            }
        });
    }
},{immediate: true})

const onAddEmployeeTosubscribe = function (dip: Worker, index: number) {
    dipendentiAggiuntiItems.value.push(dip);
    dipendentiList.value.splice(index, 1);
    emit('update:modelValue', dipendentiAggiuntiItems.value);
};

const isAlreadySubscribed = (worker: Worker) : boolean => {
    return props.subscribedWorkersId.includes(worker.id);
}
const isNewEmployeePanelVisible = ref<boolean>(false);
const goToCourseDetail = (id: string) => {
    router.push({ name: 'course-detail', params: { id: id } });
};
const onRemoveEmployeeToSubscribe = function (dip: Worker) {
    dipendentiList.value.push(dip);
    dipendentiAggiuntiItems.value.splice(dipendentiAggiuntiItems.value.indexOf(dipendentiAggiuntiItems.value.find(d => d.id == dip.id)), 1);
    emit('update:modelValue', dipendentiAggiuntiItems.value);
};

const loadData = async () => {
    await loadCourseOutcomes();
    emit('on-new-worker-saved');
}
const emit = defineEmits<{
    (e: 'on-search-employees', value: any): void,
    (e: 'on-new-worker-saved'): void,
    (e: 'update:modelValue', value: any[]): void,
    (e: 'update:canceledSubscriptions', value: any[]): void,
}>()

//Gestione annullamento iscrizione
const dipendentiRimossiItems = ref<any[]>([]);

const onAddEmployeeToCancel = function (dip: Worker, index: number) {
    dipendentiRimossiItems.value.push(dip);
    dipendentiList.value.splice(index, 1);
    emit('update:canceledSubscriptions', dipendentiRimossiItems.value);
};

const onRemoveEmployeeToCancel = function (dip: Worker) {
    dipendentiList.value.push(dip);
    dipendentiRimossiItems.value.splice(dipendentiRimossiItems.value.indexOf(dipendentiRimossiItems.value.find(d => d.id == dip.id)), 1);
    emit('update:canceledSubscriptions', dipendentiRimossiItems.value);
};

</script>

<style scoped lang="scss">
.btn-add-dipendente {
    opacity: 1;
    color: rgba(0, 102, 204, 1);
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: right;
    text-transform: uppercase;
}
.list-row-dipendente-cf {
    opacity: 1;
    color: rgba(117, 117, 117, 1);
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.list-row-dipendente-name {
    opacity: 1;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.btn-aggiungi-dipendente {
    opacity: 1;
    color: rgba(0, 102, 204, 1);
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: right;
    text-transform: uppercase;

    .icon {
        fill: rgba(0, 102, 204, 1);
        font-size: 16px;
    }
}
</style>