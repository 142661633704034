<template>
    <EmployeeDetail :idEmployee="cesfUser?.workerId" />
</template>

<script setup lang="ts">import { useCesfUser } from '@/composables';
import EmployeeDetail from './EmployeeDetail.vue';


const { cesfUser } = useCesfUser();

</script>