import { ApiPagedResult, ApiResponse, BaseService } from "@k-digitale/kappa";
import { CourseTemplate, CourseTemplateFinder, Fullfillment, FullfillmentFinder, Scope, ScopeFinder, Uc, UcFinder, Ud, UdFinder, CourseTemplateWithInstancesResponse } from "./Models";
import { AddFullfillmentAssociationRequest, CopyCourseTemplateRequest, DeleteCourseTemplateRequest, DeleteFullfillmentRequest, DeleteLessonTemplateRequest, DeleteScopeRequest, DeleteUcRequest, DeleteUdRequest, RemoveFullfillmentAssociationRequest, SaveCourseTemplateRelationshipRequest, SaveCourseTemplateRequest, SaveFullfillmentRequest, SaveLessonTemplateRequest, SaveScopeRequest, SaveUcRequest, SaveUdRequest, SetCourseTemplateCompletedRequest } from "./Requests";

class TemplatesService extends BaseService {

    //COURSES
    async getCourseTemplatesByFinder(finder: CourseTemplateFinder): Promise<ApiPagedResult<CourseTemplate>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/templates/courses/", { params: finder })
        );

        return response!.data;
    }

    async getCourseTemplate(id: string): Promise<CourseTemplate> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/templates/course/${id}`)
        );

        return response!.data;
    }

    async getCourseTemplateByProductId(productId: number): Promise<CourseTemplateWithInstancesResponse> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/templates/course-with-instances-by-product/${productId}`)
        );

        return response?.data;
    }

    async copyCourseTemplate(request: CopyCourseTemplateRequest): Promise<string | null> {

        const response = await this.tryExecute(
            this.api.post<any>("learning/templates/copy-course", request)
        );

        //return response!.data;
        return response?.data;
    }

    //COURSE TEMPLATE RELATIONSHIPS

    async getIncludedCourseTemplates(rootId: string): Promise<CourseTemplate[]> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/templates/included-course-templates/${rootId}`)
        );

        return response!.data;
    }
    async getRequiredCourseTemplates(rootId: string): Promise<CourseTemplate[]> {
        const response = await this.tryExecute(
            this.api.get<any>(`learning/templates/required-course-templates/${rootId}`)
        );

        return response!.data;
    }

    //LESSONS

    async getLessonTemplatesByFinder(finder: CourseTemplateFinder): Promise<ApiPagedResult<CourseTemplate>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/templates/lessons/", { params: finder })
        );

        return response!.data;
    }
    async getFullfillmentsByFinder(finder: FullfillmentFinder): Promise<ApiPagedResult<Fullfillment>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/templates/fullfillments/", { params: finder })
        );

        return response!.data;
    }

    //UDS
    async getUdsByFinder(finder: UdFinder): Promise<ApiPagedResult<Ud>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/templates/uds/", { params: finder })
        );

        return response!.data;
    }

    //UCS
    async getUcsByFinder(finder: UcFinder): Promise<ApiPagedResult<Uc>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/templates/ucs/", { params: finder })
        );

        return response!.data;
    }


    //SCOPES
    async getScopesByFinder(finder: ScopeFinder): Promise<ApiPagedResult<Scope>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/templates/scopes/", { params: finder })
        );

        return response!.data;
    }

}

export const templatesService = new TemplatesService();
