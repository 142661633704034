import { computed, ref, watch } from 'vue';
import { useUserStore } from '@k-digitale/kappa'
import { CesfUserInfoResponse } from '@/models/Account/models';
import { Company } from '@/learning/registries/Models';
import { profileService } from '@/modules/profile/service';

const company = ref<Company>(null)
export function useCesfUser() {
    const cesfUser = ref<CesfUserInfoResponse>(null);
    const { user } = useUserStore();

    watch(() => user, async () => {
        cesfUser.value = user as any;
        if((cesfUser.value?.user?.id || 0 ) > 0 && cesfUser.value?.companyId != null){
            if(company.value?.id != cesfUser.value.companyId){
                company.value = await profileService.getCompany(cesfUser.value.companyId);
            }
        } else{
            company.value = null;
        }
    }, {immediate: true});

    const isUserCompany = computed(() => {
        return !!cesfUser.value?.companyId;
    })

    const isUserWorker = computed(() => {
        return !!cesfUser.value?.workerId;
    })

    const switchCompany = async (idCompany: string) => {
        if(cesfUser.value && cesfUser.value.isExtranetSuperuser == true) {
            cesfUser.value.companyId = idCompany;
            if(company.value?.id != cesfUser.value.companyId){
                company.value = await profileService.getCompany(cesfUser.value.companyId);
            }
        }
    }

    return { cesfUser, isUserWorker, isUserCompany, company, switchCompany }
}