<template>
    <Page>
        <PageHeader title="Cantieri"></PageHeader>

        <div class="row">
            <div class="col-6">
                <KInputSearch
                    placeholder="Cerca cantiere"
                    style="margin-bottom: 0 !important;"
                    v-model="cantiereFullSearchTerm"
                    @search="onCantiereFullSearch" />
            </div>
            <!-- <div class="me-auto col-12 col-md-6 col-lg-6 d-inline-flex align-items-center justify-content-end">
                <label>Filtra </label>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary btn-outline-secondary dropdown-toggle text-black" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Comune
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary dropdown-toggle btn-outline-secondary text-black" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Tipologia
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="row">
            <div class="col-12">
                <DataTable :value="cantieri" dataKey="id" responsiveLayout="scroll" :loading="moduleBuildingSiteStore.loadingCantieri">
                    <Column header="COMUNE">
                        <template #body="slotProps">
                            {{slotProps.data.comuneISTAT}} ({{slotProps.data.provinciaISTAT}})
                        </template>
                    </Column>
                    <Column header="INDIRIZZO">
                        <template #body="slotProps">
                            {{slotProps.data.via}} {{slotProps.data.numeroCivico}}
                        </template>
                    </Column>
                    <!-- <Column field="tipologiaDeiLavori" header="TIPOLOGIA"></Column> -->
                    <Column header="INIZIO">
                        <template #body="slotProps">
                            {{toDateString(slotProps.data.dataPresuntoInizio)}}
                        </template>
                    </Column>
                    <Column header="FINE">
                        <template #body="slotProps">
                            {{toDateString(slotProps.data.dataPresuntaFine)}}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <button @click="goToCantiereDetail(slotProps.data)" type="button" class="btn btn-primary"> DETTAGLI </button>
                            </div>
                        </template>
                    </Column>
                    <template #empty> Nessun cantiere trovato </template>
                </DataTable>
                <KPaginator :current-page="pageInfo.pageIndex" :total-count="pageInfo.totalCount" :total-pages="pageInfo.totalPages"
                    :show-total-count="true" @page-selected="goToPage">
                </KPaginator>
            </div>
        </div>

        <BannerEsperto titolo="Hai bisogno di attivare un servizio per cantiere?" testo-btn="CONTATTA IL TUO CONSULENTE CESF" />
    </Page>
</template>

<script setup lang="ts">

import { ref, watch, nextTick, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { KPaginator, PageInfo, useFilters } from "@k-digitale/kappa";
import { useCesfUser } from "@/composables";
import { useBuildingSiteStore } from "../store"
import { Cantiere, CantiereFinder } from "@/services/cesf/Models";
import BannerEsperto from "@/components/BannerEsperto.vue";

//EXT
const { toDateString } = useFilters();

//COMPOSABLES
const { cesfUser, isUserCompany, isUserWorker } = useCesfUser();
const router = useRouter();

//STORES
const moduleBuildingSiteStore = useBuildingSiteStore();

const goToCantiereDetail = (item: Cantiere) => {
    router.push({
        path: `/buildingsite/detail/${item.id}`
    });
};

const cantieri = ref<Cantiere[]>([]);

//VUE
onMounted(async () => {
    await loadCantieri();
});

//METHODS
const pageInfo = ref<PageInfo>({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
});

const cantiereFinder = ref<CantiereFinder>({
    pageIndex: pageInfo.value.pageIndex,
    pageSize: pageInfo.value.pageSize,
    sortField: "DataPresuntoInizio",
    sortOrder: -1
} as CantiereFinder);

const cantiereFullSearchTerm = ref<string>("");
const onCantiereFullSearch = () => {
    cantiereFinder.value.globalSearchTerm = cantiereFullSearchTerm.value;
    loadCantieri();
}

const loadCantieri = async () => {
    if(isUserCompany.value || isUserWorker.value){
        const result = await moduleBuildingSiteStore.getCantieriByCompany(cantiereFinder.value, isUserWorker.value ? null : cesfUser.value.companyId);
        pageInfo.value = result;
        cantieri.value = result.items;
    }
}

const goToPage = async (selectedPage: number) => {
    if(pageInfo.value.pageIndex != selectedPage) {
        pageInfo.value.pageIndex = selectedPage;
        cantiereFinder.value.pageIndex = pageInfo.value.pageIndex;
        cantiereFinder.value.pageSize = pageInfo.value.pageSize;
        await loadCantieri();
    }
}


</script>

<style lang="scss">
.tb-lista-cantieri .p-datatable-wrapper {
    table {
        thead {
            tr {
                th {
                    border: none;
                }
            }
        }
    }
}
</style>
