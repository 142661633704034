<template>
    <Page>
        <PageHeader title="Corsi"></PageHeader>
        <!-- <div class="row">
            <div class="col">
                <KNotificationCard :title="'Attestato scaduto:'" :body="'Lorem ipsum dolor sit amet, consectertur adipiscing elit.'" :level="NotiticationLevel.danger" :showPrimaryButton="true" :primaryButton="{ label: 'DETTAGLI' }"></KNotificationCard>
            </div>
        </div> -->
        <div class="row">
            <div class="col d-inline-flex justify-content-between">
                <h3>Corsi in Partenza</h3>
                <button type="button" class="btn btn-primary" @click="goToAllCourses">
                    TUTTI I CORSI
                </button>
            </div>
        </div>
        <div class="row">
            <p v-if="courseStore.startingCourses.length == 0">Non sono presenti al momento corsi in partenza.</p>
            <div class="col-12 col-md-6 col-lg-4 pt-3 py-lg-3" v-for="(cc, index) in courseStore.startingCourses" :key="index">
                <KCard
                    :title="cc.template?.shortTitle ? cc.template?.shortTitle : cc.template?.title"
                    title-class="text-uppercase"
                    :style="'padding-top: 20px'"
                    :topTitle="`${toDateString(cc.startDate)}`"
                    topTitleIcon="it-clock"
                    :showCmdButton="true" labelCmdButton="ISCRIVITI"
                    @cmd-button-click='goToSubscribePage(cc.id)'
                    />

            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-8 d-flex d-inline-flex">
                <h3 class="mr-2 w-25">I miei corsi</h3>
                <KInputSearch
                    class="w-75"
                    placeholder="Cerca corso"
                    style="margin-bottom: 0 !important;"
                    v-model="courseOutcomesFinder.relatedCourseTemplateTitle"
                    @search="loadCourseOutcomes()" />
            </div>
            <!-- <div class="me-auto col-12 col-md-6 col-lg-6 d-inline-flex align-items-center justify-content-end">
                <label>Filtra per</label>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary btn-outline-secondary dropdown-toggle text-black"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Data Scadenza
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="btn-group ml-5">
                    <button type="button" class="btn btn-secondary dropdown-toggle btn-outline-secondary text-black"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Stato
                        <KIcon class="icon-expand icon-sm" icon="it-expand" />
                    </button>
                    <div class="dropdown-menu">
                        <div class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 1</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 2</span></a>
                                </li>
                                <li>
                                    <a class="dropdown-item list-item" href="#"><span>Azione 3</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="row">
            <div class="col-12">
                <table class="table table-borderless">
                    <thead class="table-dark">
                        <tr>
                            <th scope="col" style="max-width: 200px">CORSO</th>
                            <th scope="col">NOMINATIVO</th>
                            <th scope="col">SCADENZA</th>
                            <th scope="col">STATO</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="loading">
                            <tr>
                                <td colspan="4">
                                    <KSpinner :loading="loading"/>
                                </td>
                            </tr>
                        </template>
                        <template v-else-if="myCourses.length > 0" v-for="(mc, index) in myCourses" :key="index">
                            <tr>
                                <td colspan="3" style="width: 50%;">
                                    <div class="d-flex">
                                        <div class="d-flex align-items-center" v-if="mc.isOrphan" tabindex="1" v-tooltip.focus="'Il corso base non è stato fatto presso CESF'">
                                            <KIcon icon="it-error" class="icon-danger"/>
                                        </div>
                                        <div class="accordion accordion-left-icon border-0" id="accordionMyCourses">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" :id="`myCourseHeading${index}`">
                                                    <button class="accordion-button border-0 text-black" type="button"
                                                        data-bs-toggle="collapse"
                                                        :data-bs-target="`#myCourseCollapse${index}`" aria-expanded="false"
                                                        :aria-controls="`myCourseCollapse${index}`">
                                                        {{ mc.name }}
                                                    </button>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <div style="padding: 14px 24px">
                                        Totali {{ mc.totalStudents }}:
                                        <span v-if="mc.validStudents">
                                            <div class="course-state-code inline-block ml-4" :class="`bg-success`"/>
                                            Validi {{ mc.validStudents }}
                                        </span>
                                        <span v-if="mc.expiringStudents">
                                            <div class="course-state-code inline-block ml-4" :class="`bg-warning`"/>
                                            In scadenza {{ mc.expiringStudents }}
                                        </span>
                                        <span v-if="mc.expiredStudents">
                                            <div class="course-state-code inline-block ml-4" :class="`bg-danger`"/>
                                            Scaduti {{ mc.expiredStudents }}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <a @click="goToCourseDetail(mc.courseId)" class="read-more mt-3 float-end" role="button" title="VAI AL CORSO">
                                        <span class="text">
                                            VAI AL CORSO
                                            <span class="visually-hidden">VAI AL CORSO</span>
                                        </span>
                                        <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                                    </a>
                                </td>
                            </tr>
                            <tr v-for="(dd, indexRow) in mc.details" :key="indexRow" :id="`myCourseCollapse${index}`"
                                class="accordion-collapse collapse" :class="indexRow > 0 ? 'border-top' : ''"
                                data-bs-parent="#accordionMyCourses" role="region"
                                :aria-labelledby="`myCourseHeading${index}`">
                                <td style="max-width: 200px"></td>
                                <td>
                                    <div class="accordion-body p-0">{{ dd.userFullName }}</div>
                                </td>
                                <td>
                                    <div class="accordion-body p-0">{{ dd.expireDate }}</div>
                                </td>
                                <td>
                                    <div class="course-state-code inline-block mr-4" :class="`bg-${dd.stateCode}`">
                                    </div>
                                    <div class="accordion-body p-0 inline-block">{{ dd.stateDescription }}</div>
                                    <div v-if="dd.partialOutcomes">
                                        Effettuate {{ dd.partialOutcomes.hoursDone }} ore su {{ dd.partialOutcomes.hoursForUpdate }} per l'aggiornamento
                                    </div>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-primary"
                                    @click="goToWorkerDetail(dd.workerId)"> DETTAGLI </button>
                                </td>
                                <td>
                                    <button v-if="dd.updateCourseId" type="button" class="btn" :class="{'btn-primary': dd.stateCode == 'danger', 'btn-outline-primary': dd.stateCode != 'danger' }"
                                    @click="goToCourseDetail(dd.updateCourseId)"> AGGIORNA </button>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td>Non sono presenti al momento corsi effettuati.</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <h3 class="mr-2 w-25">Altri corsi</h3>
        <div class="row">
            <div class="col-12">
                <table class="table table-borderless">
                    <thead class="table-dark">
                        <tr>
                            <th scope="col" style="max-width: 200px">CORSO</th>
                            <th scope="col">NOMINATIVO</th>
                            <th scope="col">SCADENZA</th>
                            <th scope="col">STATO</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="loading">
                            <tr>
                                <td colspan="4">
                                    <KSpinner :loading="loading"/>
                                </td>
                            </tr>
                        </template>
                        <template v-else-if="otherCourses.length > 0" v-for="(mc, index) in otherCourses" :key="index">
                            <tr>
                                <td colspan="3" style="width: 50%;">
                                    <div class="accordion accordion-left-icon border-0" id="accordionOtherCourses">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" :id="`otherCourseHeading${index}`">
                                                <button class="accordion-button border-0 text-black" type="button"
                                                    data-bs-toggle="collapse"
                                                    :data-bs-target="`#otherCourseCollapse${index}`" aria-expanded="false"
                                                    :aria-controls="`otherCourseCollapse${index}`">
                                                    {{ mc.name }}
                                                </button>
                                            </h2>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="2">
                                    <div style="padding: 14px 24px">
                                        Totali {{ mc.totalStudents }}
                                    </div>
                                </td>
                                <td>
                                    <a @click="goToCourseDetail(mc.courseId)" class="read-more mt-3 float-end" role="button" title="VAI AL CORSO">
                                        <span class="text">
                                            VAI AL CORSO
                                            <span class="visually-hidden">VAI AL CORSO</span>
                                        </span>
                                        <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                                    </a>
                                </td>
                            </tr>
                            <tr v-for="(dd, indexRow) in mc.details" :key="indexRow" :id="`otherCourseCollapse${index}`"
                                class="accordion-collapse collapse" :class="indexRow > 0 ? 'border-top' : ''"
                                data-bs-parent="#accordionOtherCourses" role="region"
                                :aria-labelledby="`otherCourseHeading${index}`">
                                <td style="max-width: 200px"></td>
                                <td>
                                    <div class="accordion-body p-0">{{ dd.userFullName }}</div>
                                </td>
                                <td>
                                    <div class="accordion-body p-0">{{ dd.expireDate }}</div>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-primary"
                                    @click="goToWorkerDetail(dd.workerId)"> DETTAGLI </button>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td>Non sono presenti al momento corsi effettuati.</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>

        <BannerEsperto titolo="Non sai quale corso scegliere?" testo-btn="CHIEDI AL TUO CONSULENTE" />

        <h2>Le mie iscrizioni</h2>
        <h5>Effettuate</h5>
        <DataTable table-class="k-data-table k-row-clickable"
            @sort=" onCourseParticipationSort($event)"
            @row-click="goToCourseDetail($event.data.courseInstance.courseTemplateId)"
            :value="courseParticipations"
            :loading="loading"
            dataKey="id"
            responsiveLayout="scroll">

            <Column sortable field="name" header="Lavoratore">
                <template #body="slotProps">
                    {{slotProps.data.student.worker.lastName}} {{slotProps.data.student.worker.name}}
                </template>
            </Column>
            <Column sortable field="course" header="Corso" >
                <template #body="slotProps">
                    {{slotProps.data.courseInstance.template.title}}
                </template>
            </Column>
            <Column sortable field="courseInstance.startDate" header="Data corso" >
                <template #body="slotProps">
                    {{slotProps.data.timestamp ? toDateString(slotProps.data.courseInstance.startDate) : '-' }}
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <a @click="goToCourseDetail(slotProps.data.courseInstance.courseTemplateId)" class="read-more mt-3 float-end" role="button" title="VAI AL CORSO">
                        <span class="text">
                            VAI AL CORSO
                            <span class="visually-hidden">VAI AL CORSO</span>
                        </span>
                        <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                    </a>
                </template>
            </Column>

            <template #empty>
                Nessuna iscrizione trovata.
            </template>
        </DataTable>
        <KPaginator :current-page="pageInfo.pageIndex" :total-count="pageInfo.totalCount" :total-pages="pageInfo.totalPages"
            :show-total-count="true" @page-selected="goToPage">
        </KPaginator>

        <h5>Richieste</h5>
        <DataTable table-class="k-data-table k-row-clickable"
            @sort=" onCourseParticipationRequestSort($event)"
            @row-click="goToCourseDetail($event.data.courseTemplate.id)"
            :loading="loading"
            :value="courseParticipationRequests"
            dataKey="id"
            responsiveLayout="scroll">

            <Column sortable field="name" header="Lavoratore">
                <template #body="slotProps">
                    {{slotProps.data.student.worker.lastName}} {{slotProps.data.student.worker.name}}
                </template>
            </Column>
            <Column sortable field="course" header="Corso" >
                <template #body="slotProps">
                    {{slotProps.data.courseTemplate.title}}
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <a @click="goToCourseDetail(slotProps.data.courseTemplate.id)" class="read-more mt-3 float-end" role="button" title="VAI AL CORSO">
                        <span class="text">
                            VAI AL CORSO
                            <span class="visually-hidden">VAI AL CORSO</span>
                        </span>
                        <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                    </a>
                </template>
            </Column>

            <template #empty>
                Nessuna pre-iscrizione trovata.
            </template>
        </DataTable>
        <KPaginator :current-page="preIscrizioniPageInfo.pageIndex" :total-count="preIscrizioniPageInfo.totalCount" :total-pages="preIscrizioniPageInfo.totalPages"
            :show-total-count="true" @page-selected="goToPartRequestPage">
        </KPaginator>
    </Page>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { nextTick, onMounted, ref, computed } from "vue";
import { CourseInstance,
    CourseParticipation,
    CourseParticipationFinder,
    CourseParticipationOutcome,
    CourseParticipationOutcomeFinder,
    CourseParticipationOutcomeStatus,
    CourseParticipationOutcomeValidityStatus,
    CourseParticipationRequestStatus,
    CourseParticipationStatus,
    CourseParticipationRequestFinder,
    CourseParticipationRequest } from "@/learning/instances/Models";
import { courseService } from "../service";
import { learningWrappersService } from "../../../services/learningWrappers/LearningWrappersServices";
import { useCourseStore } from "../store";
import { useCesfUser, useViewStatus } from "@/composables";
import { KCard, KPaginator, PageInfo, SortingOrder, useFilters } from "@k-digitale/kappa";
import { useRouter } from "vue-router";
import BannerEsperto from "@/components/BannerEsperto.vue";
import { CourseParticipationOutcomeWrapper, CourseTemplateTagType } from "@/services/learningWrappers/Models";
import { Collapse } from "bootstrap-italia";

const router = useRouter();
const { toDateString } = useFilters();
const courseStore = useCourseStore();
const { cesfUser, isUserCompany } = useCesfUser();
const viewStatusHelper = useViewStatus();

const startingCourses = ref<CourseInstance[]>([]);
const myCourses = ref<any[]>([]);
const otherCourses = ref<any[]>([]);
const loading = ref<boolean>(false);

onMounted(async () => {

    loading.value = true;
    await courseStore.loadStartingCourses(0, 3);
    await loadCourseOutcomes();
    await Promise.all([loadParticipations(), loadParticipationRequest()]);
    loading.value = false;

    nextTick(() => {
        var triggerAccordions = [].slice.call(document.querySelectorAll(`.accordion`))
        triggerAccordions.forEach(function (triggerEl) {
            (Collapse as any).getOrCreateInstance(triggerEl);
        })
    });
});

const courseOutcomesFinder = ref<CourseParticipationOutcomeFinder>({
    queryRegistriesRelations: true,
    pageIndex: -1,
    pageSize: -1,
    status: CourseParticipationOutcomeStatus.Success,
    validityStatus: CourseParticipationOutcomeValidityStatus.Active,
    sortOrder: -1
} as CourseParticipationOutcomeFinder);

const loadCourseOutcomes = async () => {
    if(isUserCompany){
        const courseParticipationsResponse =
            await learningWrappersService.getCourseParticipationOutcomesWrappersForActualCompanyStudents(
                cesfUser.value.companyId,
                courseOutcomesFinder.value);
        if(courseParticipationsResponse.items.length > 0){
            let fathers = courseParticipationsResponse.items.filter(o  => o.fathersCourseTemplates.length == 0 && o.validityStatus == CourseParticipationOutcomeValidityStatus.Active);
            const groupedParticipations = fathers.reduce((acc : any, obj : CourseParticipationOutcome) => {
                const key = obj.relatedCourseTemplateId;
                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push(obj);
                return acc;
            }, {});
            myCourses.value = [];
            otherCourses.value = [];
            for (var fatherKey of Object.keys(groupedParticipations)) {
                const group = groupedParticipations[fatherKey];

                const groupedByStudent = group.reduce((acc : any, obj : CourseParticipationOutcome) => {
                    const key = obj.courseParticipation.studentId;
                    if (!acc[key]) {
                        acc[key] = [];
                    }

                    acc[key].push(obj);
                    return acc;
                }, {});
                const studentsActiveCourses = [] as CourseParticipationOutcomeWrapper[];
                for(var studentKey of Object.keys(groupedByStudent)){
                    const groupByStudent = groupedByStudent[studentKey];
                    const sortedGroup = (groupByStudent as CourseParticipationOutcomeWrapper[]).sort((a,b) => {
                        return new Date(b.courseDate).getTime() - new Date(a.courseDate).getTime();
                    });
                    studentsActiveCourses.push(sortedGroup[0]);
                }
                const studentsArray = studentsActiveCourses.map(cp =>{
                    return {
                        userFullName: cp.courseParticipation.student.worker.name+ ' ' + cp.courseParticipation.student.worker.lastName,
                        expireDate: toDateString(cp.expiryDate),
                        stateCode: viewStatusHelper.getStatusCodeFromDate(cp.expiryDate),
                        stateDescription: viewStatusHelper.getStatusLabelFromDate(cp.expiryDate),
                        workerId: cp.courseParticipation.student.workerId,
                        updateCourseId: cp.relatedUpdateCourseId,
                        partialOutcomes: cp.partialOutcomes
                    }
                });
                const outcome = (group[0] as CourseParticipationOutcomeWrapper);
                const courseView = {
                            courseId: outcome.relatedCourseTemplateId,
                            name: outcome.relatedCourseTemplate.shortTitle ? outcome.relatedCourseTemplate.shortTitle : outcome.relatedCourseTemplate.title,
                            details: studentsArray,
                            totalStudents: studentsArray.length,
                            validStudents: studentsArray.filter(s => s.stateCode == "success")?.length,
                            expiredStudents: studentsArray.filter(s => s.stateCode == "danger")?.length,
                            expiringStudents: studentsArray.filter(s => s.stateCode == "warning")?.length
                        };
                if(outcome.relatedTemplateTags && outcome.relatedTemplateTags.some(t => t == CourseTemplateTagType.Main)){
                    myCourses.value.push(courseView);
                } else{
                    otherCourses.value.push(courseView);
                }
            }

            //Raggruppo per i corsi base padre
            let fathersRelatedCourseTemplatesId = fathers.map(f => f.relatedCourseTemplateId);
            let orphans = courseParticipationsResponse.items.filter(o => o.fathersCourseTemplates.length > 0 && !o.fathersCourseTemplates.some(ct => fathersRelatedCourseTemplatesId.includes(ct)));
            let groupedOrphansByFather = orphans.reduce((acc : any, obj : CourseParticipationOutcomeWrapper) => {
                const key = obj.fathersCourseTemplates[0];
                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push(obj);
                return acc;
            }, {});

            for (var fatherKey of Object.keys(groupedOrphansByFather)) {
                const groupByFather = groupedOrphansByFather[fatherKey];

                //Raggruppo il sottogruppo per studente in modo da calcolarmi gli aggiornamenti più recenti
                let groupedOrphansByStudents = groupByFather.reduce((acc : any, obj : CourseParticipationOutcomeWrapper) => {
                    const key = obj.courseParticipation.studentId;
                    if (!acc[key]) {
                        acc[key] = [];
                    }

                    acc[key].push(obj);
                    return acc;
                }, {});
                const studentsOrphansActiveCourses = [] as CourseParticipationOutcomeWrapper[];
                for(var studentKey of Object.keys(groupedOrphansByStudents)){
                    const groupByStudent = groupedOrphansByStudents[studentKey];
                    const sortedGroup = (groupByStudent as CourseParticipationOutcomeWrapper[]).sort((a,b) => {
                        return new Date(b.courseDate).getTime() - new Date(a.courseDate).getTime();
                    });
                    studentsOrphansActiveCourses.push(sortedGroup[0]);
                }

                //Infine raggruppo i restanti gruppi per relativo template per avere i corsi da mostrare
                let groupedOrphansByRelatedCourse = studentsOrphansActiveCourses.reduce((acc : any, obj : CourseParticipationOutcomeWrapper) => {
                    const key = obj.relatedCourseTemplateId;
                    if (!acc[key]) {
                        acc[key] = [];
                    }

                    acc[key].push(obj);
                    return acc;
                }, {});

                for(var relatedCourseKey of Object.keys(groupedOrphansByRelatedCourse)){
                    const groupByRelatedCourse = groupedOrphansByRelatedCourse[relatedCourseKey];
                    const studentsArray = (groupByRelatedCourse as CourseParticipationOutcomeWrapper[]).map(cp =>{
                        const courseDate = new Date(cp.courseDate);
                        const validityMonths = cp.relatedCourseTemplate.validityMonths;
                        cp.expiryDate = cp.expiryDate ? cp.expiryDate : new Date((courseDate.setMonth(courseDate.getMonth()+validityMonths)));
                        return {
                            userFullName: cp.courseParticipation.student.worker.name+ ' ' + cp.courseParticipation.student.worker.lastName,
                            expireDate: toDateString(cp.expiryDate),
                            stateCode: viewStatusHelper.getStatusCodeFromDate(cp.expiryDate),
                            stateDescription: viewStatusHelper.getStatusLabelFromDate(cp.expiryDate),
                            workerId: cp.courseParticipation.student.workerId,

                            }
                        });
                        const outcome = (groupByRelatedCourse[0] as CourseParticipationOutcomeWrapper);
                        const courseView = {
                            courseId: outcome.relatedCourseTemplateId,
                            name: outcome.relatedCourseTemplate.shortTitle ? outcome.relatedCourseTemplate.shortTitle : outcome.relatedCourseTemplate.title,
                            details: studentsArray,
                            totalStudents: studentsArray.length,
                            validStudents: studentsArray.filter(s => s.stateCode == "success")?.length,
                            expiredStudents: studentsArray.filter(s => s.stateCode == "danger")?.length,
                            expiringStudents: studentsArray.filter(s => s.stateCode == "warning")?.length,
                            isOrphan: true
                        };
                        myCourses.value.push(courseView);
                }

            }
        }
    }
}

const goToWorkerDetail = (id: string) => {
    router.push({
        path: `/employee/detail/${id}/attestati`
    });
};

const goToCourseDetail = (id: string) => {
    router.push({ name: 'course-detail', params: { id: id } });
};
const goToSubscribePage = (id: string) => {
    router.push({ name: 'course-registration', params: { id: id } });
};
const goToAllCourses = () => {
    router.push({
        path: `/courses`,
    });
};

//tabella partecipazioni
const pageInfo = ref<PageInfo>({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
});

const courseParticipationsFinder = ref<CourseParticipationFinder>({
    queryInstancesRelations: true,
    queryRegistriesRelations: true,
    pageIndex: pageInfo.value.pageIndex,
    pageSize: pageInfo.value.pageSize,
    companyId: cesfUser.value.companyId,
    status: CourseParticipationStatus.SignedUp,
    sortField: "courseInstance.startDate",
    sortOrder: SortingOrder.Descending,
} as CourseParticipationFinder);

const courseParticipations = ref<CourseParticipation[]>([]);
const loadParticipations = async () => {
    loading.value = true;
    const results = await courseStore.getParticipations(courseParticipationsFinder.value);
    if(results){
        pageInfo.value = results;
        courseParticipations.value = results.items;
    }
    loading.value = false;
}

const goToPage = async (selectedPage: number) => {
    if(pageInfo.value.pageIndex != selectedPage) {
        pageInfo.value.pageIndex = selectedPage;
        courseParticipationsFinder.value.pageIndex = pageInfo.value.pageIndex;
        courseParticipationsFinder.value.pageSize = pageInfo.value.pageSize;
        await loadParticipations();
    }
}

const onCourseParticipationSort = async (event : any) => {
    courseParticipationsFinder.value.sortField = event.sortField;
    courseParticipationsFinder.value.sortOrder = event.sortOrder;
    await loadParticipations();
}

//tabella pre-iscrizioni
const preIscrizioniPageInfo = ref<PageInfo>({
    pageIndex: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0
});

const courseParticipationRequestsFinder = ref<CourseParticipationRequestFinder>({
    queryRelations: true,
    status: CourseParticipationRequestStatus.NotSignedUp,
    companyId: cesfUser.value.companyId,
    pageIndex: pageInfo.value.pageIndex,
    pageSize: pageInfo.value.pageSize,
    sortField: "timestamp",
} as CourseParticipationRequestFinder);

const courseParticipationRequests = ref<CourseParticipationRequest[]>([]);
const loadParticipationRequest = async () => {
    loading.value = true;
    const results = await courseStore.getParticipationRequests(courseParticipationRequestsFinder.value);
    if(results){
        preIscrizioniPageInfo.value = results;
        courseParticipationRequests.value = results.items;
    }
    loading.value = false;
}

const goToPartRequestPage = async (selectedPage: number) => {
    if(preIscrizioniPageInfo.value.pageIndex != selectedPage) {
        preIscrizioniPageInfo.value.pageIndex = selectedPage;
        courseParticipationRequestsFinder.value.pageIndex = pageInfo.value.pageIndex;
        courseParticipationRequestsFinder.value.pageSize = pageInfo.value.pageSize;
        await loadParticipationRequest();
    }
}

const onCourseParticipationRequestSort = async (event : any) => {
    courseParticipationRequestsFinder.value.sortField = event.sortField;
    courseParticipationRequestsFinder.value.sortOrder = event.sortOrder;
    await loadParticipationRequest();
}

</script>

<style lang="scss">
.go-to-course-accordion {
    top: 19px;
    right: 70px;
}

.course-state-code {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}
</style>
