import { ApiPagedResult, BaseService } from "@k-digitale/kappa";
import { CourseInstanceFinder, CourseParticipationOutcomeFinder } from "@/learning/instances/Models";
import { CourseInstanceWrapper, CourseParticipationOutcomeWrapper, CourseTemplateListItemView, WorkersRLSWrapper } from "./Models";
import { WorkerFinder } from "@/learning/registries/Models";
import { CourseTemplateFinder } from "@/learning/templates/Models";

class LearningWrappersServices extends BaseService {

    async getCourseParticipationOutcomesWrappersByFinder(finder: CourseParticipationOutcomeFinder): Promise<ApiPagedResult<CourseParticipationOutcomeWrapper>> {
        const response = await this.tryExecute(
            this.api.get<any>("cesf/learning-wrappers/course-participation-outcomes/", { params: finder })
        );

        return response!.data;
    }
    async getCourseParticipationOutcomesWrappersForActualCompanyStudents(companyId: string, finder: CourseParticipationOutcomeFinder): Promise<ApiPagedResult<CourseParticipationOutcomeWrapper>> {
        const response = await this.tryExecute(
            this.api.get<any>(`cesf/learning-wrappers/companies/${companyId}/course-participation-outcomes/`, { params: finder })
        );

        return response!.data;
    }

    async getCourseInstancesWrappersByFinder(finder: CourseInstanceFinder): Promise<ApiPagedResult<CourseInstanceWrapper>> {
        const response = await this.tryExecute(
            this.api.get<any>("cesf/learning-wrappers/course-instances/", { params: finder })
        );

        return response!.data;
    }
    async getWorkersRlsWrappersByFinder(finder: WorkerFinder): Promise<ApiPagedResult<WorkersRLSWrapper>> {
        const response = await this.tryExecute(
            this.api.get<any>("cesf/learning-wrappers/workers-rls/", { params: finder })
        );

        return response!.data;
    }

    async getCourseTemplatesListView(finder: CourseTemplateFinder): Promise<ApiPagedResult<CourseTemplateListItemView>> {
        const response = await this.tryExecute(
            this.api.get<any>("cesf/learning-wrappers/course-templates/list-view", { params: finder })
        );

        return response!.data;
    }

}

export const learningWrappersService = new LearningWrappersServices();
