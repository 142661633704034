import { CourseInstance, CourseParticipationOutcome } from "@/learning/instances/Models";;
import { CourseTemplate } from "@/learning/templates/Models";;
import { Worker } from "../../learning/registries/Models";;

export enum CourseTemplateTagType {
    Main = "Main",
}

export interface CourseParticipationOutcomeWrapper extends CourseParticipationOutcome {
    fathersCourseTemplates: string[];
    relatedTemplateTags: CourseTemplateTagType[];
    relatedUpdateCourseId?: string;
    partialOutcomes: PartialOutcomeReport
}

export interface PartialOutcomeReport {
    hoursDone: number;
    hoursForUpdate: number;
}

export interface CourseInstanceWrapper extends CourseInstance {
    subscribedNumber: number;
}

export interface CourseTemplateListItemView extends CourseTemplate {
    templateStartingCourses: number;
}

export interface WorkersRLSWrapper extends Worker {
    idDMSAttestato?: number;
    idDMSAggiornamento?: number;
    dataScadenzaCorso?: Date;
    idRLSCourseOutcome?: string;
    idRLSAggiornamentoOutcome?: string;
}