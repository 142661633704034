import { defineStore } from "pinia";
import { Cantiere, CantiereFinder, CantiereVisibility, CantiereVisibilityFinder } from "@/services/cesf/Models";
import { Worker } from "@/learning/registries/Models";
import { cesfService } from "@/services/cesf/CesfServices";
import { ApiPagedResult, ApiResult, PageInfo } from "@k-digitale/kappa";
import { SaveDeleteCantiereVisibilityListRequest } from "@/services/cesf/Requests";

export interface ModuleBuildingSiteState {
  currentCantiere: Cantiere,
  cantieri: Cantiere[],
  cantieriVisibilities: CantiereVisibility[],
  loadingCantieri: boolean,
  loadingCantiere: boolean,
  loadingPreposti: boolean,
  loadingCantieriVisibilities: boolean,
  savingCantieriVisibilities: boolean,
  loadingDocumentiCantiere: boolean,

}

export const useBuildingSiteStore = defineStore('module-buildingsite-store', {

  state: () => ({
    currentCantiere: {},
    cantieri: [],
    loadingCantieri: false,
    loadingCantiere: false,
    loadingPreposti: false,
    loadingCantieriVisibilities: false,
    savingCantieriVisibilities: false,
    cantieriVisibilities: [],
    loadingDocumentiCantiere: false
  } as ModuleBuildingSiteState),

  getters: {
    //
  },

  actions: {
    async getCantieriByCompany(finder: CantiereFinder, companyId: string): Promise<ApiPagedResult<Cantiere>> {
      this.loadingCantieri = true;
      const result = await cesfService.getCantieriByEduCompanyId(finder, companyId);
      this.loadingCantieri = false;
      return result;
    },

    async getCantiereById(id: string): Promise<Cantiere> {
      this.loadingCantiere = true;
      const result = await cesfService.getCantiere(id);
      this.loadingCantiere = false;
      return result;
    },

    // DOCUMENTI CANTIERE
    async getDocumentiCantiere(id: string, companyId?: string) : Promise<any[]>{
      this.loadingDocumentiCantiere = true;
      try {
        const response = await cesfService.getDocumentiCantiere(id, companyId);
        const result = response.items;
        this.loadingDocumentiCantiere = false;
        return result;
      }
      finally { this.loadingDocumentiCantiere = false; }
    },

    async uploadDocumentoCantiere(id: string, formData: FormData) : Promise<boolean> {
      this.loadingDocumentiCantiere = true;
      try {
        const result = await cesfService.uploadDocumentoCantiere(id, formData);
        this.loadingDocumentiCantiere = false;
        return result;
      }
      finally { this.loadingDocumentiCantiere = false; }
    },

    async downloadDocumentoCantiere(id : string, idDocumento : number) : Promise<void> {
        // this.loadingDocumentiCantiere = true;
        const result = await cesfService.downloadCantieriDocument(id, idDocumento);
        // this.loadingDocumentiCantiere = false;
    },

    async deleteDocumentoCantiere(id : string, idDocumento : number) : Promise<boolean> {
      // this.loadingDocumentiCantiere = true;
      const result = await cesfService.deleteDocumentoCantiere(id, idDocumento);
      // this.loadingDocumentiCantiere = false;
      return result;
    },

    async getPreposti(companyId : string, pageIndex: number, pageSize: number) : Promise<ApiPagedResult<Worker>>{
      this.loadingPreposti = true;
      const result = await cesfService.getCompaniesPreposti(companyId, pageIndex, pageSize);
      this.loadingPreposti = false;
      return result;
    },

    async getCantieriVisibilitiesByFinder(finder : CantiereVisibilityFinder) : Promise<PageInfo>{
      this.loadingCantieriVisibilities = true;
      const result = await cesfService.getCantieriVisibilityByFinder(finder);
      let pageInfo = {} as PageInfo;
      if(result){
        this.cantieriVisibilities = result.items;
        pageInfo.pageIndex = result.pageIndex;
        pageInfo.pageSize = result.pageSize;
        pageInfo.totalCount = result.totalCount;
        pageInfo.totalPages = result.totalPages;
      }
      this.loadingCantieriVisibilities = false;

      return pageInfo;
    },

    async saveDeleteCantieriVisibility(request : SaveDeleteCantiereVisibilityListRequest) : Promise<ApiResult<boolean>>{
      this.savingCantieriVisibilities = true;
      const result = await cesfService.saveDeleteCantieriVisibilities(request);
      this.savingCantieriVisibilities = false;
      return result;
    }
  }
})