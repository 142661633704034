<template>
    <Page :loading="loading" v-if="corso != null">
        <PageHeader title="Iscrizione"></PageHeader>
        <KBackButton />
        <div class="row mt-5">
            <div class="col-12">
                <div class="c-line kd-header-full">
                    <div class="header-top-title">Corso</div>
                    <div class="row">
                        <div class="col-12 col-md-8 col-lg-12">
                            <h3 class="title-text">
                                {{ corso.template.title }}
                            </h3>
                        </div>
                    </div>
                    <K3ColContainer rowClass="m-0" col1Class="p-1" col2Class="p-1" col3Class="p-1">
                        <template #col1>
                            <p class="header-sub-detail m-0">Durata</p>
                            <p>{{ corso.template.totalHours }} ore</p>
                        </template>
                        <template #col2>
                            <p class="header-sub-detail m-0">Partenza corso</p>
                            <p>{{ toDateString(corso.startDate) }}</p>
                        </template>
                        <template #col3>
                            <p class="header-sub-detail m-0">Luogo</p>
                            <p>{{ placeOfCourse }}</p>
                        </template>
                    </K3ColContainer>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 p-0">
                <div class="" id="collapseExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="dati-utente">
                            <button
                                    class="accordion-button h4 fw-normal text-decoration-none border-none border-bottom"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-user-data"
                                    aria-expanded="true"
                                    aria-controls="collapse1c">
                                Verifica i tuoi dati
                            </button>
                        </h2>
                        <div id="collapse-user-data" class="accordion-collapse collapse show" role="region" aria-labelledby="dati-utente">
                            <div class="accordion-body mt-5">
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.extCode1" :labelText="'Codice cassa edile '" :class="'form-control-plaintext'" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.businessName" :labelText="'Ragione Sociale'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.partitaIVA" :labelText="'Partita IVA'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.fiscalCode" :labelText="'Codice Fiscale'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.address" :labelText="'Indirizzo'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.zipCode" :labelText="'CAP'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.city" :labelText="'Comune'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.provincia" :labelText="'Provincia'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.phone" :labelText="'Telefono'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.fax" :labelText="'Fax'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.cellPhone" :labelText="'Cellulare'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                    <div class="cols-12 col-md-12 col-lg-6">
                                        <KInputText v-model="company.email" :labelText="'Email'" class="form-control-plaintext" :readonly="true"></KInputText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="row">
            <div class="col-12">
                <h4 class="fw-normal">Chi desideri iscrivere?</h4> <h5>Disponibilità: {{ availableSpots }}</h5>
            </div>
            <CourseRegistrationWorkerList
                    :courseTemplateId="corso.courseTemplateId"
                    v-model="dipendentiAggiuntiItems"
                    v-model:canceledSubscriptions="dipendentiRimossiItems"
                    :workersList="dipendentiCercatiItems"
                    :subscribedWorkersId="subscribedWorkersId"
                    is-subscription
                    @on-new-worker-saved="loadData"
                    @on-search-employees="onSearchEmployee"
                    :available-spots="availableSpots"
                    />
        </div>

        <div class="row text-center">
            <div class="col-12">
                <button :disabled="dipendentiAggiuntiItems.length == 0 && dipendentiRimossiItems.length == 0 " type="button" @click="sendSubscriptionRequest" class="btn btn-primary col-xs-8 col-md-4 col-lg-4 col-xl-3">INVIA RICHIESTA</button>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useFilters, SortingOrder } from "@k-digitale/kappa";
import { courseService } from "../service";
import { CourseInstance, CourseParticipation, CourseParticipationFinder, CourseParticipationOutcomeFinder, CourseParticipationOutcomeStatus, CourseParticipationOutcomeValidityStatus, CourseParticipationRequest, CourseParticipationRequestFinder, CourseParticipationStatus, LessonInstance, LessonInstanceFinder,} from "@/learning/instances/models";
import { useRoute, useRouter } from "vue-router";
import { profileService } from "@/modules/profile/service";
import {learningExtensionService} from "../../../services/learningExtensions/LearningExtensionsServices"
import {learningWrappersService} from "../../../services/learningWrappers/LearningWrappersServices"
import { Company } from "@/learning/registries/models";
import { employeeService } from "@/modules/employee/service";
import { instancesService } from "@/learning/instances/instancesService";
import { useCesfUser,useViewStatus } from "@/composables";
import { useCourseStore } from "../store";
import { useToast } from "primevue/usetoast";

import { Student, StudentFinder, Worker, WorkerFinder } from "@/learning/registries/models";
import { SaveExternalCourseParticipationRequest, SetCourseParticipationCanceledRequest } from "../requests";
import { SaveStudentRequest } from "@/learning/registries/Requests";

//IMPORT COMPONENTS
import { CourseTemplate } from "@/learning/Templates/Models";
import { CourseParticipationOutcomeWrapper } from "@/services/learningWrappers/Models";
import CourseRegistrationWorkerList from "./components/CourseRegistrationWorkerList.vue";

const router = useRouter();
const route = useRoute();
const viewStatusHelper = useViewStatus();
const courseStore = useCourseStore();
const toast = useToast();
const loading = ref<boolean>();

const { toDateString } = useFilters();

const idCorso = ref(route.params.id);
const corso = ref<CourseInstance>(null);
const company = ref<Company>({} as Company);
const lessons = ref<LessonInstance[]>([]);
const subscribedNumber = ref<number>();
const { cesfUser, isUserCompany } = useCesfUser();

const companyStudents = ref<Student[]>([]);
const companyParticipations = ref<CourseParticipation[]>([]);
const dipendentiCercatiItems = ref<Worker[]>([]);

const dipendentiList = computed(() => {
    return dipendentiCercatiItems.value.map(d => {
        const relatedOutcome = outcomes.value.find(o => o.courseParticipation.student.workerId == d.id)
        return{
            courseBaseDone: relatedOutcome && !relatedCourseUpdate.value,
            courseBaseNotDone: !relatedOutcome && relatedCourseUpdate.value,
            expireDate: relatedOutcome ?  toDateString(relatedOutcome.expiryDate) : '-',
            stateCode: relatedOutcome ? viewStatusHelper.getStatusCodeFromDate(relatedOutcome.expiryDate) : '-',
            stateDescription: relatedOutcome ? viewStatusHelper.getStatusLabelFromDate(relatedOutcome.expiryDate) : '-',
            ...d
        }
    })
});

const dipendentiAggiuntiItems = ref<Worker[]>([]);
const dipendentiRimossiItems = ref<Worker[]>([]);
const relatedCourseUpdate = ref<CourseTemplate>();
const outcomes = ref<CourseParticipationOutcomeWrapper[]>([]);

const isNewEmployeePanelVisible = ref<boolean>(false);

const subscribedWorkersId = computed(() => {
    return companyParticipations.value.map(cp => cp.student?.workerId );
})

const subscribedWorkersAndParticipations = computed(() => {
    return companyParticipations.value.map(cp => {
        return {
            workerId: cp.student?.workerId,
            participationId: cp.id
        }
    });
})

onMounted(async () => {
    loading.value = true;
    corso.value = await courseService.getCourse(idCorso.value as string);
    if(isUserCompany.value){
        company.value = await profileService.getCompany(cesfUser.value.companyId);

        relatedCourseUpdate.value = await learningExtensionService.getRelatedUpdateCourse(corso.value.courseTemplateId);

        const lessonsFinder = {
            courseInstanceId: idCorso.value,
            sortField: "StartDate",
            sortOrder: SortingOrder.Ascending,
        } as LessonInstanceFinder;
        var respLessons = await courseService.getLessons(lessonsFinder);
        if (respLessons && respLessons.items) {
            lessons.value = respLessons.items;
        }

        await loadStudents();
        await loadCourseParticipations();
        await loadCourseOutcomes();
    }
    loading.value = false;

});

const loadData = async () =>{
    loading.value = true;
    await loadStudents();
    await loadCourseParticipations();
    await loadCourseOutcomes();
    loading.value = false;
}

const loadCourseOutcomes = async () => {
    let finder = {
        queryRegistriesRelations: true,
        pageIndex: -1,
        pageSize: -1,
        status: CourseParticipationOutcomeStatus.Success,
        validityStatus: CourseParticipationOutcomeValidityStatus.Active,
        sortOrder: -1
    } as CourseParticipationOutcomeFinder;

    if(relatedCourseUpdate.value){
        finder.relatedCourseTemplateId = relatedCourseUpdate.value.id;
    } else{
        finder.relatedCourseTemplateId = corso.value.courseTemplateId;
    }

    const outcomesResponse = await learningWrappersService.getCourseParticipationOutcomesWrappersForActualCompanyStudents(
        cesfUser.value.companyId,
        finder);

    outcomes.value = outcomesResponse.items;
}

const availableSpots = computed(() => {
    return corso.value.studentsNumber - subscribedNumber.value - dipendentiAggiuntiItems.value.length;
})

const loadStudents = async (fullTextSearch?: string) => {
    const studentFinder = {
        companyId: cesfUser.value.companyId,
        globalSearchTearm: fullTextSearch
    } as StudentFinder;

    var workerResponse = await employeeService.getStudents(studentFinder);
    if (workerResponse && workerResponse.items) {
        companyStudents.value = workerResponse.items;
        dipendentiCercatiItems.value = workerResponse.items.map(s => {return {notSubscribable: s.notSubscribable, toBeVerified: s.toBeVerified, ...s.worker}})
    }
}

const loadCourseParticipations = async () => {
    const courseParticipationFinder = {
        courseInstanceId: corso.value.id,
        queryRegistriesRelations: true,
        status: CourseParticipationStatus.SignedUp
    } as CourseParticipationFinder;

    var workerResponse = await instancesService.getCourseParticipationsByFinder(courseParticipationFinder);
    if (workerResponse && workerResponse.items) {
        subscribedNumber.value = workerResponse.items.length;
        companyParticipations.value = workerResponse.items.filter(cp => cp.companyId == company.value.id);
    }
}

const searchTimeout = ref<any>(null);
const onSearchEmployee = function (e: any) {
    clearTimeout(searchTimeout.value);

    searchTimeout.value = setTimeout(async () => {
        await loadStudents(e.target.value);
    }, 300);
};

const placeOfCourse = computed(() => {
    if (lessons.value && lessons.value.length) {
        var lsp = lessons.value.find((x) => {
            return x.place && x.place.length;
        });

        if (lsp) {
            return lsp.place;
        }
    }

    return "";
});

const sendSubscriptionRequest = async () => {
    const request = dipendentiAggiuntiItems.value.map(da => {
        const relatedStudent = companyStudents.value.find(s => s.workerId == da.id);
        if(relatedStudent){
            return{
                companyId: cesfUser.value.companyId,
                courseInstanceId: corso.value.id,
                studentId: relatedStudent.id,
            } as SaveExternalCourseParticipationRequest;
        } else{
            return{
                companyId: cesfUser.value.companyId,
                courseInstanceId: corso.value.id,
                saveStudentRequest: {
                    workerId: da.id,
                    toBeVerified: true,
                } as SaveStudentRequest,
            } as SaveExternalCourseParticipationRequest;
        }
    } ) as SaveExternalCourseParticipationRequest[];
    loading.value = true;
    if(request && request.length){

        const response = await courseStore.saveCourseParticipationSave(request);
        if(response){
            toast.add({severity: "success",summary: "Iscrizioni inviate con successo",life: 3000});
            dipendentiAggiuntiItems.value = [];
        } else{
            toast.add({severity: "error",summary: "Errore nell'invio delle iscrizioni",life: 3000});
        }
    }

    //Annullamento iscrizioni
    const rimossiIds =  dipendentiRimossiItems.value.map(d => d.id);
    const foundParticipations = subscribedWorkersAndParticipations.value.filter(a => rimossiIds.includes(a.workerId));
    if(foundParticipations && foundParticipations.length > 0){
        const cancelResponse = await courseStore.setCourseParticipationCancelled(foundParticipations.map(fp => {
            return {
                courseParticipationId: fp.participationId
            } as SetCourseParticipationCanceledRequest
        }));
        if(cancelResponse){
            toast.add({severity: "success",summary: "Richieste annullamento inviate con successo",life: 3000});
            dipendentiAggiuntiItems.value = [];
        } else{
            toast.add({severity: "error",summary: "Errore nell'invio delle richieste di annullamento",life: 3000});
        }
    }

    await loadCourseParticipations();
    await loadStudents();

    loading.value = false;
}

</script>

<style scoped lang="scss">
.kd-header-full {
    margin: -20px -30px -20px;
    padding: 20px;
    color: #333333;
    background-color: #f4f4f4;
}
.kd-already-sub {
    background-color: #f4f4f4;
}
.title-text {
    font-size: 40px;
}
.title-text-underline {
    text-decoration: underline;
}
.top-text {
    font-size: 14px;
    color: #757575;
}
.focus-text {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: 400;
}
.exit-menu {
    color: #0066cc;
    font-size: 16px;
    //font-weight: 600;
    justify-content: flex-end;

    .exit-menu-icon {
        fill: #0066cc;
    }
}
.header-top-title,
.header-sub-detail {
    color: #757575;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
}
.btn-add-dipendente {
    opacity: 1;
    color: rgba(0, 102, 204, 1);
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: right;
    text-transform: uppercase;
}
.list-row-dipendente-cf {
    opacity: 1;
    color: rgba(117, 117, 117, 1);
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.list-row-dipendente-name {
    opacity: 1;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.btn-aggiungi-dipendente {
    opacity: 1;
    color: rgba(0, 102, 204, 1);
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: right;
    text-transform: uppercase;

    .icon {
        fill: rgba(0, 102, 204, 1);
        font-size: 16px;
    }
}
.course-state-code {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}
</style>
