<template>
    <Page>
        <PageHeaderFull title="Benvenuto" :titleUnderline="pageDisplayName">
            <template #secondary-actions>
                <K3ColContainer v-if="company" col1Class="p-1" col2Class="p-1" col3Class="p-1">
                    <template #col1 v-if="company.extCode1">
                        Codice Cassa Edile: <span class="label-box">{{ company.extCode1 }}</span>
                    </template>
                    <template #col2 v-if="false">
                        <!-- <strong>RLS: </strong>{{companyData.responsabileSicurezzaLavoratori.eduWorker.name}} {{companyData.responsabileSicurezzaLavoratori.eduWorker.lastName}}
                        <div class="state-code inline-block mr-3" :class="`bg-success`"></div>
                        <div class="accordion-body p-0 inline-block">
                            Regolarità Amministrativa: <span class="second-col-label">In regola</span>
                        </div> -->
                    </template>
                    <template #col3>
                        <InfoRls :id-company="company.id" />
                    </template>
                </K3ColContainer>
            </template>
        </PageHeaderFull>

        <div class="row my-4">
            <div class="col-12 p-0">
                <div class="mb-2">
                    <span class="notify-title">Notifiche</span>
                </div>
                <!-- <button value="modal" @click="testmodal"></button> -->
                <KAlert
                    v-if="showExpiredCoursesNotification"
                    title="Corsi"
                    body="Sono presenti dei corsi scaduti"
                    showPrimaryButton
                    showSecondaryButton
                    primaryButton="DETTAGLI"
                    secondaryButton="RICORDAMELO PIU' TARDI"
                    :level="AlertLevel.danger"
                    @on-primary-button-click="router.push({path: `/course`})"
                    @on-secondary-button-click="showExpiredCoursesNotification = false">
                </KAlert>

                <!-- <CesfNotificationCard v-for="notifica in notificationStore.notifications" :key="notifica.id" :notifica="notifica" /> -->
                <KLatestNotifications :items-to-show="3" />
                <span v-if="notificationStore.notifications.length == 0 && !showExpiredCoursesNotification"> Nessuna notifica presente al momento. </span>
            </div>
        </div>

        <div class="row my-4">
            <div class="col-12 p-0" v-if="isUserCompany">

                <KTabs>
                    <!-- <KTab name="Corsi" :higlightCounter="coursesExpired">
                        <DataTable :value="corsiItems" dataKey="id" responsiveLayout="scroll" :loading="loading">
                            <Column header="CORSO">
                                <template #body="slotProps">
                                    <div v-if="isStateVisible(slotProps.data)" class="bg-primary state-code-label inline-block mr-3" />
                                    {{slotProps.data.corso}}
                                </template>
                            </Column>
                            <Column header="INIZIO CORSO">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.inizioCorso)}}
                                </template>
                            </Column>
                            <Column header="SCADENZA">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.dataScadenza)}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <button type="button" class="btn btn-outline-primary"
                                            @click="goToCourseDetail()"> SCOPRI DI PIU' </button>
                                    </div>
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <button type="button" class="btn btn-primary"
                                            @click="goToIscrizioneCorso(slotProps.data)"> ISCRIVITI </button>
                                    </div>
                                </template>
                            </Column>
                            <template #empty> Nessun corso trovato </template>
                        </DataTable>
                    </KTab> -->
                    <KTab name="Cantieri">
                        <DataTable :value="cantieriItems" dataKey="id" responsiveLayout="scroll" :loading="loading">
                            <Column field="comune" header="COMUNE" />
                            <Column field="indirizzo" header="INDIRIZZO" />
                            <!-- <Column field="tipologia" header="TIPOLOGIA" /> -->
                            <Column header="INIZIO">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.dataInizio)}}
                                </template>
                            </Column>
                            <Column header="FINE">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.dataFine)}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <button type="button" class="btn btn-primary"
                                            @click="goToCantiereDetail(slotProps.data)"> DETTAGLI </button>
                                    </div>
                                </template>
                            </Column>
                            <template #empty> Nessun cantiere trovato </template>
                        </DataTable>
                    </KTab>
                    <KTab name="Circolari e Comunicazioni">
                        <DataTable :value="circolariItems" dataKey="id" responsiveLayout="scroll" :loading="loading">
                            <Column field="name" header="TITOLO"></Column>
                            <Column header="DATA PUBBLICAZIONE">
                                <template #body="slotProps">
                                    {{toDateString(slotProps.data.publishedDate)}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div style="text-align: right">
                                        <a target="_blank" role="button" class="btn btn-primary" :href="(link+slotProps.data.url)"> DETTAGLI </a>
                                    </div>
                                </template>
                            </Column>
                            <template #empty> Nessuna circolare o comunicazione </template>
                        </DataTable>
                    </KTab>
                </KTabs>
            </div>
            <div class="col-12 p-0" v-else>
                <KSectionHeader title="Circolari e Comunicazioni" />
                <DataTable :value="circolariItems" dataKey="id" responsiveLayout="scroll" :loading="loading">
                    <Column field="name" header="TITOLO"></Column>
                    <Column header="DATA PUBBLICAZIONE">
                        <template #body="slotProps">
                            {{toDateString(slotProps.data.publishedDate)}}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <a target="_blank" role="button" class="btn btn-primary" :href="(link+slotProps.data.url)"> DETTAGLI </a>
                            </div>
                        </template>
                    </Column>
                    <template #empty> Nessuna circolare o comunicazione </template>
                </DataTable>
            </div>
        </div>

        <!-- <div class="row my-4">
            <div class="col-12 p-0">
                <h3 class="vantaggi-opportunita-title">Vantaggi e opportunità</h3>
            </div>
            <div class="col-12 p-0">
                <K3ColContainer col1Class="p-3" col2Class="p-3" col3Class="p-3">
                    <template #col1>
                        <KCard :title="'Verifica documentazione aziendale sulla sicurezza Verifica documentazione aziendale sulla sicurezza Verifica documentazione aziendale sulla sicurezza Verifica documentazione aziendale sulla sicurezza'"
                                :showDetailButton="true"
                                :showCmdButton="true"
                                :labelDetailButton="'SCOPRI DI PIU'"
                                :labelCmdButton="'ISCRIVITI'"
                                :eventCmdButton="goToCourseDetail">

                        </KCard>
                    </template>
                    <template #col2>
                        <KCard :title="'Verifica documentazione aziendale sulla sicurezza'"
                                :showDetailButton="true"
                                :showCmdButton="true"
                                :labelDetailButton="'SCOPRI DI PIU'"
                                :labelCmdButton="'ISCRIVITI'"
                                :eventCmdButton="goToCourseDetail">

                        </KCard>
                    </template>
                    <template #col3>
                        <KCard :title="'Verifica documentazione aziendale sulla sicurezza'"
                                :showDetailButton="true"
                                :showCmdButton="true"
                                :labelDetailButton="'SCOPRI DI PIU'"
                                :labelCmdButton="'ISCRIVITI'"
                                :eventCmdButton="goToCourseDetail">

                        </KCard>
                    </template>
                </K3ColContainer>
            </div>
        </div> -->

        <BannerEsperto />

        <div class="row my-4">
            <div class="col-8">
                <KSectionHeader title="Ultime dal settore" :separator="true" />
                <div class="col-12 p-0">
                    <KCmsLatest2 cmsNodeCode="listanews" :cmsContentBaseUrl="cmsContentsBaseUrl" />
                </div>
            </div>
            <div class="col-4">
                <KSectionHeader title="WebTV" />
                <div class="col-12 p-0">
                    <KWebTvLatest></KWebTvLatest>
                </div>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import dayjs from "dayjs";
import { useFilters, useUserStore, KAlert, AlertLevel, useNotificationStore, KLatestNotifications } from "@k-digitale/kappa";

//IMPORT MODELS
import { useCesfUser, useViewStatus } from "@/composables";
import { learningWrappersService } from "../services/learningWrappers/LearningWrappersServices";
import { Cantiere, CantiereFinder, CesfNotification } from "@/services/cesf/Models";
import { CourseInstanceFinder, CourseParticipationOutcome, CourseParticipationOutcomeFinder, CourseParticipationOutcomeStatus, CourseParticipationOutcomeValidityStatus } from "@/learning/instances/Models";

//IMPORT STORES
import { useBuildingSiteStore } from "@/modules/buildingsite/store";

import { useCourseStore } from "@/modules/course/store";
import { useCesfCmsStore } from "@/store"

import BannerEsperto from "@/components/BannerEsperto.vue";
import CesfNotificationCard from "@/components/CesfNotificationCard.vue";
import { CourseParticipationOutcomeWrapper, CourseTemplateTagType } from "@/services/learningWrappers/Models";
import InfoRls from "@/components/InfoRls.vue";

const { isUserCompany, cesfUser, company } = useCesfUser();
const viewStatusHelper = useViewStatus();

const router = useRouter();
const { toDDMMMMString, toDateString } = useFilters();
const { user } = useUserStore();

//STORES
const cesfCmsStore = useCesfCmsStore();
const coursesStore = useCourseStore();
const buildingSiteStore = useBuildingSiteStore();

//REFS
const loading = ref(true);
const corsiItems = ref<any[]>([]);
const showExpiredCoursesNotification = ref(false);

//COMPUTED
const circolariItems = computed(() => {
    return cesfCmsStore.circolari.sort((a,b) => {
        return new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime();
    })
});

const cmsContentsBaseUrl = computed(() => import.meta.env.VITE_CMS_DETAIL_BASE_URL);

const link = computed(() => import.meta.env.VITE_CMS_DETAIL_BASE_URL);



const cantieriItems = computed(() =>
    cantieri.value.map(c => {
        return {
            id: c.id,
            comune: `${c.comuneISTAT} (${c.provinciaISTAT})`,
            indirizzo: `${c.via} ${c.numeroCivico}`,
            tipologia: c.tipologiaDeiLavori,
            dataInizio: c.dataPresuntoInizio,
            dataFine: c.dataPresuntaFine
        };
    })
);

const coursesExpired = computed(() => {
    return corsiItems.value.filter(function (x: any) {
        return viewStatusHelper.isExpiredOrInExpiring(x.dataScadenza);
    }).length;
});

//VUE
onMounted(async () => {

    loading.value = true;
    try {
        const loadingTasks = [
            cesfCmsStore.getCircolari(),
            loadNotifications()
        ];
        if(isUserCompany.value) {
            loadingTasks.push(loadCantieri());
            loadingTasks.push(loadCorsiItems());
        }

        await Promise.all(loadingTasks);
    }
    finally {
        loading.value = false;
    }
});

const pageDisplayName = computed(() => {
    if(isUserCompany.value == true) {
        if(company.value) {
            return company.value.businessName;
        }
    }
    else {
        return cesfUser.value?.user.fullName;
    }

    return '';
})

watch(() => isUserCompany.value, async () => {
    if(isUserCompany.value){
        loading.value = true;

        await Promise.all([
            loadCantieri(),
            loadCorsiItems(),
            cesfCmsStore.getCircolari(),
            loadNotifications(),
            loadCourseOutcomes(),
        ]);

        loading.value = false;
    }
}, { immediate: false })

const cantieri = ref<Cantiere[]>([]);
const loadCantieri = async (pageNumber : number = 1) => {
    const finder = {
        pageIndex: pageNumber,
        pageSize: 10,
        sortField: "DataPresuntoInizio",
        sortOrder: -1
    } as CantiereFinder;
    if(isUserCompany.value){
        const result = await buildingSiteStore.getCantieriByCompany(finder, cesfUser.value.companyId);
        cantieri.value = result.items;
    }
}

const notificationStore = useNotificationStore();
const loadNotifications = async () => {
    await notificationStore.loadNotifications(true);
}

const loadCourseOutcomes = async () => {
    if(isUserCompany){
        const courseParticipationsResponse =
            await learningWrappersService.getCourseParticipationOutcomesWrappersForActualCompanyStudents(
                cesfUser.value.companyId,
                {
                queryRegistriesRelations: true,
                pageIndex: -1,
                pageSize: -1,
                status: CourseParticipationOutcomeStatus.Success,
                validityStatus: CourseParticipationOutcomeValidityStatus.Active,
                sortOrder: -1
            } as CourseParticipationOutcomeFinder);

        if(courseParticipationsResponse.items.length > 0){
            let fathers = courseParticipationsResponse.items.filter(o  => o.fathersCourseTemplates.length == 0 && o.validityStatus == CourseParticipationOutcomeValidityStatus.Active);
            const groupedParticipations = fathers.reduce((acc : any, obj : CourseParticipationOutcome) => {
                const key = obj.relatedCourseTemplateId;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(obj);
                return acc;
            }, {});
            for (var key of Object.keys(groupedParticipations)) {
                const group = groupedParticipations[key];
                const outcome = (group[0] as CourseParticipationOutcomeWrapper);
                if(outcome.relatedTemplateTags && outcome.relatedTemplateTags.some(t => t == CourseTemplateTagType.Main)){
                    if(dayjs() > dayjs(outcome.expiryDate)){
                        showExpiredCoursesNotification.value = true;
                        return;
                    }
                }
            }
            //Raggruppo per i corsi base padre
            let fathersRelatedCourseTemplatesId = fathers.map(f => f.relatedCourseTemplateId);
            let orphans = courseParticipationsResponse.items.filter(o => o.fathersCourseTemplates.length > 0 && !o.fathersCourseTemplates.some(ct => fathersRelatedCourseTemplatesId.includes(ct)));
            let groupedOrphansByFather = orphans.reduce((acc : any, obj : CourseParticipationOutcomeWrapper) => {
                const key = obj.fathersCourseTemplates[0];
                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push(obj);
                return acc;
            }, {});

            for (var fatherKey of Object.keys(groupedOrphansByFather)) {
                const groupByFather = groupedOrphansByFather[fatherKey];

                //Raggruppo il sottogruppo per studente in modo da calcolarmi gli aggiornamenti più recenti
                let groupedOrphansByStudents = groupByFather.reduce((acc : any, obj : CourseParticipationOutcomeWrapper) => {
                    const key = obj.courseParticipation.studentId;
                    if (!acc[key]) {
                        acc[key] = [];
                    }

                    acc[key].push(obj);
                    return acc;
                }, {});
                const studentsOrphansActiveCourses = [] as CourseParticipationOutcomeWrapper[];
                for(var studentKey of Object.keys(groupedOrphansByStudents)){
                    const groupByStudent = groupedOrphansByStudents[studentKey];
                    const sortedGroup = (groupByStudent as CourseParticipationOutcomeWrapper[]).sort((a,b) => {
                        return new Date(b.courseDate).getTime() - new Date(a.courseDate).getTime();
                    });
                    studentsOrphansActiveCourses.push(sortedGroup[0]);
                }

                //Infine raggruppo i restanti gruppi per relativo template per avere i corsi da mostrare
                let groupedOrphansByRelatedCourse = studentsOrphansActiveCourses.reduce((acc : any, obj : CourseParticipationOutcomeWrapper) => {
                    const key = obj.relatedCourseTemplateId;
                    if (!acc[key]) {
                        acc[key] = [];
                    }

                    acc[key].push(obj);
                    return acc;
                }, {});

                for(var relatedCourseKey of Object.keys(groupedOrphansByRelatedCourse)){
                    const groupByRelatedCourse = groupedOrphansByRelatedCourse[relatedCourseKey];
                    for( var outcome of groupByRelatedCourse){
                        const courseDate = new Date(outcome.courseDate);
                        const validityMonths = outcome.relatedCourseTemplate.validityMonths;
                        outcome.expiryDate = new Date((courseDate.setMonth(courseDate.getMonth()+validityMonths)));
                        if(dayjs() > dayjs(outcome.expiryDate)){
                            showExpiredCoursesNotification.value = true;
                            return;
                        }
                    }
                }

            }
        }


    }
}

const goToCantiereDetail = (cantiere : Cantiere) => {
    router.push({
            path: `/buildingsite/detail/${cantiere.id}`
        });
}

const loadCorsiItems = async (pageNumber : number = 1) => {
    const startingCoursesFinder = {
        pageIndex: 1,
        pageSize: 10,
        queryRelations: true,
    } as CourseInstanceFinder;
    const startingCourses = await coursesStore.getStartingCourses(startingCoursesFinder);

    const companyOutcomesFinder = {
        pageIndex: -1,
        pageSize: -1,
        status: CourseParticipationOutcomeStatus.Success,
        validityStatus: CourseParticipationOutcomeValidityStatus.Active,
    } as CourseParticipationOutcomeFinder;
    const courseCompanyOutcomes = await coursesStore.getCompanyCourseOutcomesByFinder(cesfUser.value.companyId, companyOutcomesFinder);
    corsiItems.value = startingCourses.map(sc => {
        const outcomes = courseCompanyOutcomes.filter(co => co.relatedCourseTemplateId == sc.courseTemplateId);
        let result = {
            id: sc.id,
            corso: sc.template.title,
            inizioCorso: sc.startDate,
            dataScadenza: outcomes.length > 0 ?
                outcomes.reduce((prev, curr) => {
                    if(!curr.doesExpire) return curr;
                    return curr.expiryDate < prev.expiryDate ? curr : prev;
                }).expiryDate : null,
        };
        return result;
    });
}



const goToCircolareDetail = (item: any) => {
    router.push({
        path: `/communication/detail/${item.id}`
    });
};

const isStateVisible = (item: any) => {
    return viewStatusHelper.isExpiredOrInExpiring(item.dataScadenza);
};

const goToIscrizioneCorso = (item: any) => {
    // router.push({
    //     path: `/employee/detail/${item.id}`
    // });
};

const goToCourseDetail = () => {
    router.push({
        path: `/course/registration`,
    });
};

</script>

<style lang="scss">

.label-box {
    background-color: #e3e3e3;
    border-radius: 5px;
    padding: 0px 5px;
}

</style>
