<template>
    <Page>
        <KBackButton />
        <PageHeader :title="'Cantiere '+cantiere?.codice"></PageHeader>

        <div class="row mx-0" v-if="cantiere">
            <div class="col-12">
                <div class="row d-flex align-items-center mt-3 bg-light py-3">
                    <div class="col-12 col-md-5 col-lg-4">
                        <h6 class="">Tipologia</h6>
                        <h5>{{ cantiere.tipologiaDeiLavori }}</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Inizio</h6>
                        <h5>{{ toDateString(cantiere.dataPresuntoInizio) }}</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Data Fine</h6>
                        <h5>{{ toDateString(cantiere.dataPresuntaFine) }}</h5>
                    </div>
                    <div v-if="cantiere.nomeResponsabile" class="col-12 col-md-5 col-lg-4">
                        <h6 class="">Responsabile Cantiere</h6>
                        <h5>{{ cantiere.nomeResponsabile }}</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Comune</h6>
                        <h5>{{ cantiere.comuneISTAT }} ({{cantiere.provinciaISTAT}})</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Indirizzo</h6>
                        <h5>{{ cantiere.via }}</h5>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="isUserCompany" class="row mx-0 bg-light" style="border-radius: 5px;" >
            <div class="col-12">

                <div class="row d-flex align-items-center mt-3 py-3">

                    <div class="row col-12">
                        <p>Puoi selezionare uno o più preposti che accedendo alla Extranet avranno visibilità sui dati ed i documenti di questo cantiere.</p>
                    </div>

                    <div class="col-12">
                        <h6>Lavoratore Preposto</h6>
                        <div class="col-6">
                            <ProgressSpinner v-if="_store.loadingPreposti"></ProgressSpinner>
                            <div class="d-flex align-items-start" v-else>
                                <KSelect
                                v-model="selectedPrepostoId"
                                :options="prepostiDropdownOption"
                                />
                                <button @click="addPrepostoToVisibilityAdds" class="btn btn-icon btn-me k-btn-flat">
                                    <span class="text-uppercase fw-bold ml-2 text-primary">Seleziona</span>
                                </button>
                            </div>
                        </div>
                        <DataTable v-if="visibilitiesToAdd.length > 0" class="bg-light" :value="visibilitiesToAdd" dataKey="id" responsiveLayout="scroll">
                            <Column header="IN AGGIUNTA">
                                <template #body="slotProps">
                                    {{slotProps.data.lastName}} {{slotProps.data.name}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <button @click="undoCantiereVisibilityAdd(slotProps.data)" class="btn btn-icon btn-me k-btn-flat">
                                        <span class="text-uppercase fw-bold ml-2 text-primary">Rimuovi</span>
                                    </button>
                                </template>
                            </Column>
                        </DataTable>
                        <h4 class="mt-5">Il cantiere è visibile a:</h4>
                        <DataTable
                            class="bg-light"
                            :value="_store.cantieriVisibilities"
                            dataKey="id"
                            responsiveLayout="scroll"
                            :row-class="formatVisibilitiesRowClass"
                            :loading="_store.loadingCantieriVisibilities">
                            <Column header="NOMINATIVO">
                                <template #body="slotProps">
                                    {{slotProps.data.eduWorker?.lastName}} {{slotProps.data.eduWorker?.name}}
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <button v-if="isVisibilityInDelete(slotProps.data)" @click="undoCantiereVisibilityRemoval(slotProps.data)" class="btn btn-icon btn-me k-btn-flat">
                                        <span class="text-uppercase fw-bold ml-2 text-primary">Annulla rimozione</span>
                                    </button>
                                    <button v-else @click="addCantiereVisibilityToRemove(slotProps.data)" class="btn btn-icon btn-me k-btn-flat">
                                        <span class="text-uppercase fw-bold ml-2 text-primary">Rimuovi</span>
                                    </button>
                                </template>
                            </Column>

                            <template #empty> Nessun preposto ha visibilità del cantiere </template>
                        </DataTable>
                        <div v-if="showVisibilitySaveButton" class="row text-center">
                            <div class="col-12">
                                <button type="button" @click="saveDeleteCantieriVisibilities" class="btn btn-primary col-xs-8 col-md-4 col-lg-4 col-xl-3">
                                    <ProgressSpinner v-if="_store.savingCantieriVisibilities" style="width: 20px; height: 20px; margin: -5px 0;" strokeWidth="4" />
                                    <span v-else> SALVA</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div v-if="cantiere.nomeResponsabile" class="col-12 col-md-5 col-lg-4">
                        <h6 class="">Responsabile Cantiere</h6>
                        <h5>{{ cantiere.nomeResponsabile }}</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Comune</h6>
                        <h5>{{ cantiere.comuneISTAT }} ({{cantiere.provinciaISTAT}})</h5>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                        <h6 class="">Indirizzo</h6>
                        <h5>{{ cantiere.via }}</h5>
                    </div> -->
                </div>

            </div>
        </div>

        <GMapMap
            :center="center"
            :zoom="14"
            style="width: 70%; height: 350px; margin: 20px 0; border-radius: 10px"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                styles: [
                    {
                        featureType: 'poi.business',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        elementType: 'labels.icon',
                        stylers: [{ visibility: 'off' }],
                    },
                ]
            }">

            <GMapMarker :position="center" />
        </GMapMap>

        <h4 class="mt-3">Descrizione</h4>
        <p>{{cantiere?.descrizione}}</p>

        <h4 class="mt-3">Documenti</h4>
        <div v-if="_store.loadingDocumentiCantiere">Caricamento documenti</div>
        <div v-else-if="documenti && documenti.length==0">Nessun documento allegato</div>
        <div v-else>
            <div v-for="doc in documenti" :key="doc.entityId">
                <table style="width: 100%; margin-bottom: 10px;">
                    <tr style="border-bottom: 1px solid gainsboro">
                        <td>
                            <span>{{doc.documentFileName}}</span>
                        </td>
                        <td style="text-align: end">
                            <Button v-if="isUserCompany" label="Elimina" class="p-button-text p-button-danger p-button-sm" @click="deleteDocumento(doc.documentId)" />
                            <Button label="Download" class="p-button-sm p-button-link" style="margin-left: 10px;" @click="downloadDocumento(doc.documentId)" />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <Button icon="pi pi-plus" class="p-button-sm btn-primary" label="Carica documento" @click="dialogUploadVisibile=true" />
            <!-- refectory necessario per dialog -->
            <Dialog v-model:visible="dialogUploadVisibile" style="width: 50%;" :modal="true" >
                <template #header>
                    <h1>Carica documento</h1>
                </template>
                <ProgressSpinner v-if="_store.loadingDocumentiCantiere" />
                <div v-else>
                    <FileUpload :customUpload="true" :fileLimit="1" @uploader="uploadFile"
                                :chooseLabel="'Scegli'"
                                :uploadLabel="'Importa'"
                                :cancelLabel="'Annulla'"
                                >
                        <template #empty>
                            <p>Trascina qui il file da caricare</p>
                        </template>
                    </FileUpload>
                    </div>
            </Dialog>
        </div>
    </Page>
</template>

<script setup lang="ts">
//IMPORTS VUE
import { onMounted, computed, ref } from "vue";
import { useRoute } from "vue-router";
import { FileUploadUploaderEvent } from 'primevue/fileupload';
import { useCesfUser } from '@/composables';

//IMPORT EXT
import { KPaginator, PageInfo, useFilters, KSelect, usePlatformApi } from "@k-digitale/kappa";

//IMPORT MODELS
import { Cantiere, CantiereVisibility, CantiereVisibilityFinder } from "@/services/cesf/Models";
import { InsertCantiereVisibilityRequest, SaveDeleteCantiereVisibilityListRequest } from "@/services/cesf/Requests";
import { Worker } from "@/learning/registries/Models";

//IMPORT MODELS
import { useBuildingSiteStore } from "../store";

//EXT
const { toDateTimeString, toDateString } = useFilters();

//STORES
const _store = useBuildingSiteStore();

//COMPOSABLES
const route = useRoute();
const { cesfUser, isUserCompany } = useCesfUser();
const { kToast } = usePlatformApi();

//REFS
const cantiere = ref<Cantiere>(null);
const idCantiere = ref<string>(route.params.id as string);

const documenti = ref<any[]>([]);
const dialogUploadVisibile = ref(false);

//VUE
onMounted(async () => {
    await loadCantiere();
    await loadDocumentiCantiere();
    if(isUserCompany.value){
        await Promise.all([getPreposti(), getCantieriVisibilityByFinder()]);
    }
});

const loadCantiere = async () => {
    cantiere.value = await _store.getCantiereById(idCantiere.value);
}

const loadDocumentiCantiere = async () => {
    documenti.value = await _store.getDocumentiCantiere(idCantiere.value, cesfUser.value?.companyId ?? "");
}

const downloadDocumento = async (id: number) => {
    const result = await _store.downloadDocumentoCantiere(idCantiere.value, id);
}

const deleteDocumento = async (id: number) => {
    const result = await _store.deleteDocumentoCantiere(idCantiere.value, id);
    if(result){
        await loadDocumentiCantiere();
    }
}

const uploadFile = async (event: FileUploadUploaderEvent) => {
    var file = event.files as File[];

    if (!file) {
        // toast.add({
        //     severity: "error",
        //     summary: "Nessun file selezionato!",
        //     life: 3000,
        // });
    }

    try {
        var formData = new FormData();
        formData.append("file", file[0]);

        // emit("file-loaded", formData);

        const result = await _store.uploadDocumentoCantiere(idCantiere.value, formData);
        if(result){
            //toast.add({severity:'success', summary:'Salvato', detail:"Documento salvato con successo.", life: 3000});
            await loadDocumentiCantiere();
        } else{
            //toast.add({severity:'error', summary:'Errore', detail:"E' avvenuto un errore durante il salvataggio del documento.", life: 3000});
        }
        dialogUploadVisibile.value = false;

    } catch (err) {
        console.log(err);
        // toast.add({
        //     severity: "error",
        //     summary: "Errore",
        //     detail: "Errore durante l'importazione del file delle anagrafiche delle Aziende di Info Camere.",
        //     life: 3000,
        // });
    }
    finally {
        // loading.value = false;
    }
};

const center = computed(() => {
    let lat = 0.0;
    let lng = 0.0;

    if(cantiere.value) {
        lat = cantiere.value.coordY;
        lng = cantiere.value.coordX;
    }
    const pos = {
        lat: lat,
        lng: lng
    };

    return pos;
});

//Preposti
const selectedPrepostoId = ref<string>();

const prepostiDropdownOption = computed(() => {
    const selectedPrepostiIds = visibilitiesToAdd.value.map(p => p.eduWorkerId);
    const visibilitiesWorkersId = _store.cantieriVisibilities.map(cv => cv.eduWorkerId);
    let listaPreposti = [{
        name: "Seleziona un preposto",
    }]
    listaPreposti = listaPreposti.concat(preposti.value.filter(p => !selectedPrepostiIds.includes(p.id) && !visibilitiesWorkersId.includes(p.id)).map(p => {
        return{
            name: `${p.lastName} ${p.name}`,
            value: p.id
        }
    }));
    return listaPreposti;
})

const getPreposti = async () => {
    if(cesfUser.value){
        var result = await _store.getPreposti(
            cesfUser.value.companyId,
            -1,
            -1);
        if(result){
            preposti.value = result.items;
        }
    }
}

//CANTIERI VISIBILITY
const visibilitiesToAdd = ref<InsertCantiereVisibilityRequest[]>([]);
const visibilitiesToRemove = ref<CantiereVisibility[]>([]);

const addPrepostoToVisibilityAdds = () => {
    const selectedPreposto = preposti.value.find(p => p.id == selectedPrepostoId.value);
    if(selectedPreposto){
        visibilitiesToAdd.value.push({
            cantiereId: cantiere.value.id,
            eduCompanyId: cesfUser.value.companyId,
            eduWorkerId: selectedPreposto.id,
            name: selectedPreposto.name,
            lastName: selectedPreposto.lastName,
        } as InsertCantiereVisibilityRequest)
    }
}

const undoCantiereVisibilityAdd = (request: InsertCantiereVisibilityRequest) => {
    visibilitiesToAdd.value = visibilitiesToAdd.value.filter(v => v.eduWorkerId != request.eduWorkerId);
}

const addCantiereVisibilityToRemove = (visibility: CantiereVisibility) => {
    visibilitiesToRemove.value.push(visibility);
}

const undoCantiereVisibilityRemoval = (visibility: CantiereVisibility) => {
    visibilitiesToRemove.value = visibilitiesToRemove.value.filter(v => v.id != visibility.id);
}

const preposti = ref<Worker[]>([]);

const cantieriVisibilityFinder = ref<CantiereVisibilityFinder>({
    pageIndex: 1,
    pageSize: 10,
    cantiereId: cantiere.value?.id,
    eduCompanyId: cesfUser.value?.companyId,
} as CantiereVisibilityFinder)

const getCantieriVisibilityByFinder = async () => {
    if(cesfUser.value){
        await _store.getCantieriVisibilitiesByFinder(cantieriVisibilityFinder.value);
    }
}

const saveDeleteCantieriVisibilities = async () => {
    const request = {
        idsToDelete: visibilitiesToRemove.value.map(v => v.id),
        insertRequests: visibilitiesToAdd.value
    } as SaveDeleteCantiereVisibilityListRequest;
    var result = await _store.saveDeleteCantieriVisibility(request);
    if(result && result.success){
        kToast.showSuccess("Salvataggio modifiche avvenuto con successo");
        visibilitiesToAdd.value = [];
        visibilitiesToRemove.value = [];
        await getCantieriVisibilityByFinder();
    } else{
        kToast.showError("E' avvenuto un errore durante il salvataggio delle modifiche");
    }
}

const showVisibilitySaveButton = computed(() => {
    return visibilitiesToRemove.value.length > 0 ||
        visibilitiesToAdd.value.length > 0;
})

const isVisibilityInDelete = (visibility: CantiereVisibility) : boolean => {
    return visibilitiesToRemove.value.some(v => v.id == visibility.id);
}

const formatVisibilitiesRowClass = (visibility: CantiereVisibility) => {
    if(isVisibilityInDelete(visibility)){
        return "visibility-in-delete";
    }
}

</script>

<style lang="scss">
.tabview-custom {
    i,
    span {
        vertical-align: middle;
    }

    span {
        margin: 0 0.5rem;
    }
}
.p-tabview p {
    line-height: 1.5;
    margin: 0;
}

.image{
    opacity: 1;
}

.bg-light .p-datatable-tbody > tr {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light .p-datatable-thead > tr > th {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light .p-datatable-footer {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light .p-datatable-tbody > tr.visibility-in-delete {
    background-color: rgb(255, 161, 161) !important;
}

</style>
