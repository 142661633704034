import { ApiPagedResult, ApiResponse, BaseService } from "@k-digitale/kappa";
import { CourseInstance, CourseInstanceFinder, CourseParticipation, CourseParticipationFinder, LessonInstance, LessonInstanceFinder } from "@/learning/instances/models";
import { SaveCourseParticipationRequestListItemRequest } from "./requests";
import { CourseTemplate, CourseTemplateFinder } from "@/learning/Templates/Models";

class ModuleCourseService extends BaseService {

    async getStartingCourses(finder: CourseInstanceFinder) : Promise<ApiPagedResult<CourseInstance>> {
        const response = await this.tryExecute(
            this.api.get<ApiPagedResult<CourseInstance>>(`learning/course-instances`, { params: finder })
        );

        return response!.data;
    }

    async getCourse(id: string) : Promise<CourseInstance> {
        const response = await this.tryExecute(
            this.api.get<CourseInstance>(`learning/course-instances/${id}`)
        );

        return response!.data;
    }

    async getCourseTemplate(id: string) : Promise<CourseTemplate> {
        const response = await this.tryExecute(
            this.api.get<CourseTemplate>(`learning/templates/course/${id}`)
        );

        return response!.data;
    }

    async getLessons(finder: LessonInstanceFinder) : Promise<ApiPagedResult<LessonInstance>> {
        const response = await this.tryExecute(
            this.api.get<ApiPagedResult<LessonInstance>>(`learning/instances/lessons`, { params: finder })
        );

        return response!.data;
    }

    async getAllCourses(finder: CourseTemplateFinder) : Promise<ApiPagedResult<CourseTemplate>> {
        const response = await this.tryExecute(
            this.api.get<ApiPagedResult<CourseTemplate>>(`cesf/corsi`, { params: finder })
        );

        return response!.data;
    }

    async getCourseParticipationsByFinder(finder: CourseParticipationFinder): Promise<ApiPagedResult<CourseParticipation>> {
        const response = await this.tryExecute(
            this.api.get<any>("learning/instances/course-participations/", { params: finder })
        );

        return response!.data;
    }
}

export const courseService = new ModuleCourseService();