<template>
    <Page :loading="loading">
        <PageHeader title="Servizi"></PageHeader>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 pt-3 py-lg-3" v-for="srv in serviziAttivabili" :key="srv.id">
                <KCard
                    :title="srv.name"
                    :text="srv.description"
                    title-class="text-uppercase"
                    :style="'padding-top: 20px'"
                    :topTitle="srv.familyName"
                    topTitleIcon="it-mail" 
                    :showCmdButton="true"
                    labelCmdButton="RICHIEDI"
                    @cmd-button-click="richiediServizio(srv.id)"
                    />
            </div>
        </div>

        <div class="row">
            <div class="col d-inline-flex justify-content-between">
                <h3>Servizi attivati</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <DataTable :value="serviziAttivati" dataKey="id" responsiveLayout="scroll" table-class="k-data-table">
                    <Column header="SERVIZIO">
                        <template #body="slotProps">
                            {{slotProps.data.nome}}
                        </template>
                    </Column>
                    <Column header="DATA ATTIVAZIONE">
                        <template #body="slotProps">
                            {{toDateString(slotProps.data.dataAttivazione)}}
                        </template>
                    </Column>
                    <Column header="DATA SCADENZA">
                        <template #body="slotProps">
                            {{toDateString(slotProps.data.dataScadenza)}}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <button type="button" class="btn btn-primary" @click="mostraDettaglio(slotProps.data)"> DETTAGLI </button>
                            </div>
                        </template>
                    </Column>
                    <template #empty> Nessun servizio attivato. </template>
                </DataTable>
            </div>
        </div>

        <BannerEsperto titolo="Hai bisogno di attivare un servizio?" testo-btn="CONTATTA IL TUO CONSULENTE CESF" />

        <!-- Modale dettaglio servizio -->
        <KModalSlide title="Dettaglio servizio" :dimension="ModalDimension.sm" v-model="dettaglioVisibile" @closed="dettaglio = null">
                
                <h6>Servizio attivo</h6>
                <div>{{ dettaglio?.nome }}</div>
                
                <h6>Data Attivazione</h6>
                <div>{{toDateString(dettaglio?.dataAttivazione)}}</div>

                <h6>Data Scadenza</h6>
                <div>{{toDateString(dettaglio?.dataScadenza)}}</div>

                <h6>Note</h6>
                <div>{{ dettaglio?.note }}</div>

                <h6>Allegati</h6>
                <div v-for="doc in dettaglio?.allegati" class="d-flex justify-content-between mb-1">
                    {{ doc.nomeAllegato }} 
                    <button type="button" class="btn btn-primary" @click="downloadDoc(doc.idDocumentoDms)"> SCARICA </button>
                </div>
        </KModalSlide>
    </Page>
</template>

<script setup lang="ts">
import { useCesfUser } from '@/composables';
import { useFilters, usePlatformApi, ModalDimension } from '@k-digitale/kappa';
import { ref, watch, onMounted } from 'vue';
import BannerEsperto from "@/components/BannerEsperto.vue";
import { serviceService } from '../service';
import { ServizioAzienda, ServizioAziendaAttivabileDto } from '../models';

const { toDateString } = useFilters();
const { kToast, kConfirm } = usePlatformApi();

const { isUserCompany, cesfUser, company } = useCesfUser();

watch(() => company, async () => {
    await loadServizi();
});

onMounted(async () => await loadServizi());

const loading = ref(false);
const serviziAttivabili = ref<ServizioAziendaAttivabileDto[]>([]);
const serviziAttivati = ref<ServizioAzienda[]>([]);

const loadServizi = async () => {
    if(!company.value) {
        return;
    }

    loading.value = true;
    try {
        const serviziAz = await serviceService.getCompanyServices(company.value.id);
        serviziAttivabili.value = serviziAz.serviziAttivabili;
        serviziAttivati.value = serviziAz.serviziAzienda;
    }
    catch (err) {}
    finally {
        loading.value = false;
    }
}

const richiediServizio = async (idServizio: number) => {
    if(!company.value) {
        return;
    }

    loading.value = true;
    try {
        kConfirm.requireConfirm(
            "Inviare la richiesta del servizio? Verrai ricontattato per maggiori dettagli e per completare l'operazione richiesta.",
            async () => {
                const esitoRichiesta = await serviceService.requestService(company.value.id, idServizio);
                if(esitoRichiesta) {
                    kToast.showSuccess("Richiesta servizio inviata con successo.")
                }
                else {
                    kToast.showSuccess("Invio richiesta servizio fallita.")
                }
            },
            () => {}
        )
    }
    catch (err) {}
    finally {
        loading.value = false;
    }
}

const dettaglio = ref<ServizioAzienda>();
const dettaglioVisibile = ref(false);

const mostraDettaglio = async (servAzienda: ServizioAzienda) => {
    if(servAzienda) {
        dettaglio.value = servAzienda;
        dettaglioVisibile.value = true;
    }
}

const downloadDoc = async (idDoc: number) => {
    if(idDoc) {
        await serviceService.downloadDocumentoServizioAzienda(company.value.id, idDoc);
    }
}

</script>