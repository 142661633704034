<template>
    <Page :loading="loading">
        <PageHeader title="Circolari e Comunicazioni"></PageHeader>
        <div class="row my-4">
            <div class="col-12 p-0">
                <DataTable :value="circolariItems" dataKey="id" responsiveLayout="scroll" :loading="loading">
                    <Column field="name" header="TITOLO"></Column>
                    <Column header="DATA PUBBLICAZIONE">
                        <template #body="slotProps">
                            {{toDateString(slotProps.data.publishedDate)}}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <div style="text-align: right">
                                <a target="_blank" role="button" class="btn btn-primary" :href="(link+slotProps.data.url)"> DETTAGLI </a>
                            </div>
                        </template>
                    </Column>
                    <template #empty> Nessuna circolare o comunicazione </template>
                </DataTable>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { useCesfCmsStore } from "@/store"
import { useFilters } from "@k-digitale/kappa"
import { computed, onMounted, ref } from "vue";


const link = computed(() => import.meta.env.VITE_CMS_DETAIL_BASE_URL);

const cesfCmsStore = useCesfCmsStore();
const { toDateString } = useFilters();
const loading = ref<boolean>(false);
onMounted(async () => {
    loading.value = true;
    await cesfCmsStore.getCircolari();
    loading.value = false;
})
const circolariItems = computed(() => {
    return cesfCmsStore.circolari.sort((a,b) => {
        return new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime();
    })
});

</script>

<style lang="scss">

</style>