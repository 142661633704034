import { defineStore } from "pinia";
import { CourseInstance,
  CourseInstanceFinder,
  CourseInstanceStatus,
  CourseParticipation,
  CourseParticipationFinder,
  CourseParticipationOutcome,
  CourseParticipationOutcomeFinder,
  CourseParticipationRequest,
  CourseParticipationRequestFinder } from '@/learning/instances/Models';
import { courseService } from './service';
import { instancesService } from '@/learning/instances/InstancesService';
import { templatesService } from "../../learning/templates/TemplatesService";
import { registriesService } from '@/learning/registries/RegistriesService';
import { ApiPagedResult, SortingOrder, usePlatformApi } from "@k-digitale/kappa";
import { SaveCourseParticipationRequestListItemRequest, SaveExternalCourseParticipationRequest, SetCourseParticipationCanceledRequest} from "./requests";
import dayjs from "dayjs";
import { CourseTemplate, CourseTemplateFinder, CourseTemplateStatus } from "@/learning/Templates/Models";
import { learningWrappersService } from "../../services/learningWrappers/LearningWrappersServices";
import { CourseInstanceWrapper, CourseTemplateListItemView } from "@/services/learningWrappers/Models";
import { SaveCourseParticipationRequest } from "@/learning/instances/Requests";

const { execApiCall } = usePlatformApi();
export interface ModuleCourseState {
  loadingCompanyOutcomes: boolean,
  loadingStartingCourses: boolean,
  loadingCourses: boolean,
  loadingOtherCourses: boolean,
  loadingRequiredCourses: boolean,
  loadingCourse: boolean,
  loadingParticipation: boolean,
  loadingParticipationRequests: boolean,
  startingCourses: CourseInstance[]
  courses: CourseTemplate[]
  companyOutcomes: CourseParticipationOutcome[]
}

export const useCourseStore = defineStore('module-course-store', {
  state: () => ({
    loadingCompanyOutcomes: false,
    loadingStartingCourses: false,
    loadingCourses: false,
    loadingCourse: false,
    loadingRequiredCourses: false,
    loadingOtherCourses: false,
    startingCourses:[] as CourseInstance[],
    courses: [],
    loadingParticipationRequests: false,
    companyOutcomes: [],
  } as ModuleCourseState),

  getters: {
      //
  },

  actions: {
      async loadStartingCourses(pageIndex:number, pageSize: number) {

          const finder = {
              status: CourseInstanceStatus.StudentsSigninIn,
              queryRelations: true,
              startAfterDate: new Date(),
              sortField: "startDate",
              sortOrder: SortingOrder.Ascending,
              hideOldCourses: true,
              pageIndex: pageIndex,
              pageSize: pageSize,
              excludeFullCourses: true,
              hideInvisibleToExtranet: true,

          } as CourseInstanceFinder;

          const courses = await execApiCall(courseService.getStartingCourses(finder));
          if(courses && courses.items && courses.items.length)
          {
              this.startingCourses = courses.items;
          } else{
              this.startingCourses = [];
          }
      },

      async saveCourseParticipationRequestsSave(request : SaveCourseParticipationRequestListItemRequest[]) : Promise<boolean> {
        return await execApiCall(instancesService.saveCourseParticipationRequestList(request));
      },
      async saveCourseParticipationSave(request : SaveExternalCourseParticipationRequest[]) : Promise<boolean> {
        return await execApiCall(instancesService.saveCourseParticipationList(request));
      },
      async setCourseParticipationCancelled(request : SetCourseParticipationCanceledRequest[]) : Promise<boolean> {
        return await execApiCall(instancesService.setCourseParticipationListCancelled(request));
      },
      async getCompanyCourseOutcomesByFinder(companyId: string, finder : CourseParticipationOutcomeFinder) : Promise<CourseParticipationOutcome[]> {
        this.loadingCompanyOutcomes = true;
        const courseParticipationsResponse = await registriesService.getCourseParticipationOutcomesWithActualStudents(companyId, finder);
        this.loadingCompanyOutcomes = false;
        if(courseParticipationsResponse && courseParticipationsResponse.items){
          this.companyOutcomes = courseParticipationsResponse.items;
        return courseParticipationsResponse.items;
        }
        return [];
      },

      async getStartingCourses(finder : CourseInstanceFinder) : Promise<CourseInstance[]> {
        finder.startAfterDate = dayjs().utc(true).toDate();
        finder.status = CourseInstanceStatus.StudentsSigninIn;
        finder.excludeFullCourses = true;

        this.loadingStartingCourses = true;
        const startingCoursesResponse = await instancesService.getCourseInstancesByFinder(finder);
        this.loadingStartingCourses = false;
        if(startingCoursesResponse && startingCoursesResponse.items){
          this.startingCourses = startingCoursesResponse.items;
          return startingCoursesResponse.items;
        }
        return [];
      },

      async getStartingCoursesWrapper(finder : CourseInstanceFinder) : Promise<CourseInstanceWrapper[]> {
        finder.startAfterDate = dayjs().utc(true).toDate();
        finder.status = CourseInstanceStatus.StudentsSigninIn;
        finder.excludeFullCourses = true;

        this.loadingStartingCourses = true;
        const startingCoursesResponse = await learningWrappersService.getCourseInstancesWrappersByFinder(finder);
        this.loadingStartingCourses = false;
        if(startingCoursesResponse && startingCoursesResponse.items){
          this.startingCourses = startingCoursesResponse.items;
          return startingCoursesResponse.items;
        }
        return [];
      },

      async getRequiredCourseTemplates(rootCourseId:string) : Promise<CourseTemplate[]> {
        this.loadingOtherCourses = true;
        const coursesResponse = await templatesService.getRequiredCourseTemplates(rootCourseId);
        this.loadingOtherCourses = false;
        return coursesResponse;
      },

      async getAllCourses(finder: CourseTemplateFinder) : Promise<ApiPagedResult<CourseTemplateListItemView>> {
        finder.status = CourseTemplateStatus.Completed;
        finder.showRelatedToPimOnly = true;
        finder.sortField = "templateStartingCourses";
        finder.sortOrder = SortingOrder.Descending;
        this.loadingCourses = true;
        const coursesResponse = await learningWrappersService.getCourseTemplatesListView(finder);
        this.loadingCourses = false;
        if(coursesResponse && coursesResponse.items){
          this.courses = coursesResponse.items;
          return coursesResponse;
        }
        return {} as ApiPagedResult<CourseTemplateListItemView>;
      },

      async getOtherCourses(finder: CourseTemplateFinder) : Promise<ApiPagedResult<CourseTemplateListItemView>> {
        finder.status = CourseTemplateStatus.Completed;
        finder.showRelatedToPimOnly = false;
        finder.sortField = "templateStartingCourses";
        finder.sortOrder = SortingOrder.Descending;
        this.loadingOtherCourses = true;
        const coursesResponse = await learningWrappersService.getCourseTemplatesListView(finder);
        this.loadingOtherCourses = false;
        if(coursesResponse && coursesResponse.items){
          this.courses = coursesResponse.items;
          return coursesResponse;
        }
        return {} as ApiPagedResult<CourseTemplateListItemView>;
      },

      async getCourse(id: string) : Promise<CourseTemplate> {
        this.loadingCourse = false;

        let courseResponse;
        if(isNaN(Number(id))){
           courseResponse = await templatesService.getCourseTemplate(id);
        } else{
          const parsedInt = parseInt(id);
          var courseByProduct = await templatesService.getCourseTemplateByProductId(parsedInt);
          courseResponse = courseByProduct.courseTemplate;
        }

        this.loadingCourse = true;
        return courseResponse;

      },

      async getParticipations(finder: CourseParticipationFinder) : Promise<ApiPagedResult<CourseParticipation>>
      {
        this.loadingParticipation = true;
        const result = await instancesService.getCourseParticipationsByFinder(finder);
        this.loadingParticipation = false;
        return result;
      },
      async getParticipationRequests(finder: CourseParticipationRequestFinder) : Promise<ApiPagedResult<CourseParticipationRequest>>
      {
        this.loadingParticipation = true;
        const result = await instancesService.getCourseParticipationRequestsByFinder(finder);
        this.loadingParticipation = false;
        return result;
      }
  }
})